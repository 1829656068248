/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";

import { axiosInstance } from "../../utility/api";

export default function Resetpw() {
	const [password, setPassword] = useState("");
	const [cpassword, setCpassword] = useState("");
	const history = useHistory();
	const { search } = useLocation();
	const { token } = queryString.parse(search);

	const validatePassword = (password, repeatPassword) => {
		let MinLength = 6;
		let MaxLength = 16;

		let meetsLengthRequirements =
			password.length >= MinLength && repeatPassword.length <= MaxLength;
		let hasUpperCasevarter = false;
		let hasLowerCasevarter = false;
		let hasDecimalDigit = false;

		if (meetsLengthRequirements) {
			for (var i = 0, len = password.length; i < len; i++) {
				var char = password.charAt(i);
				if (!isNaN(+char * 1)) {
					hasDecimalDigit = true;
				} else {
					if (char == char.toUpperCase()) {
						hasUpperCasevarter = true;
					}
					if (char == char.toLowerCase()) {
						hasLowerCasevarter = true;
					}
				}
			}
		}

		var isValid =
			meetsLengthRequirements &&
			hasUpperCasevarter &&
			hasLowerCasevarter &&
			hasDecimalDigit;
		return isValid;
	};

	const submitHandler = (e) => {
		e.preventDefault();
		if (!validatePassword(password, cpassword)) {
			Swal.fire(
				"Oops",
				"Password need 6 characters, including an uppercase and lowercase letter and a number.",
				"error"
			);
			return false;
		}
		if (password !== cpassword) {
			Swal.fire("Oops", "password does not match", "warning");
			return;
		}
		e.preventDefault();
		const payload = {
			password,
			token,
		};
		if (password !== "") {
			axiosInstance
				.post("/v1/admin/auth/reset", payload)
				.then((res) => {
					// localStorage.clear();
					//   console.log("qwerty Show : ", res?.data?.result?.accessToken);
					//   setShowLoader(false);

					// if (res?.data?.result?.email) {
					//   setStep1Complete(true);
					//   setLoginStepInfo({
					//     qr: res?.data?.result?.qr,
					//     email: res?.data?.result?.email,
					//   });
					// } else {
					//   setStep1Complete(false);
					// }
					Swal.fire("Great", "Password Reset Successfully", "success").then(
						(res) => history.push("/")
					);
				})
				.catch((err) => Swal.fire("Oops!", err.response.data.message, "error"));
		}
	};

	return (
		<div>
			<h3>Forgot your password?</h3>
			<form className="form-group" onSubmit={submitHandler}>
				<br />
				<label>New Password</label>
				<input
					type="password"
					name="password"
					className="form-control"
					placeholder="Enter Password"
					value={password}
					onChange={(e) => setPassword(e?.target?.value)}
					required
				/>
				<br />
				<label>Confirm password</label>
				<input
					type="password"
					name="cpassword"
					className="form-control"
					placeholder="re-enter password"
					value={cpassword}
					onChange={(e) => setCpassword(e?.target?.value)}
					required
				/>
				<br />
				{/* <p style={{ color: "red", fontSize: "11px" }}>Hint</p> */}
				<Alert severity="warning">
					Password need 6 characters, including an uppercase and lowercase
					letter and a number.
				</Alert>
				<p style={{ color: "red", fontSize: "10px" }}></p>
				<button type="submit" className="btn btn-dark btn-lg btn-block">
					Submit
				</button>
			</form>
		</div>
	);
}
