/* eslint-disable eqeqeq */

import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";

import "./CollectionTable.css";
import { axiosInstance } from "../../utility/api";
// import NoRecordsFound from "../../common/Error/NoRecordsFound";
import { useDebounce } from "../../hooks/useDebounce";
import Loader from "../common/Loader/Loader";
import editIcon from "../../assets/images/icons/edit.svg";
import deleteIcon from "../../assets/images/icons/delete.svg";
import ToggleLeft from "../../assets/images/icons/toggle-left.svg";
import ToggleRight from "../../assets/images/icons/toggle-right.svg";
import NoRecordsFound from "../common/Error/NoRecordsFound";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import AssetsByCollection from "./AssetsByCollection";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";

const useRowStyles = makeStyles({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
  centerDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});

//Each row of table
function Row(props) {
  const {
    //row,
    data,
    tableColumnWidth,
    // setShowLoader,
    // showLoader,
    setOpenEdit,
    //selectedRow,
    setSelectedRow,
  } = props;
  const [open, setOpen] = React.useState(false);
  //const [deleteCategoryID, setDeleteCategoryID] = useState(null);
  // const token = JSON.parse(localStorage.getItem("token"));
  const [openModal, setOpenModal] = React.useState(false);
  const classes = useRowStyles();

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const toggleResale = (id, toggleStatus) => {
    console.log("deleted 2", id);
    if (id) {
      confirmAlert({
        title: "Toggle Resale?",
        message: "Are you sure to change status.",
        buttons: [
          {
            label: "Yes",
            onClick: () => handleResale(id, toggleStatus),
          },
          {
            label: "No",
            onClick: () => console.log("Clicked No"),
          },
        ],
      });
    }
  };

  const handleResale = (id, toggleStatus) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };
    if (config?.headers?.Authorization) {
      axiosInstance
        .patch(
          `/v1/collection/updateResale/${id}`,
          { allowResale: !toggleStatus },
          config
        )
        .then((res) => {
          Swal.fire("Success!", res?.data?.message, "success");
        })
        .then(() => {
          window.location.reload(true);
        })
        .catch((err) => {
          console.log("rrr eror", err?.response.data?.message);
          Swal.fire("Error!", err?.response?.data?.message, "error");
        });
    }
  };

  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.rowDatas}
        style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          <div className="columnStyles">{data?.name ? data?.name : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
          <div className="columnStyles">
            {data?.assetCount ? data?.assetCount : "-"}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div className="columnStyles">
            {data?.updatedAt
              ? moment.utc(data?.updatedAt).local().format('DD/MM/YYYY')
              : "-"}
          </div>
        </TableCell>
        {/* <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
         
          <Tooltip title="Toggle Resale">
            <img
              src={data?.allowResale ? ToggleRight : ToggleLeft}
              onClick={async () => {
                //await setDeleteCategoryID(data?.id);
                toggleResale(data?.id, data?.allowResale);
              }}
              className="marginLeft20 cursorPointer"
              alt="Delete"
            />
          </Tooltip>
        </TableCell> */}
        <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="columnStyles-sm columnStyles" onClick={handleOpen}>
            <div className="viewOnPolygonScan">View</div>
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModal}>
              <div className={classes.centerDiv}>
                <AssetsByCollection
                  userID={data?.id}
                  closeModal={(val) => setOpenModal(false)}
                  assets={data?.assets}
                />
              </div>
            </Fade>
          </Modal>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {columns?.map(({ name, field, sortable, image }) => (
          <TableCell
            align={columns.align}
            key={name}
            className={classes.cursorDefault}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
               
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function CollectionTable({
  data,
  showLoader,
  setShowLoader,
  refreshTable,
}) {
  const classes = useRowStyles();
  // const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [collectionList, setCollectionList] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);
  const [refreshCollectionTable, setRefreshCollectionTable] = useState(false);
  const [searchText, setSearchText] = useState("");
  // const [filterSelected, setFilterSelected] = useState([]);
  // const [statusFilterSelected, setStatusFilterSelected] = useState(false);
  // const [isDateFilterEnabled, setIsDateFilterEnabled] = useState(null);
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  //const token = JSON.parse(localStorage.getItem("token"));
  // const headers = {
  // 	headers: {
  // 		Authorization: `Bearer ${token}`,
  // 	},
  // };

  // Date conversation for DateRange filer
  //let today = new Date();
  // let year = today.getFullYear();
  // let month = today.getMonth();
  // let day = today.getDate();
  //let date1MonthBefore = new Date(year, month - 1, day);
  //let date1DayBefore = new Date(year, month, day - 1);

  // const [selectionRange, setSelectionRange] = useState({
  // 	startDate: date1DayBefore,
  // 	endDate: new Date(),
  // 	key: "selection",
  // });

  const tableColumnWidth = [25, 10, 25, 20, 20];
  const columns = [
    {
      name: "Name",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },

    {
      name: "Asset Count",
      field: "count",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Created Date",
      field: "label",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "Resale Status",
    //   field: "label",
    //   // image: Divider,
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },

    {
      name: "View Assets",
      field: "viewAsset",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
  ];

  useEffect(() => {
    getCollectionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, refreshTable, refreshCollectionTable]);

  useDebounce(
    () => {
      if (searchText != null && searchText != "" && searchText != undefined) {
        getCollectionList();
      }
    },
    [searchText],
    1000
  );

  const handleChangePage = (event, value) => {
    setPageNo(value);
    window.scrollTo(0, 0);
  };

  const getCollectionList = async () => {
    let urlParam = {
      params: {
        page: pageNo,
        limit: 10,
        search: searchText,
        // fromDate: isDateFilterEnabled ? selectionRange?.startDate : "",
        // toDate: isDateFilterEnabled ? selectionRange?.endDate : "",
      },
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };

    await axiosInstance
      .get(`/v1/collectionData`, urlParam)
      .then((result) => {
        setCollectionList(result?.data?.result?.collections);
        setTotalCount(result?.data?.result?.totalItems);
        setTotalPages(result?.data?.result?.totalPages);
      })
      .catch((err) => {
        if (
          err?.response &&
          err?.response?.data?.errors &&
          err?.response?.data?.errors?.length !== 0
        ) {
          Swal.fire({
            title: "Oops!",
            text: err?.response?.data?.errors.map((err) => err.msg),
            icon: "error",
          });
        } else {
          Swal.fire("Oops!", err?.response?.data?.message, "error");
        }
      });
  };

  return (
    <div className="table_div tableWidth100">
      {showLoader == true ? <Loader /> : null}
      <div className="searchBoxProduct">
        {/* <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<CloudDownloadIcon>Report</CloudDownloadIcon>}
          onClick={() => downloadReport()}
        >
          Report
        </Button> */}
        {/* <Button
          variant="outlined"
          onClick={() => {
            setSelectionRange({
              startDate: date1MonthBefore,
              endDate: new Date(),
              key: "selection",
            });
            // setStatusFilterSelected(-1);
            setSearchText("");
            setIsDateFilterEnabled(false);
            setPageNo(1);
          }}
        >
          Reset Filter
        </Button> */}
        {/* <div
          className="height55px displayFlex"
          onClick={() => setIsDateFilterEnabled(true)}
        >
          <DataRangePopOver
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
            handleSelect={handleSelect}
          />
        </div> */}
        {/* <FormControl variant="outlined" className={classes.height24}>
          <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
          <Select
            native
            value={statusFilterSelected}
            onChange={(e) => {
              setStatusFilterSelected(e.target.value);
              setPageNo(1);
            }}
            label="Filter"
            inputProps={{
              name: "Filter",
              id: "outlined-age-native-simple",
            }}
            IconComponent={FilterListIcon}
            displayEmpty={true}
          >
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                clipRule="evenodd"
              />
            </svg>
            <optgroup label="Status">
              <option value={-1}>All</option>
              <option value={"blocked"}>Blocked</option>
              <option value={"active"}>Active</option>
            </optgroup>
          </Select>
        </FormControl> */}
        {/* <Autocomplete
          id="search-box-product"
          options={searchSuggestions}
          disableClearable={true}
          clearOnBlur={false}
          getOptionLabel={(option) => {
            return option.name || "";
          }}
          style={{ width: 300 }}
          inputValue={searchText}
          renderInput={(params) => (
            <TextField
              {...params}
              // value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setPageNo(1);
              }}
              // onKeyDown={(e) => handleSearchBoxKeyDown(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              label="Search Category"
              variant="outlined"
            />
          )}
        /> */}
      </div>
      {collectionList?.length !== 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className={classes.root}>
              <RenderHeader
                columns={columns}
                // onSorting={(field, order) => setSorting({ field, order })}
              />
              <TableBody style={{ width: "100%" }}>
                {collectionList?.map((row, index) => (
                  <Row
                    aria-label="expand row"
                    size="small"
                    key={row?.id}
                    row={row}
                    setSelectedRow={(value) => setSelectedRow(value)}
                    selectedRow={selectedRow}
                    data={collectionList[index]}
                    tableColumnWidth={tableColumnWidth}
                    setOpenEdit={setOpenEdit}
                    // setShowLoader={setShowLoader}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            page={pageNo}
            onChange={handleChangePage}
          />
        </>
      ) : (
        <div className="noResultFound flexColumnCenter">
          <NoRecordsFound message="No records found." />
        </div>
      )}
    </div>
  );
}
