import React from 'react';

const InputField = ({
  label,
  name,
  value,
  handleChange,
  required,
  isEdit,
  maxLength,
}) => {
  const charactersRemaining = maxLength ? maxLength - (value ? value.length : 0) : null;

  return (
    <div className="form-group mb-3">
      <label htmlFor={name}>{label}</label>
      {required && <span className="colorRed">*</span>}
      <input
        type="text"
        name={name}
        className="form-control validate"
        value={value}
        onChange={(e) => handleChange(e)}
        required={required}
        disabled={isEdit ? true : false}
        maxLength={maxLength}
      />
      {maxLength && (
        <div className="charactersRemaining">
          <span>({charactersRemaining !== null ? charactersRemaining : maxLength} characters remaining)</span>
        </div>
      )}
    </div>
  );
};

export default InputField;
