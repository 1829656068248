/* eslint-disable eqeqeq */
import React, { useState } from "react";
import "./index.css";
import Category from "./categories";
import Subcategory from "./subcategory/index";

export default function CommissionIndex() {
	const [selectedTab, setSelectedTab] = useState("category");
	return (
		<div>
			<div className="categoryContent">
				<div className="boleroTabsSection">
					<div
						className={
							"UsersTabOption " +
							(selectedTab == "category" ? "userTabSelected" : "")
						}
						onClick={() => {
							setSelectedTab("category");
						}}
					>
						Category
					</div>
					<div
						className={
							"boleroTabCurve" +
							(selectedTab == "category" ? "userTabSelected" : "")
						}
						onClick={() => {
							setSelectedTab("category");
						}}
					></div>

					<div
						className={
							"UsersTabOption " +
							(selectedTab == "subCategory" ? "userTabSelected" : "")
						}
						onClick={() => {
							setSelectedTab("subCategory");
						}}
					>
						Sub category
					</div>
					<div
						className={
							"boleroTabCurve " +
							(selectedTab == "subCategory" ? "userTabSelected" : "")
						}
						onClick={() => {
							setSelectedTab("subCategory");
						}}
					></div>
				</div>
				{selectedTab == "category" && <Category />}
				{selectedTab == "subCategory" && (
					<Subcategory
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
					/>
				)}
			</div>
		</div>
	);
}
