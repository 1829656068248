import React, { useState } from "react";
import UiDrawer from "../creators/UiDrawerEditCreator";
import ReportsTable from "./ReportsTable";

export default function ImageUpload() {
  const [open, setOpen] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  // const token = JSON.parse(localStorage.getItem("token"));

  return (
    <>
      <div className="products-wrapper">
        <div className="container-fluide">
          <div className="page-title">
            <div className="row">
              <div className="col-6">
                <h3>Image Bulk Upload</h3>
              </div>

{/* 
              <div
                className="col-12 mt-4"
                style={{ justifySelf: "flexgit -end" }}
              >
                <button
                  className={"btn btn-primary"}
                  onClick={() => setOpen(true)}
                >
                  + Add Creator
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="products-content">
          <ReportsTable />
        </div>
      </div>
    </>
  );
}
