var CryptoJS = require("crypto-js");

// Encrypt
// var ciphertext = CryptoJS.AES.encrypt('my message', 'secret key 123').toString();
export const ciphertextCustomerAssetView = CryptoJS.AES.encrypt(
  "admin-view",
  "re@!89tKey"
).toString();

// Decrypt
// var bytes = CryptoJS.AES.decrypt(ciphertext, "secret key 123");
// var originalText = bytes.toString(CryptoJS.enc.Utf8);
