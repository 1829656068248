/* eslint-disable eqeqeq */
import React from "react";
import Swal from "sweetalert2";
import { Drawer } from "@material-ui/core";
import Loader from "../../../common/Loader/Loader";
import { axiosInstance } from "../../../../utility/api";
import AddSubCategoryForm from "./addSubCategoryForm";

const styles = {
  sideNav: {
    marginTop: "-60px",
    zIndex: 3,
    marginLeft: "0px",
    position: "fixed",
  },
  link: {
    color: "black",
    textDecoration: "none",
  },
  title: {
    width: "90%",
    marginBottom: "20px",
  },
  productCard: {
    boxShadow: "0px 0px 2px #bbb",
    padding: "10px",
    marginBottom: "20px",
  },
};

// const useStyles = makeStyles((theme) => ({
//   rootSnackBar: {
//     width: "100%",
//     "& > * + *": {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));

export default class UiDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpened: false,
      name: "",
      categoryId: "",
      error: {
        status: false,
        message: "",
      },
      showLoader: {
        open: false,
        message: "",
      },
      isEdit: false,
      totalCount: 0,
      subCategoryList: null,
      pageNo: 0,
    };
  }

  config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  // classes = useStyles();

  componentDidMount() {
    if (this?.props?.selectedRow) {
      this.setState({
        ...this.props.selectedRow,
        isEdit: true,
      });
    } else {
      this.setState({
        ...this.state,
        isEdit: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.selectedRow?.id !== this?.props?.selectedRow?.id) {
      this.setState({
        ...this.props.selectedRow,
        isEdit: true,
      });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  toggleDrawerStatus = () => {
    this.setState({
      isDrawerOpened: true,
    });
  };

  closeDrawer = () => {
    this.setState({
      isDrawerOpened: false,
    });
  };

  setShowLoader = (value, message) => {
    this.setState({
      ...this.state,
      showLoader: { open: value, message: message },
    });
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    this.setState({
      error: { status: false, message: "" },
    });
    if (
      this.state.name == "" ||
      this.state.name == undefined ||
      this.state.name == null
    ) {
      Swal.fire("Oops!", "Please enter name.", "error");
      // this.setState({
      //   error: { status: true, message: "Please enter a name." },
      // });
      return false;
    }
    if (
      this.state.categoryId == "" ||
      this.state.categoryId == undefined ||
      this.state.categoryId == null
    ) {
      Swal.fire("Oops!", "Please select a category.", "error");
      // this.setState({
      //   error: { status: true, message: "Please enter a slug." },
      // });
      return false;
    }

    this.setShowLoader(true, "Please Wait.");

    const data = { ...this.state };
    delete data.isDrawerOpened;
    delete data.showLoader;
    // delete data.catagories;
    // delete data.pactive;
    // delete data.value;
    delete data.error;
    delete data.setOpenSnackBar;

    delete data.imageUrl;

    if (!this.state.isEdit) {
      delete data.isEdit;
      axiosInstance
        .post("/v1/admin/category/subCategory", data, this.config)
        .then((res) => {
          console.log("posted");
          // this.setState({
          //   ...this.state,
          //   showLoader: false,
          // });
          this.setShowLoader(false, "");
          this.setState({
            showLoader: false,
            isDrawerOpened: false,
            name: "",
            // catagories: [],
            categoryId: "",
            error: {
              status: false,
              message: "",
            },
          });
          this.props.onClose();
          Swal.fire("Success!", res?.data?.message, "success");
          window.location.reload(true);
          this.props.setSelectedTab("subCategory");
        })
        .then(() => {
          axiosInstance
            .get(
              `/v1/admin/category/subCategory?page=${
                this.state.pageNo + 1
              }&limit=10`,
              this.config
            )
            .then((result) => {
              this.props.setSubCategoryList(result?.data?.result?.assets);
              this.props.setTotalCount(result?.data?.result?.totalItems);
            })
            .catch((err) => {
              if (
                err?.response &&
                err?.response?.data?.errors &&
                err?.response?.data?.errors?.length !== 0
              ) {
                Swal.fire({
                  title: "Oops!",
                  text: err?.response?.data?.errors.map((err) => err.msg),
                  icon: "error",
                });
              } else {
                Swal.fire("Oops!", err?.response?.data?.message, "error");
              }
            });
        })
        .catch((err) => {
          this.setShowLoader(false, "");
          if (
            err?.response &&
            err?.response?.data?.errors &&
            err?.response?.data?.errors?.length > 1
          ) {
            Swal.fire({
              title: "Oops!",
              text: err?.response?.data?.errors.map((err) => err.msg),
              icon: "error",
            });
          } else {
            Swal.fire("Oops!", err?.response?.data?.message, "error");
          }
        });
    } else {
      this.handleEditForm(e);
      this.setShowLoader(false, "");
    }
  };

  handleEditForm = (e) => {
    e.preventDefault();
    this.setState({
      error: { status: false, message: "" },
    });
    this.setShowLoader(true, "Please Wait");
    const data = { ...this.state };
    delete data.isDrawerOpened;
    delete data.catagories;
    // if (data.pactive === "yes") data.status = "active";
    // else data.status = "not active";
    delete data.pactive;
    delete data.value;
    delete data.error;
    delete data.showLoader;
    delete data.setOpenSnackBar;
    delete data.createdAt;
    delete data.counts;
    delete data.id;
    delete data.isDeleted;
    delete data.updatedAt;
    delete data.isEdit;
    delete data.imageUrl;
    // delete data.categoryId;
    // delete data.name;
    delete data.pageNo;
    delete data.subCategoryList;
    delete data.totalCount;
    // delete data.category;

    axiosInstance
      .patch(
        `/v1/admin/category/subCategory/${this.state.id}`,
        data,
        this.config
      )
      .then((res) => {
        console.log("Updated");
        Swal.fire("Success!", res?.data?.message, "success");
        window.location.reload(true);
        this.props.onClose();
        this.setShowLoader(false, "");
      })
      .then(() => {
        axiosInstance
          .get(
            `/v1/admin/category/subCategory?page=${
              this.state.pageNo + 1
            }&limit=10`,
            this.config
          )
          .then((result) => {
            this.props.setSubCategoryList(result?.data?.result?.assets);
            this.props.setTotalCount(result?.data?.result?.totalItems);
          })
          .catch((err) => {
            if (
              err?.response &&
              err?.response?.data?.errors &&
              err?.response?.data?.errors?.length !== 0
            ) {
              Swal.fire({
                title: "Oops!",
                text: err?.response?.data?.errors.map((err) => err.msg),
                icon: "error",
              });
            } else {
              Swal.fire("Oops!", err?.response?.data?.message, "error");
            }
          });
      })

      .catch((err) => {
        this.setShowLoader(false, "");
        if (
          err.response &&
          err.response.data.errors &&
          err.response.data.errors.length > 1
        ) {
          Swal.fire({
            title: "Oops!",
            text: err?.response?.data?.errors,
            icon: "error",
          });
        } else {
          Swal.fire("Oops!", err?.response?.data?.message, "error");
        }
      });
  };

  render() {
    const isDrawerOpened = this.props.open;
    return (
      <div>
        <Drawer
          anchor="right"
          variant="temporary"
          open={isDrawerOpened}
          onClose={this.props.onClose}
        >
          {this.state.showLoader.open == true && (
            <Loader loaderInfo={this.state.showLoader.message} />
          )}
          <AddSubCategoryForm
            style={{ ...styles.title, marginBottom: "30px" }}
            state={this.state}
            // onChangeDescription={(event, editor) => {
            //   const data = editor.getData();
            //   this.setState({ description: data });
            // }}

            handleChange={this.handleChange}
            // handleImageUpload={this.handleImageUpload}
            // loadfile={this.loadfile}
            // categoryList={this.state.catagories}
            handleSubmitForm={this.handleSubmitForm}
            isEdit={this.state.isEdit}
          />
        </Drawer>
      </div>
    );
  }
}
