/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Drawer } from "@material-ui/core";

import Checkbox from "./checkbox";
import { axiosInstance, baseURL } from "../../utility/api";

const UiDrawer = (props) => {
	const { permission, setPermission } = props;
	const [newsTgle, setNewstgle] = useState(false);
	const [aucTgle, setAucTgle] = useState(false);
	const [catTgle, setCatTgle] = useState(false);
	const [userTgle, setUserTgle] = useState(false);
	const [assetsTgle, setAssetsTgle] = useState(false);
	const [comTgle, setComTgle] = useState(false);
	const [transTgle, setTransTgle] = useState(false);
	const [analyTgle, setAnalyTgle] = useState(false);
	const [ordersTgle, setOrdersTgle] = useState(false);

	const {
		state,
		setState,
		// selectHandler,
		selectAction,
		// setSelectAction,
		isEdit,
		// checked,
		// expanded,
		// setChecked,
		// setExpanded,
	} = props;
	// const [erorr, setError] = useState({
	//   status: false,
	//   message: "",
	// });
	const [showLoader, setShowLoader] = useState({
		open: false,
		message: "",
	});

	const config = {
		headers: {
			Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
		},
	};

	useEffect(() => {
		axiosInstance
			.get(`${baseURL}v1/admin/base/permission`, config)
			.then((res) => {
				setShowLoader(false);
				setPermission(res.data.result);
				// setError({
				//   status:false,
				//   message:""
				// })

				// props.onClose();
				// Swal.fire("Success!", res?.data?.message, "success");
			})
			.catch((err) => {
				setShowLoader(false);
				if (
					err.response &&
					err.response.data.errors &&
					err.response.data.errors.length > 1
				) {
					Swal.fire({
						title: "Oops!",
						text: err?.response?.data?.errors,
						icon: "error",
					});
				} else {
					Swal.fire("Oops!", err?.response?.data?.message, "error");
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setState((prev) => ({ ...prev, [name]: value }));
	};

	const handleOnChange = (e) => {
		const { name, checked, value } = e.target;
		if (name === "all") {
			setPermission((pre) => ({
				...pre,
				[value]: {
					...pre[value],
					add: checked,
					delete: checked,
					edit: checked,
					view: checked,
					// all: checked
				},
			}));
		} else {
			setPermission((pre) => {
				return {
					...pre,
					[value]: { ...pre[value], [name]: checked },
				};
			});
		}
	};
	const handleSubmitForm = (e) => {
		e.preventDefault();
		let result = {};

		// if (state.firstname === "" || state.lastname === "" || state.email === "") {
		//   Swal.fire("Oops!", "Please enter all the required fields.", "error");
		//   return false;
		// }

		props.setShowLoader(true);

		if (state.password !== state.confirmPassword) {
			Swal.fire("info", "Incorrect password entered", "error");
			return;
		}
		if (Object.entries(permission).length > 0) {
			const newDat = Object.entries(permission).map((e) => ({
				[e[0]]: [e[1]],
			}));

			for (var i = 0; i < newDat.length; i++) {
				result = { ...result, ...newDat[i] };
			}
		}

		const data = {
			firstName: state.firstName,
			lastName: state.lastName,
			email: state.email,
			password: state.password,
			permission: result,
		};

		const upDatedata = {
			firstName: state.firstName,
			lastName: state.lastName,
			email: state.email,
			// password: state.password,
			permissionId: state.permissionId,
			permission: result,
		};

		let permission_data = Object.values(result);
		let onlytrue = permission_data.map((item) =>
			Object.values(item[0]).find((i) => i === true)
		);
		if (!onlytrue.includes(true)) {
			Swal.fire("Oops", "You must give at least one permission", "info");
			return;
		}
		if (isEdit === false) {
			axiosInstance
				.post(`${baseURL}v1/admin/signUp`, data, config)
				.then((res) => {
					setShowLoader(false);
					// setError({
					//   status: false,
					//   message: "",
					// });
					props.onClose();
					Swal.fire("Success!", res?.data?.message, "success").then((res) =>
						window.location.reload()
					);
				})
				.catch((err) => {
					setShowLoader(false);
					if (
						err.response &&
						err.response.data.errors &&
						err.response.data.errors.length > 1
					) {
						Swal.fire({
							title: "Oops!",
							text: err?.response?.data?.errors,
							icon: "error",
						});
					} else {
						Swal.fire("Oops!", err?.response?.data?.message, "error");
					}
				});
		} else {
			axiosInstance
				.patch(`${baseURL}v1/sub-admin/profile/${state.id}`, upDatedata, config)
				.then((res) => {
					setShowLoader(false);
					// setError({
					//   status: false,
					//   message: "",
					// });
					props.onClose();
					Swal.fire("Success!", res?.data?.message, "success").then((res) =>
						props.onSuccess()
					);
				})
				.catch((err) => {
					setShowLoader(false);
					if (
						err.response &&
						err.response.data.errors &&
						err.response.data.errors.length > 1
					) {
						Swal.fire({
							title: "Oops!",
							text: err?.response?.data?.errors,
							icon: "error",
						});
					} else {
						Swal.fire("Oops!", err?.response?.data?.message, "error");
					}
				});
			// handleEditForm(e);
			setShowLoader(false);
		}
	};

	return (
		<div>
			<Drawer
				anchor="right"
				variant="temporary"
				open={props.open}
				onClose={props.onClose}
			>
				{showLoader.open == true && (
					// <Loader loaderInfo={showLoader.message} />
					<p></p>
				)}

				<div className="container tm-mt-big tm-mb-big">
					<div className="row">
						<div className="col-xl-9 col-lg-10 col-md-12 col-sm-12 mx-auto">
							<div className="tm-bg-primary-dark tm-block tm-block-h-auto">
								<div className="row">
									<div className="col-12">
										<h2 className="tm-block-title d-inline-block">
											{props.isEdit === true ? "Edit" : "Add"} Sub Admins
										</h2>
									</div>
								</div>
								<div className="row tm-edit-product-row">
									<div className="col-xl-12 col-lg-6 col-md-12">
										<form action="" className="tm-edit-product-form">
											<div className="form-group mb-3">
												<label htmlFor="firstName">First Name</label>
												<span className="colorRed">*</span>
												<input
													type="text"
													name="firstName"
													className="form-control validate"
													value={state.firstName}
													onChange={(e) => handleChange(e)}
													required
												/>
												<label htmlFor="lastName">Last Name</label>
												<span className="colorRed">*</span>
												<input
													type="text"
													name="lastName"
													className="form-control validate"
													value={state.lastName}
													onChange={(e) => handleChange(e)}
													required
												/>
												<label htmlFor="email">Email</label>
												<span className="colorRed">*</span>
												<input
													type="text"
													name="email"
													className="form-control validate"
													value={state.email}
													onChange={(e) => handleChange(e)}
													required
												/>
												{!isEdit && (
													<>
														<label htmlFor="password ">Password</label>
														<span className="colorRed">*</span>
														<input
															type="password"
															name="password"
															className="form-control validate"
															value={state.password}
															onChange={(e) => handleChange(e)}
															required
														/>
														<label htmlFor="password ">Confirm Password</label>
														<span className="colorRed">*</span>
														<input
															id="passwoManage Sub Adminrd"
															type="password"
															name="confirmPassword"
															className="form-control validate"
															value={state.confirmPassword}
															onChange={(e) => handleChange(e)}
															required
														/>
													</>
												)}
												<div className="mt-4">
													<label htmlFor="email">Permissions</label>
													<span className="colorRed">*</span>
													{Object.entries(permission).length > 0 ? (
														<div className="App">
															<Checkbox
																title={"News"}
																objName={"news"}
																handleOnChange={handleOnChange}
																permission={permission}
																toggle={newsTgle}
																setToggle={setNewstgle}
																// val={"news"}
															/>
															<Checkbox
																title={"Auction"}
																objName={"auction"}
																handleOnChange={handleOnChange}
																permission={permission}
																toggle={aucTgle}
																setToggle={setAucTgle}
															/>
															<Checkbox
																title={"Category"}
																objName={"category"}
																permission={permission}
																handleOnChange={handleOnChange}
																toggle={catTgle}
																setToggle={setCatTgle}
															/>
															<Checkbox
																title={"User"}
																objName={"user"}
																permission={permission}
																handleOnChange={handleOnChange}
																toggle={userTgle}
																setToggle={setUserTgle}
															/>
															<Checkbox
																title={"Commissions"}
																objName={"commission"}
																permission={permission}
																handleOnChange={handleOnChange}
																toggle={comTgle}
																setToggle={setComTgle}
															/>
															<Checkbox
																title={"Orders"}
																objName={"order"}
																permission={permission}
																handleOnChange={handleOnChange}
																toggle={ordersTgle}
																setToggle={setOrdersTgle}
															/>

															<Checkbox
																title={"Assets"}
																objName={"asset"}
																permission={permission}
																handleOnChange={handleOnChange}
																toggle={assetsTgle}
																setToggle={setAssetsTgle}
															/>
															<Checkbox
																title={"Transaction History"}
																objName={"transaction-history"}
																permission={permission}
																handleOnChange={handleOnChange}
																toggle={transTgle}
																setToggle={setTransTgle}
															/>
															<Checkbox
																title={"Analytics Dashboard"}
																objName={"analytic-dashboard"}
																permission={permission}
																handleOnChange={handleOnChange}
																toggle={analyTgle}
																setToggle={setAnalyTgle}
															/>
														</div>
													) : (
														<div>
															<p>No Data Found!!!</p>
														</div>
													)}
												</div>
											</div>
										</form>
									</div>

									{/* {state.error.status == true ? (
                  <div className="errorLabel col-6 col-md-12">
                    {state?.error?.message}
                  </div>
                ) : null} */}
									<div className="col-12">
										<button
											type="submit"
											className="btn btn-primary btn-block text-uppercase"
											onClick={handleSubmitForm}
											disabled={
												state.firstname ||
												state.lastname ||
												state.email ||
												selectAction
													? false
													: true
											}
										>
											Save Sub Admin.
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Drawer>
		</div>
	);
};

export default UiDrawer;
