import React, { Fragment } from 'react';
import Breadcrumb from '../../layout/breadcrumb';
import { Container, Row, Col } from 'reactstrap';


const Welcome = (props) => {
    return (
        <Fragment>
            <div style={{ padding: "40px 0px" }}>
                <div style={{ margin:"auto",width: 300 }}>
                    <img src={require("../../assets/images/logo/logo_asly.png")} alt="logo"
                        className='img-fluid' style={{opacity: '0'}}/>
                </div>
                <Breadcrumb parent="Dashboard" title="Welcome to lii." />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <p style={{ fontSize: 17, fontWeight: "500" }}>
                                <br/>
                                Browse using avaliable options in the Sidebar Navigation.
                            </p>

                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default Welcome;