/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
// import ProductTable from "../common/tableExpandable.jsx";
import SubCategoriesTable from "./SubCategoryTable";
import UiDrawer from "./components/addSubCategoryDrawer";
import { axiosInstance } from "../../../utility/api";
import Swal from "sweetalert2";
import { useDebounce } from "../../../hooks/useDebounce";

export default function SubCategory(props) {
	const [open, setOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [subCategoryList, setSubCategoryList] = useState(null);
	const [pageNo, setPageNo] = useState(1);
	const [searchText, setSearchText] = useState("");
	const [filterSelected, setFilterSelected] = useState([]);
	//const token = JSON.parse(localStorage.getItem("token"));
	// const header = {
	// 	headers: {
	// 		Authorization: `Bearer ${token}`,
	// 	},
	// };

	useEffect(() => {
		getSubCategoryList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNo, filterSelected]);

	useDebounce(
		() => {
			if (searchText != null && searchText != "" && searchText != undefined) {
				getSubCategoryList();
			}
		},
		[searchText],
		1000
	);

	const getSubCategoryList = async () => {
		let urlParam = {
			params: {
				page: pageNo,
				limit: 10,
				search: searchText,
				categoryId: filterSelected != -1 ? filterSelected : "",
				// fromDate: isDateFilterEnabled ? selectionRange?.startDate : "",
				// toDate: isDateFilterEnabled ? selectionRange?.endDate : "",
			},
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
			},
		};
		await axiosInstance
			.get(`/v1/admin/category/subCategory`, urlParam)
			.then((result) => {
				setSubCategoryList(result?.data?.result?.assets);
				setTotalCount(result?.data?.result?.totalItems);
				setTotalPages(result?.data?.result?.totalPages);
			})
			.catch((err) => {
				if (
					err?.response &&
					err?.response?.data?.errors &&
					err?.response?.data?.errors?.length !== 0
				) {
					Swal.fire({
						title: "Oops!",
						text: err?.response?.data?.errors.map((err) => err.msg),
						icon: "error",
					});
				} else {
					Swal.fire("Oops!", err?.response?.data?.message, "error");
				}
			});
	};

	// const getIsEdit = () => {
	//   setIsEdit(true);
	// };

	return (
		<>
			<div className="products-wrapper">
				<div className="container-fluide">
					<div className="page-title">
						<div className="row">
							<div className="col-6">
								<h3>Sub Categories</h3>
							</div>

							{/* <div className="col-6">
                <nav className="" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/products/products/:layout">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                      </a>
                    </li>
                    <li className="active breadcrumb-item" aria-current="page">
                      Sub Categories
                    </li>
                  </ol>
                </nav>
              </div> */}
							<div
								className="col-12 mt-4"
								style={{ justifySelf: "flexgit -end" }}
							>
								<button
									className={"btn btn-primary"}
									onClick={() => setOpen(true)}
								>
									+ Add Sub Category
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="products-content">
					<SubCategoriesTable
						selectedTab={props.selectedTab}
						subCategoryList={subCategoryList}
						totalCount={totalCount}
						setPageNo={setPageNo}
						pageNo={pageNo}
						setIsEdit={(value) => setIsEdit(value)}
						setSubCategoryList={setSubCategoryList}
						setTotalCount={setTotalCount}
						totalPages={totalPages}
						searchText={searchText}
						setSearchText={(value) => {
							setSearchText(value);
						}}
						filterSelected={filterSelected}
						setFilterSelected={setFilterSelected}
						// setOpenEdit={() => {
						//   setOpen(true);
						//   console.log("ppp isedit 1", isEdit);
						//   getIsEdit();
						//   console.log("ppp isedit 2", isEdit);
						// }}
					/>
					{/* <ProductTable
            data={productList}
            columns={columns}
            tableColumnWidth={tableColumnWidth}
            totalDoc={totalDoc}
            pageNo={pageNo} <ProductTable
            data={productList}
            columns={columns}
            tableColumnWidth={tableColumnWidth}
            totalDoc={totalDoc}
            pageNo={pageNo}
            setPageNo={(pageNo) => setpageNo(pageNo)}
            showLoader={showLoader}
            setShowLoader={(e) => setShowLoader(e)}
            searchText={searchText}
            setSearchText={setSearchText}
            filterSelected={filterSelected}
            setFilterSelected={setFilterSelected}
          />
            setPageNo={(pageNo) => setpageNo(pageNo)}
            showLoader={showLoader}
            setShowLoader={(e) => setShowLoader(e)}
            searchText={searchText}
            setSearchText={setSearchText}
            filterSelected={filterSelected}
            setFilterSelected={setFilterSelected}
          /> */}
				</div>
			</div>

			<UiDrawer
				open={open}
				onClose={() => setOpen(false)}
				showLoader={showLoader}
				setShowLoader={(e) => setShowLoader(e)}
				isEdit={isEdit}
				setSelectedTab={props?.setSelectedTab}
				setSubCategoryList={setSubCategoryList}
				setTotalCount={setTotalCount}
			/>
		</>
	);
}
