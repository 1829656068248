import React from "react";
import "./checkbox.css";

const Checkbox = (props) => {
	const {
		title = "",
		permission = "",
		objName = "",
		handleOnChange = null,
		toggle = false,
		// setToggle = null,
	} = props;

	return (
		<>
			<div>
				<div className="permission_checkbox_all">
					<span
						className="font-black"
						//   onClick={()=>{
						//     setToggle(!toggle);
						//   }}
					>
						{title}
					</span>
					<input
						type="checkbox"
						name="all"
						onChange={handleOnChange}
						value={objName}
						checked={
							permission[objName]?.all ||
							permission[objName]?.add ||
							permission[objName]?.view ||
							permission[objName]?.delete ||
							permission[objName]?.edit
						}
					/>
				</div>

				{toggle && (
					<>
						<div className="permission_checkbox_siblings">
							<div className="permission_checkbox">
								<span>Add</span>
								<input
									type="checkbox"
									name="add"
									onChange={handleOnChange}
									value={objName}
									checked={permission[objName]?.add}
								/>
							</div>
							<div className="permission_checkbox">
								<span>Delete</span>
								<input
									type="checkbox"
									name="delete"
									onChange={handleOnChange}
									value={objName}
									checked={permission[objName]?.delete}
								/>
							</div>

							<div className="permission_checkbox">
								<span>Edit</span>
								<input
									type="checkbox"
									name="edit"
									onChange={handleOnChange}
									value={objName}
									checked={permission[objName]?.edit}
								/>
							</div>
							<div className="permission_checkbox">
								<span>View</span>
								<input
									type="checkbox"
									name="view"
									onChange={handleOnChange}
									value={objName}
									checked={permission[objName]?.view}
								/>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};
export default Checkbox;
