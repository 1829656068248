import React, { useState } from "react";
// import ProductTable from "../common/tableExpandable.jsx";

import CategoryTable from "../categories/components/CategoryTable";
import CollectionTable from "./CollectionTable";

export default function Collection() {
  const [open, setOpen] = useState(false);
  //const [isEdit, setIsEdit] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  // const token = JSON.parse(localStorage.getItem("token"));

  // const getIsEdit = () => {
  //   setIsEdit(true);
  // };

  return (
    <>
      <div className="products-wrapper">
        <div className="container-fluide">
          <div className="page-title">
            <div className="row">
              <div className="col-6">
                <h3>Collection</h3>
              </div>

              {/* <div className="col-6">
                <nav className="" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/products/products/:layout">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                      </a>
                    </li>
                    <li className="active breadcrumb-item" aria-current="page">
                      Categories
                    </li>
                  </ol>
                </nav>
              </div> */}
              {/* <div
								className="col-12 mt-4"
								style={{ justifySelf: "flexgit -end" }}
							>
								<button
									className={"btn btn-primary"}
									onClick={() => setOpen(true)}
								>
									+ Add Category
								</button>
							</div> */}
            </div>
          </div>
        </div>
        <div className="products-content">
          <CollectionTable refreshTable={refreshTable} />
        </div>
      </div>
    </>
  );
}
