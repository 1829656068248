import React from "react";
import Box from "@material-ui/core/Box";
import "react-date-range/dist/styles.css"; // dataranger main style file
import "react-date-range/dist/theme/default.css"; // dataranger theme css file
import "./DateRange.css"; // dataranger theme css file
import { makeStyles } from "@material-ui/core/styles";
import { DateRangePicker } from "react-date-range";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import DateRangeIcon from "@material-ui/icons/DateRange";

const useRowStyles = makeStyles({
  buttonRoot: {
    color: "rgba(0, 0, 0, 0.87)",
    padding: "6px 16px",
    fontSize: "0.875rem",
    minWidth: "64px",
    boxSizing: "border-box",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: "4px",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    height: "100%",
  },
  cursorDefault: {
    cursor: "default",
  },
  height100p: {
    height: "100%",
  },
});

export default function PopoverPopupState({
  handleSelect,
  selectionRange,
  setSelectionRange,
}) {
  const classes = useRowStyles();
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <Button
            variant="outlined"
            size="medium"
            classes={{ root: classes.buttonRoot }}
            endIcon={<DateRangeIcon />}
            {...bindTrigger(popupState)}
          >
            Date Filter
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box p={2}>
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleSelect}
              />
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
