import React from "react";
import { useEffect, useState } from "react";
import { objectDeepClone } from "../../../utility/helper";
import Select from "react-select";

const OptionalCreator = (props) => {
  const {
    creatorList,
    state,
    handleCreator,
    setSearch,
    isEdit,
    setMultipleCoCreator = () => console.log("1"),
  } = props;

  console.log("creatorList", creatorList);
  console.log("state", state);
  const emptyObj = {
    id: "",
    royaltyCommissionPercentage: "",
    royaltyCommissionCapAmount: "",
    primarySalePercentage: "",
  };
  const [creators, setCreators] = useState([]);
  const [showInfo, setShowInfo] = useState(null);

  useEffect(() => {
    const filteredArray = creators.map(
      ({
        primarySalePercentage,
        royaltyCommissionCapAmount,
        royaltyCommissionPercentage,
        id,
      }) => ({
        primarySalePercentage,
        royaltyCommissionCapAmount,
        royaltyCommissionPercentage,
        id,
      })
    );
    setMultipleCoCreator(filteredArray);
  }, [creators]);

  const handleInput = (e, index) => {
    const { name, value } = e?.target;
    console.log("value", value, value?.length);
    if (
      name === "royaltyCommissionPercentage" ||
      name === "primarySalePercentage"
    ) {
      if (value?.length >= 7) return;
      if (value?.length === 4 && !value.includes(".")) return;
    }
    if (name === "royaltyCommissionCapAmount") {
      if (value?.length >= 9) return;
      if (value?.length === 6 && !value.includes(".")) return;
    }
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    let num = value.match(regex)[0];
    // if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
    let arr = objectDeepClone(creators);
    if (num) {
      arr[index][name] = Number(value);
      setCreators(arr);
    } else {
      arr[index][name] = "";
      setCreators(arr);
    }
  };

  const handleSelectChange = (e, i) => {
    console.log("handleSelectChange", e, '-----',i);
    let arr = objectDeepClone(creators);
    arr[i] = {...arr[i], ...e}
    setCreators(arr);
  };

  const addCreator = () => {
    if (creators?.length === 4) {
      setInfo("Maximum limit is 4");
      // cannot add more than 5 elemnsts
    } else {
      let arr = objectDeepClone(creators);
      arr.push(emptyObj);
      setCreators(arr);
    }
  };

  const setInfo = (msg) => {
    setShowInfo(msg);
    setTimeout(() => {
      setShowInfo(null);
    }, 3000);
  };

  const removeAttribute = (i) => {
    if (creators?.length === 0) {
      setInfo("No Optional creator left to delete");
    } else {
      let arr = creators.filter((el, j) => i !== j);
      console.log(arr);
      setCreators(arr);
    }
  };

  useEffect(() => {
    // if (state?.UserAssetCommission?.length && creatorList?.length) {
    //   let arr = [];
    //   state.UserAssetCommission.forEach((el) => {
    //     if (el?.creatorType === "coCreator") {
    //       let user = creatorList.filter((x) => x.id === el?.userId);
    //       console.log("userff", user[0]);
    //       let obj = {
    //         id: el?.userId,
    //         royaltyCommissionPercentage: el?.percentage,
    //         royaltyCommissionCapAmount: el?.capAmount,
    //         primarySalePercentage: el?.earnings,
    //         ...user[0],
    //       };
    //       arr.push(obj);
    //     }
    //   });
    //   //setCreators(arr);
    // }

    if (state?.UserAssetCommission?.length && creatorList?.length) {
      const inputArray = state.UserAssetCommission;

      const transformedArray = [];

      const filteredInputArray = inputArray.filter(
        (item) => item.creatorType === "coCreator"
      );

      const userIds = [
        ...new Set(filteredInputArray.map((item) => item.userId)),
      ];

      userIds.forEach((userId) => {
        let user = creatorList.filter((x) => x.id === userId);

        const userRoyalty = inputArray.find(
          (item) =>
            item.userId === userId &&
            item.creatorType === "coCreator" &&
            item.type === "royalty"
        );

        const userPrimary = inputArray.find(
          (item) =>
            item.userId === userId &&
            item.creatorType === "coCreator" &&
            item.type === "primary"
        );

        const userCapAmount = inputArray.find(
          (item) =>
            item.userId === userId &&
            item.creatorType === "coCreator" &&
            item.type === "royalty"
        );

        const transformedItem = {
          id: userId,
          royaltyCommissionPercentage: userRoyalty ? userRoyalty.percentage : 0,
          primarySalePercentage: userPrimary ? userPrimary.percentage : 0,
          royaltyCommissionCapAmount: userCapAmount
            ? userCapAmount.capAmount
            : 0,
          ...user[0],
        };

        transformedArray.push(transformedItem);
      });

      let data = transformedArray.reverse();

      setCreators(data);
    }
  }, [state?.UserAssetCommission, creatorList]);

  useEffect(() => {
    handleCreator(creators);
  }, [creators]);

  const inCocreator = (id) => {
    let flag = 0;
    for (let i = 0; i < creators.length; i++) {
      if (id == creators[i]?.id) {
        flag = 1;
        return true;
      }
    }
    if (!flag) {
      return false;
    }
  };
  const coCreatorChecking = () => {
    const tempList = creatorList.filter((item, index) => {
      if (
        item.id != state.creator.id &&
        item.id != state.artist?.id &&
        item.status === "verified" &&
        !inCocreator(item?.id)
      ) {
        return item;
      }
    });

    return tempList;
  };
  return (
    <div>
      {isEdit ? null : (
        <div>
          <a
            className={
              creators?.length < 4
                ? "btn btn-primary btn-sm mb-3"
                : "btn btn-primary btn-sm mb-3 disabledbtn"
            }
            onClick={() => {
              if (creators?.length < 4) {
                addCreator();
              }
            }}
          >
            + Add CoCreator
          </a>
        </div>
      )}
      <div>
        {creators?.length
          ? creators.map((el, i) => {
              return (
                <div className="row d-flex-aic  separator">
                  <div className="form-group mb-3 col-xs-12 col-sm-6">
                    <label htmlFor="stock">CoCreator Name</label>
                    <span className="colorRed">*</span>
                    {console.log("creators", creators)}
                    <Select
                      classNamePrefix="creator-select-input"
                      value={creators[i] || null}
                      options={coCreatorChecking()}
                      // options={creatorList.filter(val => val?.id !== state.creator?.id && coCreatorChecking(val?.id))}
                      onChange={(e) => handleSelectChange(e, i)}
                      isDisabled={isEdit ? true : false}
                      // onInputChange={(e) => setSearch(e)}
                    />
                  </div>
                  <div className="form-group mb-3 col-xs-12 col-sm-6">
                    <label className="ws-no-wrap" htmlFor="stock">
                      Commission Percentage
                    </label>
                    <span className="colorRed">*</span>
                    <div className="displayFlexBaseline">
                      <div className="percent-symbol">%</div>
                      <span className="currencyInput w-100 removePadding">
                        <input
                          type="number"
                          name="royaltyCommissionPercentage"
                          min="0"
                          className="form-control validate percentageText"
                          maxLength="3"
                          value={el?.royaltyCommissionPercentage}
                          onWheel={(event) => event.currentTarget.blur()}
                          onChange={(e) => handleInput(e, i)}
                          required
                          disabled={isEdit ? true : false}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="form-group mb-3 col-xs-12 col-sm-6">
                    <label className="ws-no-wrap" htmlFor="stock">
                      Primary Earnings
                    </label>
                    <span className="colorRed">*</span>
                    <div className="displayFlexBaseline">
                      <div className="percent-symbol">%</div>
                      <span className="currencyInput w-100 removePadding">
                        <input
                          type="number"
                          name="primarySalePercentage"
                          min="0"
                          className="form-control validate percentageText"
                          maxLength="12"
                          disabled={isEdit ? true : false}
                          onWheel={(event) => event.currentTarget.blur()}
                          value={el?.primarySalePercentage}
                          onChange={(e) => handleInput(e, i)}
                          required
                        />
                      </span>
                    </div>
                  </div>
                  <div className={`form-group mb-3 col-xs-12 col-sm-5`}>
                    <label className="ws-no-wrap" htmlFor="stock">
                      Cap Amount (USD)
                      {/* <span className="colorRed">* </span> */}
                    </label>
                    <input
                      type="number"
                      name="royaltyCommissionCapAmount"
                      min="0"
                      className="form-control validate"
                      maxLength="12"
                      value={el?.royaltyCommissionCapAmount}
                      onWheel={(event) => event.currentTarget.blur()}
                      onChange={(e) => handleInput(e, i)}
                      required
                      disabled={isEdit ? true : false}
                    />
                  </div>
                  {/* <input type="text" name={'trait_type'} onChange={(e) => handleInput(e, i)} value={el?.trait_type}  />
                            <input type="text" name={'value'} onChange={(e) => handleInput(e, i)} value={el?.value} /> */}
                  {isEdit ? null : (
                    <a
                      className="cursor-pointer"
                      onClick={() => removeAttribute(i)}
                    >
                      X
                    </a>
                  )}
                </div>
              );
            })
          : null}
      </div>
      {showInfo ? <p>{showInfo}</p> : null}
    </div>
  );
};

export default OptionalCreator;
