/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
//import { makeStyles } from "@material-ui/core/styles";
import { axiosInstance } from "../../../../utility/api";
import { handleAPIError } from "../../../../utility/helper";

// const useStyles = makeStyles({
//   radioGroupRoot: {
//     display: "flex",
//     flexDirection: "row",
//     flexWrap: "nowrap",
//   },

//   formControlRoot: {
//     display: "flex",
//     flexDirection: "row",
//     flexWrap: "nowrap",
//   },
// });

export default function AddSubCategory(props) {
	const {
		//style,
		state,
		// onChangeDescription,
		handleChange,
		// handleImageUpload,
		// loadfile,
		handleSubmitForm,
		isEdit,
	} = props;

	//const classes = useStyles();
	const [categoryList, setCategoryList] = useState(null);
	const config = {
		headers: {
			Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
		},
	};

	useEffect(() => {
		getCategoryList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getCategoryList = async () => {
		await axiosInstance
			.get("/v1/admin/category", config)
			.then((res) => {
				setCategoryList(res?.data?.result?.assets);
			})
			.catch((err) => {
				let respErr = handleAPIError(err)
				Swal.fire("Oops!", respErr, "error");
			});
	};

	return (
		<>
			<div className="container tm-mt-big tm-mb-big">
				<div className="row">
					<div className="col-xl-9 col-lg-10 col-md-12 col-sm-12 mx-auto">
						<div className="tm-bg-primary-dark tm-block tm-block-h-auto">
							<div className="row">
								<div className="col-12">
									<h2 className="tm-block-title d-inline-block">
										{isEdit ? "Edit" : "Add"} Sub Category
									</h2>
								</div>
							</div>
							<div className="row tm-edit-product-row">
								<div className="col-xl-12 col-lg-6 col-md-12">
									<form action="" className="tm-edit-product-form">
										<div className="form-group mb-3">
											<label htmlFor="name">Sub Category Name</label>
											<span className="colorRed">*</span>
											<input
												type="text"
												name="name"
												className="form-control validate"
												defaultValue={state.name}
												onChange={(e) => handleChange(e)}
												required
											/>
										</div>
										<div className="form-group mb-3">
											<label htmlFor="category">Category</label>
											<span className="colorRed">*</span>
											<select
												className="custom-select tm-select-accounts"
												name="categoryId"
												id="categoryId"
												value={`${state.categoryId}`}
												onChange={(e) => handleChange(e)}
												required
											>
												<option value="" selected>
													Select category
												</option>
												{categoryList?.map((item, index) => (
													<option key={index} value={item.id}>
														{item.name}
													</option>
												))}
											</select>
										</div>
									</form>
								</div>

								{state.error.status == true ? (
									<div className="errorLabel col-6 col-md-12">
										{state?.error?.message}
									</div>
								) : null}
								<div className="col-12">
									<button
										type="submit"
										className="btn btn-primary btn-block text-uppercase"
										onClick={handleSubmitForm}
										disabled={state.name || state.categoryId ? false : true}
									>
										Save subcategory now.
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
