/* eslint-disable eqeqeq */

import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";

import "./CategoryTable.css";
import { axiosInstance } from "../../../utility/api";
import UiDrawer from "../components/UiDrawer";
import NoRecordsFound from "../../common/Error/NoRecordsFound";
import { useDebounce } from "../../../hooks/useDebounce";
import Loader from "../../common/Loader/Loader";
import editIcon from "../../../assets/images/icons/edit.svg";
import deleteIcon from "../../../assets/images/icons/delete.svg";

const useRowStyles = makeStyles({
	root: {
		marginTop: "0px",
		border: "none",
		width: "100%",
	},
	header: {
		backgroundColor: "#F5F5FC",
	},
	rowDatas: {
		"& td,th": {
			border: "none",
			height: "90px",
		},
	},
	border__right: {
		borderRight: "2px solid white",
		height: "70%",
	},
	cursorDefault: {
		cursor: "default",
	},
});

//Each row of table
function Row(props) {
	const {
		//row,
		data,
		tableColumnWidth,
		// setShowLoader,
		// showLoader,
		setOpenEdit,
		//selectedRow,
		setSelectedRow,
	} = props;
	const [open, setOpen] = React.useState(false);
	//const [deleteCategoryID, setDeleteCategoryID] = useState(null);
	// const token = JSON.parse(localStorage.getItem("token"));
	const classes = useRowStyles();

	const deleteCateogry = (id) => {
		console.log("deleted 2", id);
		if (id) {
			confirmAlert({
				title: "Delete category?",
				message: "Are you sure to delete.",
				buttons: [
					{
						label: "Yes",
						onClick: () => handleDelete(id),
					},
					{
						label: "No",
						onClick: () => console.log("Clicked No"),
					},
				],
			});
		}
	};

	const handleDelete = (id) => {
		const config = {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
			},
		};
		axiosInstance
			.delete(`/v1/admin/category/${id}`, config)
			.then((res) => {
				Swal.fire("Success!", res?.data?.message, "success");
			})
			.then(() => {
				window.location.reload(true);
			})
			.catch((err) => {
				console.log("rrr eror", err?.response.data?.message);
				Swal.fire("Error!", err?.response?.data?.message, "error");
			});
	};

	return (
		<React.Fragment>
			<TableRow
				onClick={() => {
					setOpen(!open);
				}}
				className={classes.rowDatas}
				style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
			>
				<TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
					<div className="columnStyles">{data?.name ? data?.name : "-"}</div>
				</TableCell>
				{/* <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
          <div className="columnStyles">{data?.slug ? data?.slug : "-"}</div>
        </TableCell> */}
				<TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
					<div className="columnStyles">
						{data?.assetCount ? data?.assetCount : "-"}
					</div>
				</TableCell>
				<TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
					<Tooltip title="Edit category">
						<img
							src={editIcon}
							className="cursorPointer"
							alt="Edit"
							onClick={() => {
								setSelectedRow(data);
								setOpenEdit(true);
							}}
						/>
					</Tooltip>
					<Tooltip title="Delete category">
						<img
							src={deleteIcon}
							onClick={async () => {
								//await setDeleteCategoryID(data?.id);
								deleteCateogry(data?.id);
							}}
							className="marginLeft20 cursorPointer"
							alt="Delete"
						/>
					</Tooltip>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

const RenderHeader = ({ columns, onSorting }) => {
	const [sortingField, setSortingField] = useState("");
	const [sortingOrder, setSortingOrder] = useState("asc");

	const onSortingChange = (field) => {
		const order =
			field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
		setSortingField(field);
		setSortingOrder(order);
		onSorting(field, order);
	};

	const classes = useRowStyles();
	return (
		<TableHead className={classes.header}>
			<TableRow>
				{columns?.map(({ name, field, sortable, image }) => (
					<TableCell
						align={columns.align}
						key={name}
						className={classes.cursorDefault}
					>
						<div className="dividerFlex">
							<div
								style={{
									display: "flex",
									justifyContent: "left",
									alignItems: "center",
									fontWeight: "600",
									fontSize: "12px",
									cursor: sortable ? "pointer" : "default",
								}}
								onClick={() => (sortable ? onSortingChange(field) : null)}
							>
								{name}
								{/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
							</div>
						</div>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default function CategoryTable({
	data,
	showLoader,
	setShowLoader,
	refreshTable,
}) {
	const classes = useRowStyles();
	// const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
	const [totalCount, setTotalCount] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [categoryList, setCategoryList] = useState(null);
	const [openEdit, setOpenEdit] = useState(false);
	const [pageNo, setPageNo] = useState(1);
	const [selectedRow, setSelectedRow] = useState(null);
	const [refreshCategoryTable, setRefreshCategoryTable] = useState(false);
	const [searchText, setSearchText] = useState("");
	// const [filterSelected, setFilterSelected] = useState([]);
	// const [statusFilterSelected, setStatusFilterSelected] = useState(false);
	// const [isDateFilterEnabled, setIsDateFilterEnabled] = useState(null);
	const [searchSuggestions, setSearchSuggestions] = useState([]);

	//const token = JSON.parse(localStorage.getItem("token"));
	// const headers = {
	// 	headers: {
	// 		Authorization: `Bearer ${token}`,
	// 	},
	// };

	// Date conversation for DateRange filer
	//let today = new Date();
	// let year = today.getFullYear();
	// let month = today.getMonth();
	// let day = today.getDate();
	//let date1MonthBefore = new Date(year, month - 1, day);
	//let date1DayBefore = new Date(year, month, day - 1);

	// const [selectionRange, setSelectionRange] = useState({
	// 	startDate: date1DayBefore,
	// 	endDate: new Date(),
	// 	key: "selection",
	// });

	const tableColumnWidth = [25, 25, 25, 25];
	const columns = [
		{
			name: "Name",
			field: "name",
			// image: Divider,
			numeric: false,
			sortable: false,
			align: "left",
		},
		// {
		//   name: "Label",
		//   field: "label",
		//   // image: Divider,
		//   numeric: true,
		//   sortable: false,
		//   align: "left",
		// },
		{
			name: "Asset Count",
			field: "count",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
		{
			name: "Action",
			field: "action",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
	];

	useEffect(() => {
		getCategoryList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNo, refreshTable, refreshCategoryTable]);

	useDebounce(
		() => {
			if (searchText != null && searchText != "" && searchText != undefined) {
				getCategoryList();
			}
		},
		[searchText],
		1000
	);

	const handleChangePage = (event, value) => {
		setPageNo(value);
		window.scrollTo(0, 0);
	};

	const getCategoryList = async () => {
		let urlParam = {
			params: {
				page: pageNo,
				limit: 10,
				search: searchText,
				// fromDate: isDateFilterEnabled ? selectionRange?.startDate : "",
				// toDate: isDateFilterEnabled ? selectionRange?.endDate : "",
			},
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
			},
		};

		await axiosInstance
			.get(`/v1/admin/category`, urlParam)
			.then((result) => {
				setCategoryList(result?.data?.result?.assets);
				setTotalCount(result?.data?.result?.totalItems);
				setTotalPages(result?.data?.result?.totalPages);
			})
			.catch((err) => {
				if (
					err?.response &&
					err?.response?.data?.errors &&
					err?.response?.data?.errors?.length !== 0
				) {
					Swal.fire({
						title: "Oops!",
						text: err?.response?.data?.errors.map((err) => err.msg),
						icon: "error",
					});
				} else {
					Swal.fire("Oops!", err?.response?.data?.message, "error");
				}
			});
	};

	// handle date range changes

	// const handleSelect = (ranges) => {
	// 	setSelectionRange(ranges?.selection);
	// 	setPageNo(1);
	// };

	return (
		<div className="table_div tableWidth100">
			{showLoader == true ? <Loader /> : null}
			<div className="searchBoxProduct">
				{/* <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<CloudDownloadIcon>Report</CloudDownloadIcon>}
          onClick={() => downloadReport()}
        >
          Report
        </Button> */}
				{/* <Button
          variant="outlined"
          onClick={() => {
            setSelectionRange({
              startDate: date1MonthBefore,
              endDate: new Date(),
              key: "selection",
            });
            // setStatusFilterSelected(-1);
            setSearchText("");
            setIsDateFilterEnabled(false);
            setPageNo(1);
          }}
        >
          Reset Filter
        </Button> */}
				{/* <div
          className="height55px displayFlex"
          onClick={() => setIsDateFilterEnabled(true)}
        >
          <DataRangePopOver
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
            handleSelect={handleSelect}
          />
        </div> */}
				{/* <FormControl variant="outlined" className={classes.height24}>
          <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
          <Select
            native
            value={statusFilterSelected}
            onChange={(e) => {
              setStatusFilterSelected(e.target.value);
              setPageNo(1);
            }}
            label="Filter"
            inputProps={{
              name: "Filter",
              id: "outlined-age-native-simple",
            }}
            IconComponent={FilterListIcon}
            displayEmpty={true}
          >
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                clipRule="evenodd"
              />
            </svg>
            <optgroup label="Status">
              <option value={-1}>All</option>
              <option value={"blocked"}>Blocked</option>
              <option value={"active"}>Active</option>
            </optgroup>
          </Select>
        </FormControl> */}
				<Autocomplete
					id="search-box-product"
					options={searchSuggestions}
					disableClearable={true}
					clearOnBlur={false}
					getOptionLabel={(option) => {
						return option.name || "";
					}}
					style={{ width: 300 }}
					inputValue={searchText}
					renderInput={(params) => (
						<TextField
							{...params}
							// value={searchText}
							onChange={(e) => {
								setSearchText(e.target.value);
								setPageNo(1);
							}}
							// onKeyDown={(e) => handleSearchBoxKeyDown(e)}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							label="Search Category"
							variant="outlined"
						/>
					)}
				/>
			</div>
			{categoryList?.length !== 0 ? (
				<>
					<TableContainer component={Paper}>
						<Table aria-label="collapsible table" className={classes.root}>
							<RenderHeader
								columns={columns}
								// onSorting={(field, order) => setSorting({ field, order })}
							/>
							<TableBody style={{ width: "100%" }}>
								{categoryList?.map((row, index) => (
									<Row
										aria-label="expand row"
										size="small"
										key={row?.id}
										row={row}
										setSelectedRow={(value) => setSelectedRow(value)}
										selectedRow={selectedRow}
										data={categoryList[index]}
										tableColumnWidth={tableColumnWidth}
										setOpenEdit={setOpenEdit}
										// setShowLoader={setShowLoader}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Pagination
						count={totalPages}
						page={pageNo}
						onChange={handleChangePage}
					/>
					{/* <TablePagination
            rowsPerPageOptions={[]}
            // backIconButtonProps={{ disabled: false }}
            component="div"
            count={totalCount}
            rowsPerPage={10}
            page={pageNo}
            onChangePage={handleChangePage}
            // onChange={(e) => handleChangePage(e, page)}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              <>
                <span className="paginationLabel">Showing </span>
                {`${from}-${to}`}
                <span className="paginationLabel"> out of </span>
                {`${count}`}
              </>
            )}
          /> */}
				</>
			) : (
				<div className="noResultFound flexColumnCenter">
					<NoRecordsFound message="No records found." />
				</div>
			)}
			<UiDrawer
				open={openEdit}
				onClose={() => setOpenEdit(false)}
				showLoader={showLoader}
				setShowLoader={(e) => setShowLoader(e)}
				isEdit={true}
				selectedRow={selectedRow}
				setRefreshCategoryTable={() =>
					setRefreshCategoryTable(!refreshCategoryTable)
				}
			/>
			{/* <UiDrawer
        open={open}
        onClose={() => setOpen(false)}
        showLoader={showLoader}
        setShowLoader={(e) => setShowLoader(e)}
        isEdit={isEdit}
      /> */}
		</div>
	);
}
