import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store'
import { BrowserRouter as Router } from 'react-router-dom';



const Root = (props) => {

  return (
    <Fragment>
      <Provider store={store}>
        <Router>
          <App>
          </App>
        </Router>
      </Provider>
    </Fragment>
  )
}
ReactDOM.render(<Root />,
  document.getElementById('root')
);

serviceWorker.unregister();
