import React, { useRef, useState } from "react";
import ImageUpload from "../../../../assets/images/other-images/imageUpload.svg";
import "./BulkUploadSection.css";
import { axiosInstance } from "../../../../utility/api";
import Swal from "sweetalert2";



function BulkUploadRightSection({ setCollectionImage, collectionImage }) {
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const [previewSource, setPreviewSource] = useState("");


  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  // Handle CSV file upload

  const handleFileUpload = () => {
    if (!file) {
      setError("Please select a CSV file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("csvFile", file);

    axiosInstance
      .post("/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setImageUrl(response.data.imageUrl);
      })
      .catch((error) => {
        setError("Error uploading the file. Please try again.");
      });
  };

  const previewFile = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setPreviewSource(reader.result);
      };
    }
  };

  const handleFileChange = (e) => {
    console.log("bulk.handleFileChange", e.target.files[0]);
    setError(null);
    const selectedFile = e.target.files[0];
    if (
      selectedFile &&
      ["image/jpeg", "image/png", "image/gif"].includes(selectedFile.type)
    ) {
      // setCollectionImage(selectedFile);
      uploadFile(e);
      previewFile(selectedFile);
      // postImage(selectedFile);
    } else {
      Swal.fire("Error!", "Invalid file format. Please upload a valid image file.", "error");
    }
  };

  // Upload Collection Banner Image
  const uploadFile = (e) => {
    // setShowLoader(true);
    // const config = getConfig();
    // const pic = document.getElementById("profile_pic");
    const selectedFile = e.target.files[0];
    let formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("position", 2);
    axiosInstance
      .post("/v1/admin/upload/bucket", formData, config)
      .then((res) => {
        // setShowLoader(false);
        // previewFile(selectedFile);
        setCollectionImage(res?.data?.result?.previewUrl);
        Swal.fire("Great!", "Banner Image updated sucessfully.", "success");
      })
      .catch((err) => {
        // setShowLoader(false);
        Swal.fire("Oops!", err?.response?.data?.message, "error");
      });
  };

  const postImage = (file) => {
    let form_data = new FormData();
    form_data.append("csv", file);
    try {
      axiosInstance.post("/v1/upload-csv", form_data, config).then((res) => {
        // Notifiy center state fileupload type.
        console.log("bulk.upload", res);
      });
    } catch (error) {
      console.log("error while uploading image");
    }
  };

  const handleCustomButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div
      className={`tm-product-img-dummy mx-auto addProductFlexCenter height41p imgGreyBg cursorPointer width42p`}
      onClick={handleCustomButtonClick}
    >
      <img
        alt="preview"
        src={previewSource ? previewSource : ImageUpload}
        className="previewImg"
        nb8h
      />

      <input
        type="file"
        id="bannerImage"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
}

export default BulkUploadRightSection;
