/* eslint-disable eqeqeq */
import React from "react";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import ReactQuill from "react-quill";
//import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles({
//   radioGroupRoot: {
//     display: "flex",
//     flexDirection: "row",
//     flexWrap: "nowrap",
//   },

//   formControlRoot: {
//     display: "flex",
//     flexDirection: "row",
//     flexWrap: "nowrap",
//   },
//   // label: {
//   //   textTransform: "capitalize",
//   // },
// });

export default function AddNews(props) {
	const {
		//style,
		state,
		onChangeDescription,
		handleChange,
		handleBannerImageUpload,
		// handleImageUpload,
		// loadfile,
		//newsList,
		handleSubmitForm,
		isEdit,
	} = props;

	// const [newsBannerImage, setNewsBannerImage] = useState({
	//   isFilePicked: false,
	//   file: "",
	// });
	//const classes = useStyles();
	const inputBannerImage = React.useRef(null);
	const reactQuillRef = React.useRef();
	const quilEditorText = reactQuillRef?.current?.getEditor();
	const descriptionLength = quilEditorText?.getLength() - 1;

	React.useEffect(() => {
		console.log("quilEditorText?.getLength()", quilEditorText?.getLength());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [descriptionLength]);

	const handleImageUploadInput = () => {
		inputBannerImage.current.click();
	};

	return (
		<>
			<div
				className="container tm-mt-big tm-mb-big"
				style={{ minWidth: "80em" }}
			>
				<div className="row">
					<div className="col-xl-9 col-lg-10 col-md-12 col-sm-12 mx-auto">
						<div className="tm-bg-primary-dark tm-block tm-block-h-auto">
							<div className="row">
								<div className="col-12">
									<h2 className="tm-block-title d-inline-block">
										{isEdit ? "Edit" : "Add"} News
									</h2>
								</div>
							</div>
							<div className="row tm-edit-product-row">
								<div className="col-xl-12 col-lg-6 col-md-12">
									<form action="" className="tm-edit-product-form">
										<div className="form-group mb-3">
											<label htmlFor="name">Title</label>
											<span className="colorRed">*</span>
											<input
												type="text"
												name="title"
												className="form-control validate"
												value={state.title}
												onChange={(e) => {
													// console.log("category", e.target);
													let event = { ...e };
													if (e.target.value.length > 255) {
														event.target.value = event.target.value.slice(
															0,
															255
														);
														handleChange(event);
													} else {
														handleChange(event);
													}
												}}
												required
											/>
											<div className="charactersRemaining">
												<span>
													({state?.title ? 255 - state?.title?.length : "255"}{" "}
													characters remaining)
												</span>
											</div>
										</div>

										<div className="form-group mb-3">
											<label htmlFor="description">Description</label>
											<ReactQuill
												// onKeyDown={checkCharacterCount}
												ref={reactQuillRef}
												value={state?.description}
												onChange={(e) => {
													const quilEditor =
														reactQuillRef?.current?.getEditor();
													const descriptionLength = quilEditor?.getLength();
													if (reactQuillRef?.current) {
														if (descriptionLength < 5000) {
															onChangeDescription(e);
														} else {
															quilEditor.deleteText(
																5000,
																quilEditor.getLength()
															);
															console.log(
																"description",
																reactQuillRef.current.getEditorContents()
															);
															onChangeDescription(
																reactQuillRef.current.getEditorContents()
															);
														}
													} else {
														onChangeDescription(e);
													}
												}}
											/>
											{(descriptionLength || isEdit) && (
												<div className="charactersRemaining">
													<span>
														(
														{state?.description
															? 5000 -
															  (descriptionLength ||
																	state?.description?.length)
															: "5000"}{" "}
														characters remaining)
													</span>
												</div>
											)}
										</div>
										{/* <div className="form-group mb-3">
                      <label htmlFor="name">PNG UPLOAD</label>
                      <span className="colorRed">*</span>
                      <input
                        type="file"
                        // name="url"
                        className="form-control validate"
                        // defaultValue={state.url}
                        // onChange={(e) => {
                        //   // console.log("url", e.target);
                        //   handleChange(e);
                        // }}
                        required
                      />
                    </div> */}
										<div className="form-group mb-3">
											<label htmlFor="name">Banner Image</label>
											<span className="colorRed">*</span>
											<div
												className="form-control"
												style={{ cursor: "pointer" }}
												onClick={handleImageUploadInput}
											>
												{!state?.bannerUrl ? (
													<span>Choose file.</span>
												) : (
													<span>{state?.bannerUrl}</span>
												)}
												<input
													type="file"
													id="bannerUpload"
													ref={inputBannerImage}
													className="form-control"
													style={{ paddingBottom: "2.3em", display: "none" }}
													name="bannerImage"
													onChange={handleBannerImageUpload}
												/>
											</div>

											{/* <input
                        type="text"
                        name="bannerImage"
                        className="form-control validate"
                        value={state.bannerImage}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        required
                      /> */}
										</div>
										{/* <div className="form-group mb-3">
                      <label htmlFor="name">URL</label>
                      <span className="colorRed">*</span>
                      <input
                        type="text"
                        name="url"
                        className="form-control validate"
                        value={state.url}
                        onChange={(e) => {
                          // console.log("url", e.target);
                          handleChange(e);
                        }}
                        required
                      />
                    </div> */}
										<div className="form-group mb-3">
											<label htmlFor="name">Published Date</label>
											<span className="colorRed">*</span>
											<input
												type="date"
												name="publishedDate"
												className="form-control validate"
												defaultValue={moment(state.publishedDate).format(
													"yyyy-MM-DD"
												)}
												max={moment().format("yyyy-MM-DD")}
												onChange={(e) => {
													// console.log("publishDate", e.target);
													handleChange(e);
												}}
												required
											/>
										</div>
									</form>
								</div>

								{state.error.status == true ? (
									<div className="errorLabel col-6 col-md-12">
										{state?.error?.message}
									</div>
								) : null}
								<div className="col-12">
									<button
										type="submit"
										className="btn btn-primary btn-block text-uppercase"
										onClick={handleSubmitForm}
										disabled={
											state.title ||
											state.description ||
											state.url ||
											state.publishedDate
												? false
												: true
										}
									>
										Save news now.
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
