import React, { useState } from "react";
import { axiosInstance } from "../../utility/api";
import Swal from "sweetalert2";
import EyeIcon from "../../assets/images/icons/eye.svg";
import EyeOffIcon from "../../assets/images/icons/eye-off.svg";
import "./changePassword.css";

function ChangePassword() {
	const [fields, setFields] = useState({});
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
	const config = {
		headers: {
			Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
		},
	};

	const handleChange = (e) => {
		let name = e?.target?.name;
		let value = e?.target?.value;
		setFields({ ...fields, [name]: value });
	};

	const handleSubmit = async (e) => {
		const userId = localStorage.getItem("userId");

		if (fields.newPassword !== fields.confirmNewPassword) {
			Swal.fire(
				"Error",
				"New password and confirm password should match.",
				"error"
			);
			return false;
		} else if (userId) {
			let body = {
				oldPassword: fields.oldPassword,
				newPassword: fields.newPassword,
			};

			await axiosInstance
				.put(`/v1/admin/change/password/${userId}`, body, config)
				.then((res) => {
					console.log("successful");
					Swal.fire("Success !", res?.data?.message, "success").then(() => {
						localStorage.removeItem("token");
						window.location.href = `${process.env.PUBLIC_URL}/sign-in`;
					});
				})
				.catch((err) =>
					Swal.fire("Oops", err?.response?.data?.message, "warning")
				);
		}
	};

	return (
		<div className="mainDiv">
			<div className="cardStyle">
				<h2 className="formTitle">Change Password.</h2>

				{/* <div className="inputDiv">
            <label className="inputLabel" htmlFor="email">
              Email
            </label>
            <input type="em
            ail" id="email" name="email" required />
          </div> */}
				<div className="inputDiv">
					<label className="inputLabel required" htmlFor="password">
						Old Password
					</label>
					<div className="displayFlex positionRelative inputWrapper">
						<input
							type="password"
							id="oldPassword"
							name="oldPassword"
							className="flex00100"
							onChange={(e) => handleChange(e)}
							required
						/>
						{showOldPassword ? (
							<img
								src={EyeIcon}
								alt="eye"
								className="passwordEyeIcon"
								onClick={() => {
									setShowOldPassword(!showOldPassword);
									document.getElementById("oldPassword").attributes[
										"type"
									].value = "password";
								}}
							/>
						) : (
							<img
								src={EyeOffIcon}
								alt="eye"
								className="passwordEyeIcon"
								onClick={() => {
									setShowOldPassword(!showOldPassword);
									document.getElementById("oldPassword").attributes[
										"type"
									].value = "text";
								}}
							/>
						)}
					</div>
				</div>
				<div className="inputDiv">
					<label className="inputLabel required" htmlFor="password">
						New Password
					</label>
					<div className="displayFlex positionRelative inputWrapper">
						<input
							type="password"
							id="newPassword"
							name="newPassword"
							className="flex00100"
							onChange={(e) => handleChange(e)}
							required
						/>
						{showNewPassword ? (
							<img
								src={EyeIcon}
								alt="eye"
								className="passwordEyeIcon"
								onClick={() => {
									setShowNewPassword(!showNewPassword);
									document.getElementById("newPassword").attributes[
										"type"
									].value = "password";
								}}
							/>
						) : (
							<img
								src={EyeOffIcon}
								alt="eye"
								className="passwordEyeIcon"
								onClick={() => {
									setShowNewPassword(!showNewPassword);
									document.getElementById("newPassword").attributes[
										"type"
									].value = "text";
								}}
							/>
						)}
					</div>
				</div>
				<div className="inputDiv">
					<label className="inputLabel required" htmlFor="confirmPassword">
						Confirm Password
					</label>
					<div className="displayFlex positionRelative inputWrapper">
						<input
							type="password"
							id="confirmNewPassword"
							name="confirmNewPassword"
							className="flex00100"
							onChange={(e) => handleChange(e)}
						/>
						{showConfirmNewPassword ? (
							<img
								src={EyeIcon}
								alt="eye"
								className="passwordEyeIcon"
								onClick={() => {
									setShowConfirmNewPassword(!showConfirmNewPassword);
									document.getElementById("confirmNewPassword").attributes[
										"type"
									].value = "password";
								}}
							/>
						) : (
							<img
								src={EyeOffIcon}
								alt="eye"
								className="passwordEyeIcon"
								onClick={() => {
									setShowConfirmNewPassword(!showConfirmNewPassword);
									document.getElementById("confirmNewPassword").attributes[
										"type"
									].value = "text";
								}}
							/>
						)}
					</div>
				</div>

				<div className="buttonWrapper">
					<button
						type="submit"
						id="submitButton"
						onClick={() => handleSubmit()}
						className="submitButton pure-button pure-button-primary"
					>
						<span>Submit</span>
						<span id="loader"></span>
					</button>
				</div>
			</div>
		</div>
	);
}

export default ChangePassword;
