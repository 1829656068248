/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import Swal from "sweetalert2";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import "./userTable.css";
import { axiosInstance, baseURL } from "../../utility/api";
import Loader from "../../components/common/Loader/Loader";
import NoRecordsFound from "../common/Error/NoRecordsFound";
import TransactionDetailsModal from "./UserLastTransaction";
import DataRangePopOver from "../common/DateRange/DateRangePopOver";
import Edit from "../../assets/images/icons/edit.svg";
import UserActivate from "../../assets/images/UserActivate.svg";
import UserBlock from "../../assets/images/UserBlock.svg";
import optionsDots from "../../assets/images/optionsDots.svg";
import { formatDateTime } from "../common/date";
import UiDrawer from "./UiDrawerEditUser";
import { useDebounce } from "../../hooks/useDebounce";
import { handleAPIError } from "../../utility/helper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import UploadModal from "./UploadModal";
import ReactPlayer from "react-player";

const useRowStyles = makeStyles((theme) => ({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
  height24: {
    height: "24px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

//Each row of table
function Row(props) {
  const {
    data,
    tableColumnWidth,
    selectedRowIndex,
    setselectedRowIndex,
    index,
    setOpenEdit,
    //selectedRow,
    setSelectedRow,
    airdropStatus,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [openPreview, setOpenPreview] = useState({ open: false, id: data?.id });
  const history = useHistory();
  const airdropData = history.location.state?.data;
  const id = airdropData?.id;
  // const token = JSON.parse(localStorage.getItem("token"));
  const classes = useRowStyles();
  // const style = useStyles();
  const [openModal, setOpenModal] = React.useState(false);

  const handleChangeForDots = (index) => {
    setselectedRowIndex(index);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const blockUser = (id) => {
    console.log("deleted 2", id);
    if (id) {
      confirmAlert({
        title: "Block User?",
        message: "Are you sure to block.",
        buttons: [
          {
            label: "Yes",
            onClick: () => handleBlock(id),
          },
          {
            label: "No",
            onClick: () => console.log("Clicked No"),
          },
        ],
      });
    }
  };
  const activateUser = async (id) => {
    console.log("Activate", id);
    if (id) {
      await handleActivate(id);
    }
  };

  const handleBlock = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };

    const payload = {
      isBlock: true,
    };
    axiosInstance
      .put(`/v1/admin/user/block/${id}`, payload, config)
      .then((res) => {
        Swal.fire("Success!", res?.data?.message, "success");
      })
      .then(() => {
        window.location.reload(true);
      })
      .catch((err) => {
        console.log("rrr eror", err?.response.data?.message);
        Swal.fire("Error!", err?.response?.data?.message, "error");
      });
  };

  const handleActivate = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };

    const payload = {
      isBlock: false,
    };

    axiosInstance
      .put(`/v1/admin/user/block/${id}`, payload, config)
      .then((res) => {
        Swal.fire("Success!", res?.data?.message, "success");
      })
      .then(() => {
        window.location.reload(true);
      })
      .catch((err) => {
        console.log("rrr eror", err?.response.data?.message);
        Swal.fire("Error!", err?.response?.data?.message, "error");
      });
  };

  const HandleOutSideClickClose = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setselectedRowIndex("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const BlockDisableContainerRef = React.useRef(null);
  HandleOutSideClickClose(BlockDisableContainerRef);

  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.rowDatas}
        style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          <div className="columnStyles">
            {data?.profilePicUrl ? (
              <img
                src={data?.profilePicUrl ? data?.profilePicUrl : ""}
                className="pharmaIconAvatar"
                height="50px"
                alt="dp"
              />
            ) : (
              "-"
            )}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
          <div className="columnStyles">
            {data?.userName ? data?.userName : "-"}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div className="columnStyles">{`${
            data?.firstName ? data?.firstName : "-"
          } ${data?.lastName ? data?.lastName : " "}`}</div>
        </TableCell>
        {!id && (
          <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
            <div className="columnStyles">
              {formatDateTime(data?.createdAt)}
            </div>
          </TableCell>
        )}
        {id && (
          <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
            <div className="columnStyles">
              {formatDateTime(airdropStatus?.createdAt)}
            </div>
          </TableCell>
        )}
        <TableCell style={{ width: `fit-content` }}>
          <div className="columnStyles" style={{ whiteSpace: "nowrap" }}>
            {data?.email}
          </div>
        </TableCell>

        {/* <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles">{data?.role && data?.role}</div>
        </TableCell> */}
        {!id && (
          <TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
            <div
              className={`columnStyles ${
                data?.isBlock == true ? "userBlockStyle" : "userActiveStyle"
              }`}
            >
              {data?.isBlock == true ? "Block" : "Active"}
            </div>
          </TableCell>
        )}
        {id && (
          <TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
            <div>
              {airdropStatus.status == "active"
                ? "Pending"
                : airdropStatus.status == "inactive"
                ? "Not selected"
                : airdropStatus.status == "initialized"
                ? "In progress"
                : airdropStatus.status}
            </div>
          </TableCell>
        )}

        {/* <TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
          <div className="columnStyles">{data?.asset && data?.asset?.name}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
          <div className="columnStyles">{data?.asset && data?.asset?.name}</div>
        </TableCell> */}
        {!id && (
          <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
            <div className="columnStyles">
              {data?.publicAddress && data?.publicAddress}
            </div>
          </TableCell>
        )}
        {!id && (
          <TableCell style={{ width: `${tableColumnWidth[7]}%` }}>
            <Button
              variant="outlined"
              color="primary"
              href="#outlined-buttons"
              onClick={handleOpen}
            >
              View
            </Button>
            {/* <button type="button" onClick={handleOpen}>
            react-transition-group
          </button> */}
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openModal}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openModal}>
                <div className={classes.paper}>
                  <TransactionDetailsModal
                    userID={data?.id}
                    closeModal={(val) => setOpenModal(false)}
                  />
                </div>
              </Fade>
            </Modal>
            {/* <OrderDetailsModal orderId={data?.id} /> */}
          </TableCell>
        )}
        {!id && (
          <TableCell
            align="left"
            style={{ width: `${tableColumnWidth[8]}%` }}
            onClick={(event) => event.preventDefault()}
          >
            <div
              className={"voiletTickImage alignItemStart hcpLastColumn"}
              style={{ position: "relative" }}
            >
              {selectedRowIndex === index && (
                <div
                  className="threeDotContainer threeDotContainerAlignment"
                  ref={BlockDisableContainerRef}
                >
                  <div
                    className="editTableRow width100Percentage paddingLeft22px paddingTop18px cursorPointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedRow(data);
                      setOpenEdit(true);
                    }}
                  >
                    <img src={Edit} alt="edit" className="paddingRight4px" />
                    Edit
                  </div>
                  <div
                    className="blockTableRow width100Percentage paddingLeft22px cursorPointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      data?.isBlock === true
                        ? activateUser(data?.id)
                        : blockUser(data?.id);
                    }}
                  >
                    <img
                      src={data?.isBlock === true ? UserActivate : UserBlock}
                      alt="disable"
                      className="paddingRight4px"
                    />
                    {data?.isBlock === true ? "Activate" : "Block"}
                  </div>
                </div>
              )}
            </div>
            <img
              className="optionsDotsCss cursorPointer"
              alt="options"
              src={optionsDots}
              onClick={(e) => {
                e.stopPropagation();
                handleChangeForDots(index);
              }}
            />
          </TableCell>
        )}
      </TableRow>
      {openPreview?.open ? (
        <TransactionDetailsModal userID={openPreview?.id} />
      ) : null}
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {columns?.map(({ name, field, sortable, image }) => (
          <TableCell
            align={columns.align}
            key={name}
            className={classes.cursorDefault}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function UserTable({ data, showLoader, setShowLoader }) {
  const classes = useRowStyles();
  const history = useHistory();
  const airdropData = history.location.state?.data;
  const id = airdropData?.id;
  const airdropName = history.location.state?.name;
  console.log(id, "history");
  const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let day = today.getDate();
  let date1MonthBefore = new Date(year, month - 1, day);
  const [userList, setUserList] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectionRange, setSelectionRange] = useState({
    startDate: date1MonthBefore,
    endDate: new Date(),
    key: "selection",
  });
  const [searchText, setSearchText] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [statusFilterSelected, setStatusFilterSelected] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [isDateFilterEnabled, setIsDateFilterEnabled] = useState(false);
  const token = JSON.parse(localStorage.getItem("token"));
  const [openEdit, setOpenEdit] = useState(false);
  const [airdropStatus, setAirdropStatus] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [assetDetails, setAssetDetails] = useState();

  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  const tableColumnWidth = [5, 10, 15, 15, 15, 5, 25, 5, 5, 15];
  const columns = [
    {
      name: "Profile Picture",
      field: "profilepicture",
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Username",
      field: "username",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Name",
      field: "name",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Created",
      field: "created",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Email",
      field: "email",
      numeric: true,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "Role",
    //   field: "role",
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },
    {
      name: "Status",
      field: "status",
      numeric: true,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "ETH Balance",
    //   field: "eth",
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },
    // {
    //   name: "Transaction History",
    //   field: "transactionHistory",
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },
    {
      name: "Wallet Address",
      field: "walletAddress",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "View Transaction",
      field: "viewTransaction",
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Action",
      field: "action",
      numeric: false,
      sortable: false,
      align: "left",
    },
  ];

  const columnsAirdrop = [
    {
      name: "Profile Picture",
      field: "profilepicture",
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Username",
      field: "username",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Name",
      field: "name",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Requested On",
      field: "created",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Email",
      field: "email",
      numeric: true,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "Role",
    //   field: "role",
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },
    {
      name: "Status",
      field: "status",
      numeric: true,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "ETH Balance",
    //   field: "eth",
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },
    // {
    //   name: "Transaction History",
    //   field: "transactionHistory",
    //   numeric: true,
    //   sortable: false,
    //   align: "left",
    // },
  ];

  const columnsAirdropDetails = [
    {
      name: "Asset",
      field: "image",
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Asset ID",
      field: "assetID",
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Name",
      field: "name",
      numeric: false,
      sortable: false,
      align: "left",
    },
  ];

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilterSelected, pageNo, selectionRange, isDateFilterEnabled]);

  useDebounce(
    () => {
      if (searchText != null && searchText != "" && searchText != undefined) {
        getUserDetails();
      }
    },
    [searchText],
    1000
  );

  const handleSelect = (ranges) => {
    setSelectionRange(ranges?.selection);
    setPageNo(1);
  };

  const handleChangePage = (event, value) => {
    setPageNo(value);
    window.scrollTo(0, 0);
  };

  const getUserDetails = async () => {
    let startDate = new Date(
      selectionRange?.startDate?.getTime() -
        selectionRange?.startDate?.getTimezoneOffset() * 60000
    ).toISOString();
    let endDate = new Date(
      selectionRange?.endDate?.getTime() -
        selectionRange?.endDate?.getTimezoneOffset() * 60000
    ).toISOString();
    //To set endDate time last minute of the day
    // endDate = moment(endDate).add(1, "days");
    endDate = moment(endDate);
    endDate.set({ hour: 5, minute: 29, second: 59, millisecond: 59 });
    endDate = endDate.toISOString();

    let statusFilter = "";
    if (statusFilterSelected == "blocked") {
      statusFilter = true;
    } else if (statusFilterSelected == "active") {
      statusFilter = false;
    }
    if (!id) {
      await axiosInstance
        .get(
          `/v1/admin/user?page=${pageNo}&limit=10&search=${encodeURIComponent(
            searchText
          )}&isBlock=${statusFilter}&fromDate=${
            isDateFilterEnabled ? startDate : ""
          }&toDate=${isDateFilterEnabled ? endDate : ""}`,
          header
        )
        .then((result) => {
          setUserList(result?.data?.result?.users);
          setTotalCount(result?.data?.result?.totalPages);
          setTotalItemCount(result?.data?.result?.totalItems);
          setTotalUser(result?.data?.result?.totalUsers);
        })
        .catch((err) => {
          let respErr = handleAPIError(err);
          Swal.fire("Oops!", respErr, "error");
        });
    } else {
      await axiosInstance
        .get(
          `v1/admin/airdrop-request/${id}?search=${encodeURIComponent(
            searchText
          )}&order='DESC'&page=${pageNo}&limit=10`,
          header
        )
        .then((result) => {
          setAssetDetails(result?.data?.result?.airdrops?.[0]?.asset);
          console.log(result, "user details");
          setAirdropStatus(result?.data?.result?.airdrops);
          setUserList(result?.data?.result?.airdrops.map((item) => item?.user));
          setTotalCount(result?.data?.result?.totalPages);
          setTotalItemCount(result?.data?.result?.totalItems);
          setTotalUser(result?.data?.result?.airdrops.length);
        });
    }
  };

  const downloadReport = () => {
    let statusFilter =
      statusFilterSelected != -1
        ? statusFilterSelected == "blocked"
          ? true
          : false
        : "";
    // Caluculating startDate and endDate to pass to backend
    let startDate = new Date(
      selectionRange?.startDate?.getTime() -
        selectionRange?.startDate?.getTimezoneOffset() * 60000
    ).toISOString();

    let endDate = new Date(
      selectionRange?.endDate?.getTime() -
        selectionRange?.endDate?.getTimezoneOffset() * 60000
    ).toISOString();
    //To set endDate time last minute of the day
    // endDate = moment(endDate).add(1, "days");
    endDate = moment(endDate);

    // Data Range library issue while selecting yesterday, Below bypass time correction for dates selected from sidemenu (eg: select yesterday from side bar of dataRange interface)
    if (moment(selectionRange?.endDate).format("ss") != 59) {
      endDate = moment(endDate).add(1, "days");
    }

    endDate.set({ hour: 5, minute: 29, second: 59, millisecond: 59 });
    endDate = endDate.toISOString();

    // const url = `${baseURL}v1/user/csv/download`;

    if (!id) {
      const url = `${baseURL}v1/user/csv/file/download?page=${pageNo}&fromDate=${
        isDateFilterEnabled ? startDate : ""
      }&toDate=${
        isDateFilterEnabled ? endDate : ""
      }&search=${encodeURIComponent(searchText)}&isBlock=${statusFilter}`;

      window.open(url, "_blank");
    } else {
      // const token = localStorage.getItem("token");
      // const url = `${baseURL}v1/admin/airdrop-requests/export/${id}?token=${token}`
      // window.open(url, "_blank");

      const token = JSON.parse(localStorage.getItem("token")); // Retrieve the token from localStorage or wherever it is stored

      // Create the headers object with the Authorization header
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      console.log(headers, "header");
      // Make the Axios request with the headers
      axios
        .get(`${baseURL}v1/admin/airdrop-requests/export/${id}`, {
          headers: headers,
          responseType: "blob", // Specify the response type as blob for file download
        })
        .then((response) => {
          // Handle the file download logic here
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "filename.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error downloading file:", error);
        });
    }
  };

  const header = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  return (
    <div className="table_div tableWidth100">
      {showLoader == true ? <Loader /> : null}
      <div className="searchBoxProduct">
        <div className="TotalUsers">
          <div className="totalUserLabel">Total Users</div>
          <div className="totalUserContent">{totalUser}</div>
        </div>
        {!id && (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<CloudDownloadIcon>Report</CloudDownloadIcon>}
            onClick={() => downloadReport()}
          >
            Report
          </Button>
        )}
        {id && assetDetails?.soldEdition == 0 && (
          <>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              endIcon={<CloudUploadIcon>Report</CloudUploadIcon>}
              onClick={handleModalOpen}
            >
              Upload Airdrop
            </Button>
           
            <UploadModal open={modalOpen} onClose={handleModalClose} />
          </>
        )}
		{console.log("soldEditionsoldEditionsoldEdition",assetDetails)}
		 {id && assetDetails?.soldEdition > 0 && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Airdrop completed
              </Button>
            )}


        {id && (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<CloudDownloadIcon>Report</CloudDownloadIcon>}
            onClick={() => downloadReport()}
          >
            Download Airdrop
          </Button>
        )}
        {!id && (
          <Button
            variant="outlined"
            onClick={() => {
              setSelectionRange({
                startDate: date1MonthBefore,
                endDate: new Date(),
                key: "selection",
              });
              setStatusFilterSelected(-1);
              setSearchText("");
              setIsDateFilterEnabled(false);
              setPageNo(1);
            }}
          >
            Reset Filter
          </Button>
        )}
        {!id && (
          <>
            <div
              className="height55px displayFlex"
              onClick={() => setIsDateFilterEnabled(true)}
            >
              <DataRangePopOver
                selectionRange={selectionRange}
                setSelectionRange={setSelectionRange}
                handleSelect={handleSelect}
              />
            </div>
            <FormControl variant="outlined" className={classes.height24}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Filter
              </InputLabel>
              <Select
                native
                value={statusFilterSelected}
                onChange={(e) => {
                  setStatusFilterSelected(e.target.value);
                  setPageNo(1);
                }}
                label="Filter"
                inputProps={{
                  name: "Filter",
                  id: "outlined-age-native-simple",
                }}
                IconComponent={FilterListIcon}
                displayEmpty={true}
              >
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                    clipRule="evenodd"
                  />
                </svg>
                <optgroup label="Status">
                  <option value={-1}>All</option>
                  <option value={"blocked"}>Blocked</option>
                  <option value={"active"}>Active</option>
                </optgroup>
              </Select>
            </FormControl>
          </>
        )}
        <Autocomplete
          id="search-box-product"
          options={searchSuggestions}
          disableClearable={true}
          clearOnBlur={false}
          getOptionLabel={(option) => {
            return option.name || "";
          }}
          style={{ width: 300 }}
          inputValue={searchText}
          renderInput={(params) => (
            <TextField
              {...params}
              // value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value.trimStart());
                setPageNo(1);
              }}
              // onKeyDown={(e) => handleSearchBoxKeyDown(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              label="Search User"
              variant="outlined"
            />
          )}
        />
      </div>

      {id && (
        <>
          <TableContainer component={Paper} style={{ marginBottom: "1.5rem" }}>
            <Table aria-label="collapsible table" className={classes.root}>
              <RenderHeader columns={columnsAirdropDetails} />
              <TableRow
                className={classes.rowDatas}
                style={{
                  borderBottom: "3px solid #F5F5FC",
                  cursor: "pointer",
                  marginBottom: "20px",
                }}
              >
                <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
                  {/* <div className="tableCellContainer"> */}
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    {airdropData?.ipfsAudioUrl && (
                      <ReactPlayer
                        url={airdropData?.ipfsAudioUrl}
                        width="40px"
                        height="40px"
                        controls={true}
                        style={{
                          backgroundImage:
                            "url(" + airdropData?.audioThumbnail + ")",
                          backgroundSize: "cover",
                        }}
                        // onReady={true}
                        light={
                          airdropData?.audioThumbnail
                            ? airdropData?.audioThumbnail
                            : true
                        }
                        config={{
                          file: {
                            attributes: {
                              controlsList: "nodownload",
                            },
                          },
                        }}
                        // playIcon="true"
                      />
                    )}
                    {airdropData?.ipfsLongVideoUrl &&
                      !airdropData?.ipfsAudioUrl && (
                        <ReactPlayer
                          url={airdropData?.ipfsLongVideoUrl}
                          width="120px"
                          height="120px"
                          controls={true}
                          // onReady={true}
                          config={{
                            file: {
                              attributes: {
                                controlsList: "nodownload",
                              },
                            },
                          }}
                          // playIcon="true"
                        />
                      )}
                    {/* <img src={videoThumbnail && videoThumbnail} alt="Image" /> */}
                    {airdropData?.ipfsPreviewImageUrl &&
                      !airdropData?.ipfsLongVideoUrl &&
                      airdropData?.ipfsImageUrl.split(".").pop() != "glb" &&
                      airdropData?.ipfsImageUrl.split(".").pop() != "gltf" && (
                        <img
                          style={{ width: "60px", height: "60px" }}
                          src={
                            airdropData?.ipfsPreviewImageUrl
                              ? `${airdropData?.ipfsPreviewImageUrl}`
                              : null
                          }
                          alt="Img"
                        />
                      )}
                    {/* {airdropData?.ipfsImageUrl &&
								!airdropData?.ipfsLongVideoUrl &&
								airdropData?.ipfsImageUrl.split(".").pop() == "glb" &&
								airdropData?.ipfsImageUrl.split(".").pop() == "gltf" && (
									<img src={airdropData["3DURL"] ? airdropData["3DURL"] : null} alt="Image" />
								)} */}
                    {airdropData?.thumbnailUrl &&
                      !airdropData?.ipfsLongVideoUrl &&
                      !airdropData?.ipfsAudioUrl && (
                        <img
                          src={
                            airdropData["thumbnailUrl"]
                              ? airdropData["thumbnailUrl"]
                              : null
                          }
                          alt="Img"
                        />
                      )}
                    {/* {airdropData?.ipfsAudioUrl && <img src={AudioImg} alt="Image" />} */}
                  </div>
                </TableCell>
                <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
                  <div className="columnStyles">
                    {airdropData?.id ? airdropData?.id : "-"}
                  </div>
                </TableCell>
                <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
                  <div className="columnStyles">{airdropData?.name}</div>
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </>
      )}

      {userList?.length !== 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className={classes.root}>
              {!id && (
                <RenderHeader
                  columns={columns}
                  onSorting={(field, order) => setSorting({ field, order })}
                />
              )}
              {id && (
                <RenderHeader
                  columns={columnsAirdrop}
                  onSorting={(field, order) => setSorting({ field, order })}
                />
              )}

              <TableBody style={{ width: "100%" }}>
                {userList?.map((row, index) => (
                  <Row
                    aria-label="expand row"
                    size="small"
                    key={row?.id}
                    row={row}
                    setSelectedRow={(value) => setSelectedRow(value)}
                    selectedRow={selectedRow}
                    index={index}
                    data={userList[index]}
                    tableColumnWidth={tableColumnWidth}
                    selectedRowIndex={selectedRowIndex}
                    setselectedRowIndex={setSelectedRowIndex}
                    openEdit={openEdit}
                    setOpenEdit={setOpenEdit}
                    airdropStatus={airdropStatus?.[index]}
                    // setShowLoader={setShowLoader}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalCount}
            page={pageNo}
            onChange={handleChangePage}
          />
        </>
      ) : (
        <div className="noResultFound flexColumnCenter">
          <NoRecordsFound message="No records found." />
        </div>
      )}

      <UiDrawer
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        showLoader={showLoader}
        setShowLoader={(e) => setShowLoader(e)}
        isEdit={true}
        selectedRow={selectedRow}
      />
    </div>
  );
}
