import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { axiosInstance } from "../../utility/api";
import "./EditCreatorForm.css";
// import { socialLinks } from "social-links";

export default function EditCreatorForm({ selectedRow, isEdit }) {
  const [status, setStatus] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [userName, setUserName] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [userBio, setUserBio] = useState("");
  const [email, setEmail] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");
  const [password, setPassword] = useState("");

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  // const statusList = ["verified", "unverified"];
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  useEffect(() => {
    if (selectedRow) {
      setStatus(selectedRow?.status);
      setFirstName(selectedRow?.firstName);
      setLastName(selectedRow?.lastName);
      setUserName(selectedRow?.userName);
      setEmail(selectedRow?.email);
    }
  }, [selectedRow]);

  const handleEditForm = async (e) => {
    e.preventDefault();
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i;

    const mandatoryFields = [firstName, lastName, email, userName];

    for (const field of mandatoryFields) {
      if (!field) {
        Swal.fire("Oops!", "Please enter all mandatory fields", "error");
        return;
      }
    }

    if (!email.trim()) {
      Swal.fire("Oops!", "Email is required", "error");
      return;
    } else if (!emailRegex.test(email)) {
      Swal.fire("Oops!", "Invalid email format", "error");
      return;
    }

    // Additional validation for social media URLs
    if (facebookUrl && !isValidSocialLink("facebook", facebookUrl)) {
      Swal.fire("Oops!", "Invalid Facebook URL", "error");
      return;
    }
    if (twitterUrl && !isValidSocialLink("twitter", twitterUrl)) {
      Swal.fire("Oops!", "Invalid Twitter URL", "error");
      return;
    }
    if (instagramUrl && !isValidSocialLink("instagram", instagramUrl)) {
      Swal.fire("Oops!", "Invalid Instagram URL", "error");
      return;
    }
    let data = {
      firstName: firstName,
      lastName: lastName,
      userName: userName,
      email: email,
    };
    await axiosInstance
      .patch(`/v1/admin/user/${selectedRow?.id}`, data, config)
      .then((res) => {
        console.log("posted");
        // this.setState({
        //   ...this.state,
        //   showLoader: false,
        // });
        // this.setShowLoader(false, "");
        // this.props.onClose();
        Swal.fire("Success!", res?.data?.message, "success");
        window.location.reload(true);
        // Swal.fire("Success!", res?.data?.message, "success");
      })
      .catch((err) => {
        Swal.fire("Oops!", err?.data?.message, "error");
        // this.setShowLoader(false, "");
      });
  };

  const handleAddForm = async (e) => {
    e.preventDefault();

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i;

    const mandatoryFields = [firstName, lastName, email, userName];

    for (const field of mandatoryFields) {
      if (!field) {
        Swal.fire("Oops!", "Please enter all mandatory fields", "error");
        return;
      }
    }

    if (!email.trim()) {
      Swal.fire("Oops!", "Email is required", "error");
      return;
    } else if (!emailRegex.test(email)) {
      Swal.fire("Oops!", "Invalid email format", "error");
      return;
    }

    // Additional validation for social media URLs
    if (facebookUrl && !isValidSocialLink("facebook", facebookUrl)) {
      Swal.fire("Oops!", "Invalid Facebook URL", "error");
      return;
    }
    if (twitterUrl && !isValidSocialLink("twitter", twitterUrl)) {
      Swal.fire("Oops!", "Invalid Twitter URL", "error");
      return;
    }
    if (instagramUrl && !isValidSocialLink("instagram", instagramUrl)) {
      Swal.fire("Oops!", "Invalid Instagram URL", "error");
      return;
    }

    let data = {
      firstName: firstName,
      lastName: lastName,
      userName: userName,
      email: email,
      description: userBio,
      profilePicUrl: profilePhoto,
      coverPicUrl: coverPhoto,
      facebookUrl,
      twitterUrl,
      instagramUrl,
    };

    try {
      const res = await axiosInstance.post(
        `/v1/admin/user/create`,
        data,
        config
      );
      window.location.reload(true);
      Swal.fire("Success!", res?.data?.message, "success");
    } catch (err) {
      console.log("Error", err.response.data.message);
      Swal.fire("Error!", err.response.data.message, "error");
    }
  };

  const isValidSocialLink = (platform, url) => {
    let regex;

    if (platform === "facebook") {
      regex = /^(https?:\/\/)?(www\.)?facebook\.com\/.*/i;
    } else if (platform === "twitter") {
      regex = /^(https?:\/\/)?(www\.)?twitter\.com\/.*/i;
    } else if (platform === "instagram") {
      regex = /^(https?:\/\/)?(www\.)?instagram\.com\/.*/i;
    }

    return regex.test(url);
  };

  const handleChange = (event) => {
    switch (event.target.name) {
      case "firstName":
        setFirstName(event.target.value);
        break;
      case "lastName":
        setLastName(event.target.value);
        break;
      case "userName":
        setUserName(event.target.value);
        break;
      case "status":
        setStatus(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "userbio":
        setUserBio(event.target.value);
        break;
      case "facebookUrl":
        setFacebookUrl(event.target.value);
        break;
      case "instagramUrl":
        setInstagramUrl(event.target.value);
        break;
      case "twitterUrl":
        setTwitterUrl(event.target.value);
        break;

      default:
        break;
    }
  };

  // upload file

  const uploadFile = (e) => {
    let files = e.target.files[0];
    let formData = new FormData();
    formData.append("image", files);
    axiosInstance
      .post("/v1/admin/creator/uploadImg", formData, config)
      .then((res) => {
        Swal.fire("Great!", "Profile Photo successfully added", "success");
        if (e.target.name === "profile") {
          setProfilePhoto(res?.data?.result.location);
        } else {
          setCoverPhoto(res?.data?.result.location);
        }
      })
      .catch((err) => {
        Swal.fire("Oops!", err?.response?.data?.message, "error");
      });
  };

  return (
    <div className="col-xl-12 width30vw">
      <div className="container col-xl-12">
        <div className="row col-12">
          <div className="col-12">
            <h2 className="tm-block-title d-inline-block">
              {selectedRow ? "Edit" : "Add"} Creators
            </h2>
          </div>
        </div>
      </div>
      {/* <div className="row tm-edit-product-row"> */}
      <div className="  ">
        <form className="col-md-12">
          <div className="form-group">
            <label htmlFor="name">First Name</label>
            <span className="colorRed">*</span>
            <input
              type="text"
              name="firstName"
              className="form-control validate"
              value={firstName}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="form-group mb-12">
            <label htmlFor="name">Last Name</label>
            <span className="colorRed">*</span>
            <input
              type="text"
              name="lastName"
              className="form-control validate"
              value={lastName}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="form-group mb-12">
            <label htmlFor="name">Email</label>
            <span className="colorRed">*</span>
            <input
              type="text"
              name="email"
              className="form-control validate"
              value={email}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="form-group mb-12">
            <label htmlFor="name">User Name</label>
            <span className="colorRed">*</span>
            <input
              type="text"
              name="userName"
              className="form-control validate"
              value={userName}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="form-group mb-12">
            <label htmlFor="name">Bio</label>
            <span className="colorRed"></span>

            <textarea
              className="form-control validate"
              rows="3"
              required=""
              spellCheck="false"
              maxLength="400"
              name="userbio"
              value={userBio}
              onChange={(e) => handleChange(e)}
            ></textarea>
          </div>

          <div className="form-group mb-12">
            <label htmlFor="name">Facebook profile url</label>
            <span className="colorRed"></span>
            <input
              type="text"
              name="facebookUrl"
              className="form-control validate"
              value={facebookUrl}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="form-group mb-12">
            <label htmlFor="name">Instagram profile url</label>
            <span className="colorRed"></span>
            <input
              type="text"
              name="instagramUrl"
              className="form-control validate"
              value={instagramUrl}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="form-group mb-12">
            <label htmlFor="name">Twitter profile url</label>
            <span className="colorRed"></span>
            <input
              type="text"
              name="twitterUrl"
              className="form-control validate"
              value={twitterUrl}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div className="form-group mb-12">
            <label className="uploadButton">
              <p className="mt-2"> Upload Profile Photo</p>
              <input
                name="profile"
                type="file"
                id="my_file"
                style={{ display: "none" }}
                onChange={uploadFile}
                accept="image/*"
              />
            </label>
            <div style={{ display: "flex", justifyContent: "center" }}>
              Allowed file types : .png , .jpg
            </div>
          </div>
          <div className="form-group mb-12">
            <label className="uploadButton">
              <p className="mt-2"> Upload Cover Photo</p>
              <input
                type="file"
                name="cover"
                style={{ display: "none" }}
                onChange={uploadFile}
                accept="image/*"
              />
            </label>
            <div style={{ display: "flex", justifyContent: "center" }}>
              Allowed file types : .png , .jpg
            </div>
          </div>
          <div></div>

          <div className="form-group mb-12">
            <button
              type="submit"
              className="btn btn-primary btn-block text-uppercase"
              onClick={selectedRow ? handleEditForm : handleAddForm}
            >
              Save
            </button>
          </div>
        </form>
      </div>
      {/* </div> */}
    </div>
  );
}
