import React, { useState } from "react";
// import ProductTable from "../common/tableExpandable.jsx";
import UserTable from "../users/userTable";
import UiDrawer from "../users/UiDrawerEditUser";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
export default function Categories() {
	const [open, setOpen] = useState(false);
	// const [isEdit, setIsEdit] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
  let history = useHistory();
	// const token = JSON.parse(localStorage.getItem("token"));

	return (
		<>
			<div className="products-wrapper">
				<div className="container-fluide">
					<div className="page-title">
						<div className="row">
							<div className="col-6">
                <div className="pr-2 pt-1"> <ArrowBackIcon onClick={history.goBack}/></div>
								<h3>Users</h3>
							</div>

							{/* <div className="col-6">
                <nav className="" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/users/users/:layout">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                      </a>
                    </li>
                    <li className="active breadcrumb-item" aria-current="page">
                      Users
                    </li>
                  </ol>
                </nav>
              </div> */}
							<div
								className="col-12 mt-4"
								style={{ justifySelf: "flexgit -end" }}
							>
								{/* <button
                  className={"btn btn-primary"}
                  onClick={() => setOpen(true)}
                >
                  + Add User
                </button> */}
							</div>
						</div>
					</div>
				</div>
				<div className="products-content">
					<UserTable
					// setIsEdit={(value) => setIsEdit(value)}
					// setOpenEdit={() => {
					//   setOpen(true);
					//   console.log("ppp isedit 1", isEdit);
					//   getIsEdit();
					//   console.log("ppp isedit 2", isEdit);
					// }}
					/>
					{/* <ProductTable
            data={productList}
            columns={columns}
            tableColumnWidth={tableColumnWidth}
            totalDoc={totalDoc}
            pageNo={pageNo} <ProductTable
            data={productList}
            columns={columns}
            tableColumnWidth={tableColumnWidth}
            totalDoc={totalDoc}
            pageNo={pageNo}
            setPageNo={(pageNo) => setpageNo(pageNo)}
            showLoader={showLoader}
            setShowLoader={(e) => setShowLoader(e)}
            searchText={searchText}
            setSearchText={setSearchText}
            filterSelected={filterSelected}
            setFilterSelected={setFilterSelected}
          />
            setPageNo={(pageNo) => setpageNo(pageNo)}
            showLoader={showLoader}
            setShowLoader={(e) => setShowLoader(e)}
            searchText={searchText}
            setSearchText={setSearchText}
            filterSelected={filterSelected}
            setFilterSelected={setFilterSelected}
          /> */}
				</div>
			</div>

			<UiDrawer
				open={open}
				onClose={() => setOpen(false)}
				showLoader={showLoader}
				setShowLoader={(e) => setShowLoader(e)}
			/>
		</>
	);
}
