/* eslint-disable eqeqeq */
import React, { useState } from "react";
import "./analytics.css";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import Chart from "react-apexcharts";
import DataRangePopOver from "../common/DateRange/DateRangePopOver";
// import PopoverPopupState from "../Orders/components/dateRanagePopOver";
import { useHistory } from "react-router-dom";
import { axiosInstance } from "../../utility/api";

const Analytics = (props) => {
	const history = useHistory();
	const today = new Date();
	const year = today.getFullYear();
	const month = today.getMonth();
	const day = today.getDate();
	//const remainingDays = today.getDay();
	// const date1MonthBefore = new Date(year, month - 1, day);
	const weekBefore = new Date(year, month, day - 7);
	//const weekToDate = new Date(year, month, day - remainingDays);
	//const monthToDate = new Date(year, month, day - (day - 2));

	const [options, setOptions] = React.useState({
		chart: {
			id: "basic-bar",
		},
		markers: {
			size: 5,
		},
		stroke: {
			curve: "straight",
		},
		xaxis: {
			categories: [],
			title: {
				text: "Days",
			},
		},
		yaxis: {
			title: {
				text: "Sales",
			},
		},
	});

	const [series, setSeries] = useState([
		{
			name: "sales",
			data: [],
		},
	]);

	const [assetOptions, setAssetOptions] = React.useState({
		chart: {
			id: "basic-bar",
		},
		markers: {
			size: 5,
		},
		stroke: {
			curve: "straight",
		},
		xaxis: {
			categories: [],
			title: {
				text: "Days",
			},
		},
		yaxis: {
			title: {
				text: "Amount",
			},
		},
	});

	const [assetSeries, setAssetSeries] = useState([
		{
			name: "series",
			data: [],
		},
	]);

	const [dates, setDates] = useState({
		fromDate: weekBefore,
		toDate: today,
		period: "day",
	});
	const [selectionRange, setSelectionRange] = useState({
		startDate: weekBefore,
		endDate: new Date(),
		key: "selection",
	});
	const [totalSold, setTotalSold] = useState("");
	const [amt, setAmt] = useState("");

	const token = JSON.parse(localStorage.getItem("token"));
	const header = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const getGraphData = async () => {
		const result = await axiosInstance.get(
			`/v1/admin/dashboard/analytics/order`,
			{ ...header, params: { ...dates } }
		);
		const mapdata = result.data?.result?.orders;
		setTotalSold(result.data?.result?.totalOrders);
		const time = mapdata
			.map((item) => item.period + " " + item.month.substring(0, 3))
			.reverse();
		const data = mapdata.map((item) => item.order).reverse();
		setOptions({ ...options, xaxis: { categories: time } });
		setSeries([{ name: "sales", data }]);
	};

	const getAssetData = async () => {
		const assetResult = await axiosInstance.get(
			`/v1/admin/dashboard/analytics/asset?`,
			{ ...header, params: { ...dates } }
		);
		const assetData = assetResult.data?.result?.orders;
		setAmt(assetResult.data?.result?.totalSales?.toFixed(5));
		const time = assetData
			.map((item) => item.period + " " + item.month.substring(0, 3))
			.reverse();
		const data = assetData.map((item) => item.totalSale.toFixed(5)).reverse();
		setAssetOptions({
			...assetOptions,
			xaxis: { categories: time, title: { text: "Days" } },
		});
		setAssetSeries([{ name: "USD", data }]);
	};

	React.useEffect(() => {
		getGraphData();
		getAssetData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates]);

	// const handleRange = (e) => {
	// 	const value = e.target.value;
	// 	if (value === "lastWeek") {
	// 		setDates({ ...dates, fromDate: weekBefore });
	// 	}
	// 	if (value === "lastMonth") {
	// 		setDates({ ...dates, fromDate: date1MonthBefore });
	// 	}
	// 	if (value === "weekly") {
	// 		setDates({ ...dates, fromDate: weekToDate });
	// 	}
	// 	if (value === "monthly") {
	// 		setDates({ ...dates, fromDate: monthToDate });
	// 	}
	// };

	return (
		<div>
			<Breadcrumb parent="Dashboard" title="analytics" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h4>Performance</h4>
								<div className="dateRangeContainer">
									{/* <PopoverPopupState
                    selectionRange={selectionRange}
                    handleSelect={handleSelect}
                  /> */}
									<span
										style={{
											fontSize: 20,
											marginRight: 20,
										}}
									>
										{" "}
										Select Range
									</span>
									{/* <select onChange={handleRange}>
										<option value="lastWeek"> Last Week </option>
										<option value="lastMonth"> Last Month </option>
										<option value="monthly"> Month to Date </option>
										<option value="weekly"> Week to Date </option>
									</select> */}
									<div
										className="height55px displayFlex"
										//onClick={() => setIsDateFilterEnabled(true)}
									>
										<DataRangePopOver
											selectionRange={selectionRange}
											setSelectionRange={setSelectionRange}
											handleSelect={(ranges) => {
												setSelectionRange(ranges?.selection);
												let startDate = new Date(
													ranges?.selection?.startDate?.getTime() -
														ranges?.selection?.startDate?.getTimezoneOffset() *
															60000
												).toISOString();

												let endDate = new Date(
													ranges?.selection?.endDate?.getTime() -
														ranges?.selection?.endDate?.getTimezoneOffset() *
															60000
												).toISOString();
												if (startDate != endDate) {
													setDates({
														...dates,
														fromDate: startDate,
														toDate: endDate,
													});
												}
											}}
										/>
									</div>
								</div>
							</CardHeader>
							<CardBody>
								<div className="catagories">
									<div
										className="single-detail"
										onClick={() =>
											history.push(
												`${process.env.PUBLIC_URL}/analytics/dashboard/totalOrders/:layout`
											)
										}
									>
										<p>Product Analytics</p>
										<p>Total Items Sold: {totalSold}</p>
									</div>
									<div
										className="single-detail"
										onClick={() =>
											history.push(
												`${process.env.PUBLIC_URL}/analytics/dashboard/totalAssets/:layout`
											)
										}
									>
										<p>Transaction Analytics</p>
										<p>Total Sales : {amt}</p>
									</div>
								</div>

								<div style={{ width: "100%" }} className="chart-container">
									<div className="single-chart">
										<div>
											<h4> Product Analytics</h4>
										</div>
										<Chart
											options={options}
											series={series}
											type="line"
											width="440"
										/>
									</div>
									<div
										style={{ borderLeft: "1px solid #bbb" }}
										className="single-chart"
									>
										<div>
											<h4>Transaction Analytics </h4>
										</div>
										<Chart
											options={assetOptions}
											series={assetSeries}
											type="line"
											width="440"
										/>
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Analytics;
