import { axiosInstance } from "../../utility/api";
import * as APIUrls from "../../utility/APIRoutes";

const getProductList = async ({ params, headers }) => {
	return axiosInstance.get(APIUrls.ProductBaseURL, { params, headers });
};

const getAirDropList = async ({ params, headers }) => {
	return axiosInstance.get(APIUrls.AirDropBaseURL, { params, headers });
};

const getCommissionList = async ({ params, headers }) => {
	return axiosInstance.get(APIUrls.CommissionListingBaseURL, {
		params,
		headers,
	});
};

export { getProductList, getCommissionList , getAirDropList};
