/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

import "./commissionSetting.css";
import { axiosInstance } from "../../utility/api";
import Primary from "../../assets/images/icons/Primary.svg";
import Secondary from "../../assets/images/icons/Secondary.svg";

export default function CommissionSetting() {
	const [commissionType, setCommissionType] = useState("secondary");
	const [commissionList, setCommissionList] = useState([]);
	const [primaryPlatformCommission, setPrimaryPlatformCommission] = useState({
		id: "",
		platformCommission: "",
	});
	const [secondaryPlatformCommission, setSecondaryPlatformCommission] =
		useState({
			id: "",
			platformCommission: "",
		});
	// const [royaltyCommission, setRoyaltyCommission] = useState(null);
	const config = {
		headers: {
			Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
		},
	};

	useEffect(() => {
		getCommissionList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const commissionTypeOptions = [
	//   { label: "Primary Commission", value: "primary" },
	//   { label: "Secondary Commission", value: "secondary" },
	// ];

	const getCommissionList = async () => {
		await axiosInstance
			.get("/v1/admin/commissions", config)
			.then((result) => {
				setCommissionList(result?.data?.result);
				let primary = result?.data?.result?.find(
					(element) => element?.commissionType == "primary"
				);
				let secondary = result?.data?.result?.find(
					(element) => element?.commissionType == "secondary"
				);
				setPrimaryPlatformCommission(primary);
				setSecondaryPlatformCommission(secondary);
			})
			.catch((err) => {
				if (
					err?.response &&
					err?.response?.data?.errors &&
					err?.response?.data?.errors?.length !== 0
				) {
					Swal.fire({
						title: "Oops!",
						text: err?.response?.data?.errors.map((err) => err.msg),
						icon: "error",
					});
				} else {
					Swal.fire("Oops!", err?.response?.data?.message, "error");
				}
			});
	};

	const handleSubmit = async (type) => {
		if (
			primaryPlatformCommission?.platformCommission < 0 ||
			primaryPlatformCommission?.platformCommission > 20
		) {
			Swal.fire(
				"Oops!",
				"Admin platform commission should between 0% to 20%.",
				"error"
			);
			return false;
		}
		let data = {
			commissionType: type,
			platformCommission:
				type === "primary"
					? primaryPlatformCommission?.platformCommission
					: secondaryPlatformCommission?.platformCommission,
		};
		await axiosInstance
			.patch(
				`/v1/admin/commissions/${
					type === "primary"
						? primaryPlatformCommission?.id
						: secondaryPlatformCommission?.id
				}`,
				data,
				config
			)
			.then((res) => {
				console.log("posted");
				// this.setShowLoader(false, "");
				Swal.fire("Success!", res?.data?.message, "success");
			})
			.catch((err) => {
				// this.setShowLoader(false, "");
				Swal.fire("Error!", err?.response?.data?.errors, "error");
			});
	};

	return (
		<div className="commissionSettingWrapper">
			<div className="containers">
				<div className="container cone">
					<div className="icon flexRowFlexEnd">
						<img src={Primary} alt="primary" height="34px" />
						<h4>
							<u>Primary Platform Commission.</u>
						</h4>
					</div>

					{/* <h4>Residential address</h4> */}

					<table>
						<tbody>
							<tr>
								<td className="commissionTableHeader">
									<label htmlFor="stock">Commission type</label>
								</td>
								<td className="commissionTableContent paddingLeft78">
									<select
										className="custom-select tm-select-accounts"
										name="commissionType"
										id="commissionType"
										value={commissionType}
										onChange={(e) => setCommissionType(e.target.value)}
										required
										disabled
									>
										<option value="" selected>
											Primary Admin Commission
										</option>
										{/* {commissionTypeOptions?.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))} */}
									</select>
								</td>
							</tr>
							<tr>
								<td className="commissionTableHeader">
									<label htmlFor="stock">Admin Platform Commission</label>
								</td>
								<td className="commissionTableContent paddingLeft78 displayFlexBaseline">
									<input
										type="number"
										name="adminCommission"
										min="0"
										className="form-control validate marginLeft10pxN"
										value={primaryPlatformCommission?.platformCommission}
										// onKeyDown={formatInputDot}
										onChange={(e) => {
											if (e.target.value < 101) {
												setPrimaryPlatformCommission({
													...primaryPlatformCommission,
													platformCommission: e.target.value,
												});
											}
										}}
										required
									/>
									<span className="percentageImgContainer">%</span>
								</td>
							</tr>
						</tbody>
					</table>
					<div className="flexCenter">
						<div className="btn" onClick={() => handleSubmit("primary")}>
							Save Changes
						</div>
					</div>
				</div>
				<div className="container cone">
					<div className="icon flexRowFlexEnd">
						<img src={Secondary} alt="Secondary" height="34px" />
						<h4>
							<u>Secondary Platform Commission.</u>
						</h4>
					</div>
					<table>
						<tbody>
							<tr>
								<td className="commissionTableHeader">
									<label htmlFor="stock">Commission type</label>
								</td>
								<td className="commissionTableContent paddingLeft78">
									<select
										className="custom-select tm-select-accounts"
										name="commissionType"
										id="commissionType"
										value={commissionType}
										onChange={(e) => setCommissionType(e.target.value)}
										required
										disabled
									>
										<option value="" selected>
											Secondary Sale Commission
										</option>
									</select>
								</td>
							</tr>
							<tr>
								<td className="commissionTableHeader">
									<label htmlFor="stock">Platform commission</label>
								</td>
								<td className="commissionTableContent paddingLeft78 displayFlexBaseline">
									<input
										type="number"
										name="adminCommission"
										min="0"
										className="form-control validate marginLeft10pxN"
										value={secondaryPlatformCommission?.platformCommission}
										// onKeyDown={formatInputDot}
										onChange={(e) => {
											if (e.target.value < 101) {
												setSecondaryPlatformCommission({
													...secondaryPlatformCommission,
													platformCommission: e.target.value,
												});
											}
										}}
										required
									/>
									<span className="percentageImgContainer">%</span>
								</td>
							</tr>
						</tbody>
					</table>
					<div className="flexCenter">
						<div className="btn" onClick={() => handleSubmit("secondary")}>
							Save Changes
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
