/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Swal from "sweetalert2";

import "./UploadFileModal.css";
import FilePreview from "../../../assets/images/icons/file.svg";
import CloseButton from "../../../assets/images/icons/x-circle.svg";
import AddButton from "../../../assets/images/icons/plus-circle.svg";
import defaultAudiothumbnail from "../../../assets/images/logo/logo_asly.png";
import ThreeDeeViewer from "../../common/ThreeDeeViewer/ThreeDeeViewer";
import Loader from "../../common/Loader/Loader";
import ThreeDIcon from "../../../assets/images/other-images/3DIcon.png";
import RemoveFileUploaded from "./RemoveFileUploaded";

export default function UploadFileModal(props) {
	const {
		handleMultiFileUpload,
		state,
		handleClose,
		previewVideoNeeded,
		preview3DNeeded,
		previewImgNeeded,
		disableSupportingDocs,
		isSuplimentaryFile1Video,
		isSuplimentaryFile2Video,
		isSuplimentaryFile3Video,
		isSuplimentaryFile4Video,
		handleRemoveFile,
		handleDeleteAll,
		// is3dFile,
		// ipfs3dUrl,
	} = props;

	const [loader, setLoader] = useState(false);
	const [selectedFileTab, setSelectedFileTab] = useState("mainFile");
	const [selectedFile, setSelectedFile] = useState([null]);
	const audioMimeType = ["aac", "mpeg", "ogg", "wav", "x-flac", "flac"];
	const getTheSublimentoryfile1Label = () => {
		if (previewImgNeeded) {
			return "Thumbnail For Main Audio";
		} else if (preview3DNeeded) {
			return "3D Thumbnail needed";
		} else {
			return "Supplimentary file 1.";
		}
	};
	const fileLabels = {
		mainFile: "Main file.",
		supplimentaryFile1: getTheSublimentoryfile1Label(),
		supplimentaryFile2: previewImgNeeded
			? "Supplimentary file 2."
			: "Supplimentary file 2.",
		supplimentaryFile3: previewImgNeeded
			? "Supplimentary file 3."
			: "Supplimentary file 3.",
		supplimentaryFile4: previewImgNeeded
			? "Supplimentary file 4."
			: "Supplimentary file 4.",
		none: "",
	};
	// let cameraPosition = {
	//   x: 150,
	//   y: 300,
	//   z: 350,
	// };
	// const onLoad = (e) => {
	//   console.log(e);
	// };

	// const onError = (e) => {
	//   console.log(e);
	// };

	useEffect(() => {
		if (
			state?.error?.status == true &&
			state?.error?.location == "uploadFilePage"
		) {
			Swal.fire("Error!", "Please upload a valid file.", "error");
		}
	}, [state.error]);

	useEffect(() => {
		switch (selectedFileTab) {
			case "mainFile":
				state?.multiFileStore?.mainFile?.length > 0
					? setSelectedFile(state?.multiFileStore?.mainFile)
					: setSelectedFile([]);
				break;

			case "supplimentaryFile1":
				state?.multiFileStore?.supplimentary1?.length > 0
					? setSelectedFile(state?.multiFileStore?.supplimentary1)
					: setSelectedFile([]);
				break;
			case "supplimentaryFile2":
				state?.multiFileStore?.supplimentary2?.length > 0
					? setSelectedFile(state?.multiFileStore?.supplimentary2)
					: setSelectedFile([]);
				break;
			case "supplimentaryFile3":
				state?.multiFileStore?.supplimentary3?.length > 0
					? setSelectedFile(state?.multiFileStore?.supplimentary3)
					: setSelectedFile([]);
				break;
			case "supplimentaryFile4":
				state?.multiFileStore?.supplimentary4?.length > 0
					? setSelectedFile(state?.multiFileStore?.supplimentary4)
					: setSelectedFile([]);
				break;

			default:
				break;
		}
	}, [
		selectedFileTab,
		state.multiFileStore.mainFile,
		state.multiFileStore.supplimentary1,
		state.multiFileStore.supplimentary2,
		state.multiFileStore.supplimentary3,
		state.multiFileStore.supplimentary4,
	]);

	const saveMethod = () => {
		if (state?.multiFileStore?.mainFile?.length > 0) {
			handleClose();
		} else {
			Swal.fire("Oops!", "Please upload the main file", "error");
		}
	};
	const isFileStoreNotEmpty = (obj) => {
		let haveItems = false;
		let createdArr = Object.values(obj);

		for (let i = 0; i < createdArr.length; i++) {
			if (createdArr[i].length > 0) {
				haveItems = true;
				break;
			}
		}
		return haveItems;
	};
	// const isMainFileOnlyExist = (obj) => {
	//   let haveSupplimentaryItems = false;
	//   let createdArr = Object.values(obj);

	//   for (let i = 0; i < createdArr.length; i++) {
	//     if (createdArr[i]?.[4] != "mainFile") {
	//       haveSupplimentaryItems = true;
	//       break;
	//     }
	//   }
	//   return haveSupplimentaryItems;
	// };

	return (
		<div>
			<div className="fileUploadWrapper">
				<div className="fileUploadHeader">
					<img
						src={CloseButton}
						alt="filePreview"
						height="30px"
						className="cursorPointer"
						onClick={(e) => handleClose(e)}
					/>
				</div>
				<div className="fileUploadPreview">
					<div className="fileUploadPreviewWrapper flexRowNowrap">
						<div className="fileUploadPreveiwSideMenuLabel">
							<div className="fileUploadPreveiwSideMenuLabel-1 fileUploadLabel">
								Main File
							</div>
							<div className="fileUploadPreveiwSideMenuLabel-2 fileUploadLabel">
								SF1
							</div>
							<div className="fileUploadPreveiwSideMenuLabel-3 fileUploadLabel">
								SF2
							</div>
							<div className="fileUploadPreveiwSideMenuLabel-4 fileUploadLabel">
								SF3
							</div>
							<div className="fileUploadPreveiwSideMenuLabel-5 fileUploadLabel">
								SF4
							</div>
						</div>
						<div className="fileUploadPreviewSideMenu">
							<div className="fileUploadPreviewSideMenu-1 flexRowCenter">
								{state?.is3dFile && (
									<div
										className="fileUploadPreviewBox"
										style={{
											backgroundImage: `url("${ThreeDIcon}")`,
											backgroundSize: "cover",
											backgroundRepeat: "no-repeat",
										}}
										onClick={() => setSelectedFileTab("mainFile")}
									></div>
								)}
								{previewImgNeeded && state?.is3dFile == false && (
									<div
										className="fileUploadPreviewBox"
										style={{
											backgroundImage: `url("${
												state?.multiFileStore?.supplimentary1?.length > 0 &&
												state?.multiFileStore?.supplimentary1[2]
											}")`,
											backgroundSize: "cover",
											backgroundRepeat: "no-repeat",
											cursor:
												state?.multiFileStore?.supplimentary1?.length > 0
													? "pointer"
													: "default",
										}}
										onClick={() => setSelectedFileTab("mainFile")}
									></div>
								)}
								{previewVideoNeeded && (
									<div className="fileUploadPreviewBox">
										<ReactPlayer
											url={state?.multiFileStore?.mainFile}
											width="100%"
											height="100%"
											controls={false}
											config={{
												file: {
													attributes: {
														controlsList: "nodownload",
													},
												},
											}}
											// playIcon="true"
											onClick={() => setSelectedFileTab("mainFile")}
										/>
									</div>
								)}

								{!previewImgNeeded &&
									!previewVideoNeeded &&
									state.isMainFileVideo == false &&
									state?.is3dFile == false && (
										<div
											className="fileUploadPreviewBox"
											style={{
												backgroundImage: `url("${
													state?.multiFileStore?.mainFile?.length > 0 &&
													state?.multiFileStore?.mainFile[2]
												}")`,
												backgroundSize: "cover",
												backgroundRepeat: "no-repeat",
												cursor:
													state?.multiFileStore?.mainFile?.length > 0
														? "pointer"
														: "default",
											}}
											onClick={() => setSelectedFileTab("mainFile")}
										></div>
									)}
							</div>

							{state?.isSuplimentaryFile13d && (
								<div
									className="fileUploadPreviewBox marginLeft25px"
									style={{
										backgroundImage: `url("${ThreeDIcon}")`,
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat",
									}}
									onClick={() => setSelectedFileTab("supplimentaryFile1")}
								></div>
							)}

							{isSuplimentaryFile1Video && !state?.isSuplimentaryFile13d && (
								<div
									className="fileUploadPreviewSideMenu-122 flexRowCenter"
									id="video1"
								>
									<div className="fileUploadPreviewBox">
										<ReactPlayer
											url={state?.multiFileStore?.supplimentary1}
											width="100%"
											height="100%"
											controls={false}
											config={{
												file: {
													attributes: {
														controlsList: "nodownload",
													},
												},
											}}
											// playIcon="true"
											onClick={() => setSelectedFileTab("supplimentaryFile1")}
										/>
									</div>
								</div>
							)}

							{!isSuplimentaryFile1Video && !state?.isSuplimentaryFile13d && (
								<div className="fileUploadPreviewSideMenu-3 flexRowCenter">
									<div
										className="fileUploadPreviewBox"
										style={{
											backgroundImage: `url("${
												state?.multiFileStore?.supplimentary1?.length > 0 &&
												state?.multiFileStore?.supplimentary1[2]
											}")`,
											backgroundSize: "cover",
											backgroundRepeat: "no-repeat",
											cursor:
												state?.multiFileStore?.supplimentary1?.length > 0
													? "pointer"
													: "default",
										}}
										onClick={() => setSelectedFileTab("supplimentaryFile1")}
										disabled={
											state?.multiFileStore?.supplimentary1?.length > 0
												? true
												: false
										}
									>
										{state?.multiFileStore?.supplimentary1?.length > 0 &&
											[...audioMimeType].includes(
												state?.multiFileStore?.supplimentary1[2]
													?.split(".")
													.pop()
											) && (
												<img
													src={defaultAudiothumbnail}
													className="defaultThumbnailCss"
													width="100%"
													alt="thumbnail"
												/>
											)}
									</div>
								</div>
							)}

							{/* <div className="fileUploadPreviewSideMenu-2 flexRowCenter">
                {!previewImgNeeded &&
                  !isSuplimentaryFile1Video &&
                  !isSuplimentaryFile3Video &&
                  !isSuplimentaryFile4Video && (
                    <div
                      className="fileUploadPreviewBox"
                      style={{
                        backgroundImage: `url("${
                          state?.multiFileStore?.supplimentary1?.length > 0 &&
                          state?.multiFileStore?.supplimentary1[2]
                        }")`,
                        backgroundSize: "cover",
                        cursor:
                          state?.multiFileStore?.supplimentary1?.length > 0
                            ? "pointer"
                            : "default",
                      }}
                      onClick={() => setSelectedFileTab("supplimentaryFile1")}
                      disabled={
                        state?.multiFileStore?.supplimentary1?.length > 0
                          ? true
                          : false
                      }
                    >
                      {state?.multiFileStore?.supplimentary1?.length > 0 &&
                        [...audioMimeType].includes(
                          state?.multiFileStore?.supplimentary1[2]
                            ?.split(".")
                            .pop()
                        ) && (
                          <img
                            src={defaultAudiothumbnail}
                            className="defaultThumbnailCss"
                            width="100%"
                          />
                        )}
                    </div>
                  )}
              </div> */}
							{isSuplimentaryFile2Video && (
								<div
									className="fileUploadPreviewSideMenu-3 flexRowCenter"
									id="video2"
								>
									<div className="fileUploadPreviewBox">
										<ReactPlayer
											url={state?.multiFileStore?.supplimentary2}
											width="100%"
											height="100%"
											controls={false}
											config={{
												file: {
													attributes: {
														controlsList: "nodownload",
													},
												},
											}}
											// playIcon="true"
											onClick={() => setSelectedFileTab("supplimentaryFile2")}
										/>
									</div>
								</div>
							)}
							{!isSuplimentaryFile2Video && (
								<div className="fileUploadPreviewSideMenu-3 flexRowCenter">
									<div
										className="fileUploadPreviewBox"
										style={{
											backgroundImage: `url("${
												state?.multiFileStore?.supplimentary2?.length > 0 &&
												state?.multiFileStore?.supplimentary2[2]
											}")`,
											backgroundSize: "cover",
											backgroundRepeat: "no-repeat",
											cursor:
												state?.multiFileStore?.supplimentary2?.length > 0
													? "pointer"
													: "default",
										}}
										onClick={() => setSelectedFileTab("supplimentaryFile2")}
										disabled={
											state?.multiFileStore?.supplimentary2?.length > 0
												? true
												: false
										}
									>
										{state?.multiFileStore?.supplimentary2?.length > 0 &&
											[...audioMimeType].includes(
												state?.multiFileStore?.supplimentary2[2]
													?.split(".")
													.pop()
											) && (
												<img
													src={defaultAudiothumbnail}
													className="defaultThumbnailCss"
													width="100%"
													alt="thumbnail"
												/>
											)}
									</div>
								</div>
							)}
							{isSuplimentaryFile3Video && (
								<div
									className="fileUploadPreviewSideMenu-4 flexRowCenter"
									id="video3"
								>
									<div className="fileUploadPreviewBox">
										<ReactPlayer
											url={state?.multiFileStore?.supplimentary3}
											width="100%"
											height="100%"
											controls={false}
											config={{
												file: {
													attributes: {
														controlsList: "nodownload",
													},
												},
											}}
											// playIcon="true"
											onClick={() => setSelectedFileTab("supplimentaryFile3")}
										/>
									</div>
								</div>
							)}

							{!isSuplimentaryFile3Video && (
								<div className="fileUploadPreviewSideMenu-4 flexRowCenter">
									<div
										className="fileUploadPreviewBox"
										style={{
											backgroundImage: `url("${
												state?.multiFileStore?.supplimentary3?.length > 0 &&
												state?.multiFileStore?.supplimentary3[2]
											}")`,
											backgroundSize: "cover",
											backgroundRepeat: "no-repeat",
											cursor:
												state?.multiFileStore?.supplimentary3?.length > 0
													? "pointer"
													: "default",
										}}
										onClick={() => setSelectedFileTab("supplimentaryFile3")}
										disabled={
											state?.multiFileStore?.supplimentary3?.length > 0
												? true
												: false
										}
									>
										{state?.multiFileStore?.supplimentary3?.length > 0 &&
											[...audioMimeType].includes(
												state?.multiFileStore?.supplimentary3[2]
													?.split(".")
													.pop()
											) && (
												<img
													src={defaultAudiothumbnail}
													className="defaultThumbnailCss"
													width="100%"
													alt="thumbnail"
												/>
											)}
									</div>
								</div>
							)}
							{isSuplimentaryFile4Video && (
								<div
									className="fileUploadPreviewSideMenu-1 flexRowCenter"
									id="video4"
								>
									<div className="fileUploadPreviewBox">
										<ReactPlayer
											url={state?.multiFileStore?.supplimentary4}
											width="100%"
											height="100%"
											controls={false}
											config={{
												file: {
													attributes: {
														controlsList: "nodownload",
													},
												},
											}}
											// playIcon="true"
											onClick={() => setSelectedFileTab("supplimentaryFile4")}
										/>
									</div>
								</div>
							)}
							{!isSuplimentaryFile4Video && (
								<div className="fileUploadPreviewSideMenu-5 flexRowCenter">
									<div
										className="fileUploadPreviewBox"
										style={{
											backgroundImage: `url("${
												state?.multiFileStore?.supplimentary4?.length > 0 &&
												state?.multiFileStore?.supplimentary4[2]
											}")`,
											backgroundSize: "cover",
											backgroundRepeat: "no-repeat",
											cursor:
												state?.multiFileStore?.supplimentary4?.length > 0
													? "pointer"
													: "default",
										}}
										onClick={() => setSelectedFileTab("supplimentaryFile4")}
										disabled={
											state?.multiFileStore?.supplimentary4?.length > 0
												? true
												: false
										}
									>
										{state?.multiFileStore?.supplimentary4?.length > 0 &&
											[...audioMimeType].includes(
												state?.multiFileStore?.supplimentary4[2]
													?.split(".")
													.pop()
											) && (
												<img
													src={defaultAudiothumbnail}
													className="defaultThumbnailCss"
													width="100%"
													alt="thumbnail"
												/>
											)}
									</div>
								</div>
							)}
						</div>
						<div className="fileUploadPreviewContent">
							{loader && state?.is3dFile && selectedFileTab == "mainFile" ? (
								<Loader
									loaderInfo="This may take a while to render 3D preview."
									customize={true}
								/>
							) : null}
							{loader &&
							state?.ipfs3dSupplimentary1Id &&
							selectedFileTab == "supplimentaryFile1" ? (
								<Loader
									loaderInfo="This may take a while to render 3D preview."
									customize={true}
								/>
							) : null}
							<div className="fileUploadPreviewContent-header">
								{fileLabels[selectedFileTab]}
							</div>
							{previewImgNeeded &&
								selectedFileTab == "mainFile" &&
								state?.is3dFile == false && (
									<img
										className="previewImg"
										src={
											state?.multiFileStore?.supplimentary1?.length > 0 &&
											state?.multiFileStore?.supplimentary1[2]
										}
										alt="preview"
									></img>
								)}
							{selectedFileTab == "mainFile" && state?.is3dFile && (
								<>
									{/* <ReactThreeFbxViewer
                    cameraPosition={cameraPosition}
                    url={state?.ipfs3dUrl}
                    onLoading={onLoad}
                    onError={onError}
                    width={480}
                    height={260}
                  /> */}
									<div style={{ display: loader ? "none" : "" }}>
										<ThreeDeeViewer
											id={state?.ipfs3dId}
											setLoader3D={(value) => setLoader(value)}
										/>
									</div>
								</>
							)}
							{selectedFileTab == "supplimentaryFile1" &&
								state?.isSuplimentaryFile13d && (
									<>
										{/* <ReactThreeFbxViewer
                    cameraPosition={cameraPosition}
                    url={state?.ipfs3dUrl}
                    onLoading={onLoad}
                    onError={onError}
                    width={480}
                    height={260}
                  /> */}
										<div style={{ display: loader ? "none" : "" }}>
											<ThreeDeeViewer
												id={state?.ipfs3dSupplimentary1Id}
												setLoader3D={(value) => setLoader(value)}
											/>
										</div>
									</>
								)}
							{selectedFile.length > 0 && selectedFile[3] == "video" && (
								<div className="fileUploadPreviewContent-content">
									<>
										<ReactPlayer
											url={selectedFile[2]}
											width="100%"
											height="100%"
											controls={true}
											config={{
												file: {
													attributes: {
														controlsList: "nodownload",
													},
												},
											}}
											// playIcon="true"
										/>
									</>
								</div>
							)}
							{selectedFile.length > 0 && selectedFile[3] == "audio" && (
								<>
									{selectedFileTab != "mainFile" && (
										<img
											src={defaultAudiothumbnail}
											className="defaultThumbnailCs alignSelfCenter"
											width="300px"
											alt="thumbnail"
										/>
									)}

									<ReactPlayer
										url={selectedFile[2]}
										width="100%"
										height="30px"
										controls={true}
										config={{
											file: {
												attributes: {
													controlsList: "nodownload",
												},
											},
										}}
										// playIcon="true"
									/>
								</>
							)}
							{selectedFile.length > 0 && selectedFile[3] == "image" && (
								<>
									<img
										className="previewImg"
										src={
											selectedFile.length > 0 ? selectedFile[2] : FilePreview
										}
										alt="filePreview"
									/>
								</>
							)}
							{selectedFile == "none" && (
								<img src={FilePreview} alt="filePreview" height="80px" />
							)}
							<div className="fileUploadPreviewContent-footer">
								{selectedFile.length > 0 ? selectedFile[1] : ""}
							</div>
						</div>
					</div>
				</div>
				<div className="fileUploadTool">
					<span className="acceptedFileFormat">
						<b>Accepted filetypes</b> : .jpg, .png, .gif, .wav, .ogg, .mp3,
						.mp4, .webm, .glb, gltf
					</span>
					{isFileStoreNotEmpty(state.multiFileStore) ? (
						<div className="fileUploadDeleteAllButtonWrapper">
							<button
								type="button"
								className={`fileUploadDeleteAllButton`}
								onClick={handleDeleteAll}
							>
								Delete All
							</button>
						</div>
					) : null}
				</div>
				<div className="fileUploadAction flexRowNowrap">
					<div
						className={
							"fileUploadActonMainFiles"
						}
					>
						<div className="fileUploadActionMainItems">
							<div className="fileUploadLabel">Main file</div>
							<div className="fileUploadMainItemWrapper">
								{state?.multiFileStore?.mainFile?.length > 0 ? (
									<RemoveFileUploaded
										{...{ state, handleRemoveFile, fileType: "mainFile" }}
									/>
								) : (
									<div
										className={`fileUploadActionItems`}
									>
										<div
											className={
												"fileUploadMainActionBox cursorPointer"
											}
											onClick={() => {
												
													handleMultiFileUpload("main_file");
													setSelectedFileTab("mainFile");
												
											}}
										>
											<img src={AddButton} alt="filePreview" height="30px" />
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
					<div
						className={
							"fileUploadActonSupportingFiles flexColumnNowrap " +
							(disableSupportingDocs && " opacity3")
						}
					>
						<div className="displayFlex">
							{/* {previewVideoNeeded && (
                <div className="textWidth98">Short Video</div>
              )} */}
							{/* {preview3DNeeded && (
                <div className="textWidth98">3D Thumbnail.</div>
              )} */}
							{/* {previewImgNeeded && (
                <div className="textWidth98">Thumbnail For Main Audio</div>
              )} */}
							<div className="fileUploadLabel">SF1/Thumbnail</div>
							<div className="fileUploadLabel marginLeft44px">SF2</div>
							<div className="fileUploadLabel marginLeft78px">SF3</div>
							<div className="fileUploadLabel marginLeft78px">SF4</div>
						</div>

						<div className="fileUploadSupportingItemWrapper flexRowNowrap">
							{state?.multiFileStore?.supplimentary1?.length > 0 ? (
								<RemoveFileUploaded
									{...{ state, handleRemoveFile, fileType: "supplimentary1" }}
								/>
							) : (
								<div
									className={
										"fileUploadActionItems" +
										(disableSupportingDocs && " cursorDefault")
									}
								>
									<div
										className={
											"fileUploadActionBox cursorPointer" +
											(disableSupportingDocs && " cursorDefault")
										}
										onClick={() => {
											if (!disableSupportingDocs) {
												handleMultiFileUpload("supporting_file1");
												setSelectedFileTab("supplimentaryFile1");
											}
										}}
									>
										<img src={AddButton} alt="filePreview" height="30px" />
									</div>
								</div>
							)}
							{state?.multiFileStore?.supplimentary2?.length > 0 ? (
								<RemoveFileUploaded
									{...{ state, handleRemoveFile, fileType: "supplimentary2" }}
								/>
							) : (
								<div
									className={
										"fileUploadActionItems" +
										(disableSupportingDocs && " cursorDefault")
									}
								>
									<div
										className={
											"fileUploadActionBox cursorPointer" +
											(disableSupportingDocs && " cursorDefault")
										}
										onClick={() => {
											if (!disableSupportingDocs) {
												handleMultiFileUpload("supporting_file2");
												setSelectedFileTab("supplimentaryFile2");
											}
										}}
									>
										<img src={AddButton} alt="filePreview" height="30px" />
									</div>
								</div>
							)}
							{state?.multiFileStore?.supplimentary3?.length > 0 ? (
								<RemoveFileUploaded
									{...{ state, handleRemoveFile, fileType: "supplimentary3" }}
								/>
							) : (
								<div
									className={
										"fileUploadActionItems" +
										(disableSupportingDocs && " cursorDefault")
									}
								>
									<div
										className={
											"fileUploadActionBox cursorPointer" +
											(disableSupportingDocs && " cursorDefault")
										}
										onClick={() => {
											if (!disableSupportingDocs) {
												handleMultiFileUpload("supporting_file3");
												setSelectedFileTab("supplimentaryFile3");
											}
										}}
									>
										<img src={AddButton} alt="filePreview" height="30px" />
									</div>
								</div>
							)}
							{state?.multiFileStore?.supplimentary4?.length > 0 ? (
								<RemoveFileUploaded
									{...{ state, handleRemoveFile, fileType: "supplimentary4" }}
								/>
							) : (
								<div
									className={
										"fileUploadActionItems" +
										(disableSupportingDocs && " cursorDefault")
									}
								>
									<div
										className={
											"fileUploadActionBox cursorPointer" +
											(disableSupportingDocs && " cursorDefault")
										}
										onClick={() => {
											if (!disableSupportingDocs) {
												handleMultiFileUpload("supporting_file4");
												setSelectedFileTab("supplimentaryFile4");
											}
										}}
									>
										<img src={AddButton} alt="filePreview" height="30px" />
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="fileUploadFooter flexRowCenter">
					<button
						type="submit"
						className="btn btn-primary text-uppercase"
						onClick={(e) => {
							// if (
							//   previewImgNeeded &&
							//   state?.multiFileStore?.supplimentary1?.length > 0
							// ) {
							//   handleClose(e);
							// } else if (
							//   previewImgNeeded &&
							//   !state?.multiFileStore?.supplimentary1?.length > 0
							// ) {
							//   Swal.fire(
							//     "Error!",
							//     "Thumbnail for the audio is needed",
							//     "error"
							//   );
							// }
							// if (
							//   previewVideoNeeded &&
							//   state?.multiFileStore?.supplimentary1?.length > 0
							// ) {
							//   handleClose(e);
							// }

							// if (!previewImgNeeded) {
							saveMethod(e);
							// }
						}}
					>
						Save
					</button>
				</div>
			</div>
		</div>
	);
}
