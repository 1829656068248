/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import Swal from "sweetalert2";
import "react-confirm-alert/src/react-confirm-alert.css";

// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";

import Loader from "../../common/Loader/Loader";
import editIcon from "../../../assets/images/icons/edit.svg";
import deleteIcon from "../../../assets/images/icons/delete.svg";
import { axiosInstance } from "../../../utility/api";
import NoRecordsFound from "../../common/Error/NoRecordsFound";
import UiDrawer from "../subcategory/components/addSubCategoryDrawer";
import { handleAPIError } from "../../../utility/helper";

const useRowStyles = makeStyles({
	root: {
		marginTop: "0px",
		border: "none",
		width: "100%",
	},
	header: {
		backgroundColor: "#F5F5FC",
	},
	rowDatas: {
		"& td,th": {
			border: "none",
			height: "90px",
		},
	},
	border__right: {
		borderRight: "2px solid white",
		height: "70%",
	},
	cursorDefault: {
		cursor: "default",
	},
});

//Each row of table
function Row(props) {
	const {
		//row,
		data,
		tableColumnWidth,
		// setShowLoader,
		// showLoader,
		setOpenEdit,
		//selectedRow,
		setSelectedRow,
		pageNo,
		setSubCategoryList,
		setTotalCount,
	} = props;
	const [open, setOpen] = React.useState(false);
	//const [deleteCategoryID, setDeleteCategoryID] = useState(null);
	// const token = JSON.parse(localStorage.getItem("token"));
	const classes = useRowStyles();

	const deleteSubCateogry = (id) => {
		console.log("deleted 2", id);
		if (id) {
			confirmAlert({
				title: "Delete sub category?",
				message: "Are you sure to delete.",
				buttons: [
					{
						label: "Yes",
						onClick: () => handleDelete(id),
					},
					{
						label: "No",
						onClick: () => console.log("Clicked No"),
					},
				],
			});
		}
	};

	const handleDelete = (id) => {
		const config = {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
			},
		};
		axiosInstance
			.delete(`/v1/admin/category/subCategory/${id}`, config)
			.then((res) => {
				Swal.fire("Success!", res?.data?.message, "success");
			})
			.then(() => {
				axiosInstance
					.get(
						`/v1/admin/category/subCategory?page=${pageNo + 1}&limit=10`,
						config
					)
					.then((result) => {
						setSubCategoryList(result?.data?.result?.assets);
						setTotalCount(result?.data?.result?.totalItems);
					});
			})

			.catch((err) => {
				let respErr = handleAPIError(err)
				Swal.fire("Oops!", respErr, "error");
			});
	};

	return (
		<React.Fragment>
			<TableRow
				onClick={() => {
					setOpen(!open);
				}}
				className={classes.rowDatas}
				style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
			>
				<TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
					<div className="columnStyles">{data?.name}</div>
				</TableCell>
				<TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
					<div className="columnStyles">
						{data?.category?.name && data?.category?.name}
					</div>
				</TableCell>
				{/* <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div className="columnStyles">{data?.counts && data?.counts}</div>
        </TableCell> */}
				{/* <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="positionToggle width64">
            <Switch
              checked={true}
              onChange={(e) => console.log(e)}
              name="activate"
              inputProps={{ "aria-label": "checkbox" }}
              disabled={true}
            />
          </div>
        </TableCell> */}
				<TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
					<Tooltip title="Edit category">
						<img
							src={editIcon}
							className="cursorPointer"
							alt="Edit"
							onClick={() => {
								setSelectedRow(data);
								setOpenEdit(true);
							}}
						/>
					</Tooltip>
					<Tooltip title="Delete category">
						<img
							src={deleteIcon}
							onClick={async () => {
								//await setDeleteCategoryID(data?.id);
								deleteSubCateogry(data?.id);
							}}
							className="marginLeft20 cursorPointer"
							alt="Delete"
						/>
					</Tooltip>
				</TableCell>
			</TableRow>
			{/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <ExpandedRow
                rowData={data}
                setShowLoader={(e) => setShowLoader(e)}
                showLoader={showLoader}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
		</React.Fragment>
	);
}

const RenderHeader = ({ columns, onSorting }) => {
	const [sortingField, setSortingField] = useState("");
	const [sortingOrder, setSortingOrder] = useState("asc");

	const onSortingChange = (field) => {
		const order =
			field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
		setSortingField(field);
		setSortingOrder(order);
		onSorting(field, order);
	};

	const classes = useRowStyles();
	return (
		<TableHead className={classes.header}>
			<TableRow>
				{columns?.map(({ name, field, sortable, image }) => (
					<TableCell
						align={columns.align}
						key={name}
						className={classes.cursorDefault}
					>
						<div className="dividerFlex">
							<div
								style={{
									display: "flex",
									justifyContent: "left",
									alignItems: "center",
									fontWeight: "600",
									fontSize: "12px",
									cursor: sortable ? "pointer" : "default",
								}}
								onClick={() => (sortable ? onSortingChange(field) : null)}
							>
								{name}
								{/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
							</div>
						</div>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default function SubCategoryTable({
	data,
	showLoader,
	setShowLoader,
	selectedTab,
	subCategoryList,
	totalCount,
	setPageNo,
	pageNo,
	setSubCategoryList,
	setTotalCount,
	totalPages,
	searchText,
	setSearchText,
	filterSelected,
	setFilterSelected,
}) {
	const classes = useRowStyles();
	const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
	const [openEdit, setOpenEdit] = useState(false);
	const [selectedRow, setSelectedRow] = useState(null);
	// const [searchText, setSearchText] = useState("");
	const [searchSuggestions, setSearchSuggestions] = useState([]);
	// const [statusFilterSelected, setStatusFilterSelected] = useState("");
	const [categoryList, setCategoryList] = useState([]);
	const [isDateFilterEnabled, setIsDateFilterEnabled] = useState(false);
	const token = JSON.parse(localStorage.getItem("token"));
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const tableColumnWidth = [25, 25, 25, 25];
	const columns = [
		{
			name: "Sub Category",
			field: "name",
			// image: Divider,
			numeric: false,
			sortable: false,
			align: "left",
		},
		{
			name: "Category",
			field: "rate",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
		// {
		//   name: "Count",
		//   field: "count",
		//   // image: Divider,
		//   numeric: true,
		//   sortable: false,
		//   align: "left",
		// },
		{
			name: "Action",
			field: "action",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
	];

	useEffect(() => {
		getCategoryList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getCategoryList = async () => {
		await axiosInstance
			.get("/v1/admin/category?limit=200", config)
			.then((res) => {
				setCategoryList(res?.data?.result?.assets);
			})
			.catch(() => {});
	};

	const handleChangePage = (event, nextPageNo) => {
		setPageNo(nextPageNo);
		window.scrollTo(0, 0);
	};
	return (
		<div className="table_div tableWidth100">
			{showLoader == true ? <Loader /> : null}
			<div className="searchBoxProduct">
				<Button
					variant="outlined"
					onClick={() => {
						setFilterSelected(-1);
						setSearchText("");
						setIsDateFilterEnabled(false);
						setPageNo(1);
					}}
				>
					Reset Filter
				</Button>
				<FormControl variant="outlined" className={classes.height24}>
					<InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
					<Select
						native
						value={filterSelected}
						onChange={(e) => {
							setFilterSelected(e.target.value);
							setPageNo(1);
						}}
						label="Filter"
						inputProps={{
							name: "Filter",
							id: "outlined-age-native-simple",
						}}
						IconComponent={FilterListIcon}
						displayEmpty={true}
					>
						<svg
							className="w-6 h-6"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
								clipRule="evenodd"
							/>
						</svg>
						<optgroup label="Category">
							<option value={-1}>All</option>
							{categoryList?.length > 0 &&
								categoryList?.map((item, index) => {
									return (
										<option key={index} value={item?.id}>
											{item.name}
										</option>
									);
								})}
						</optgroup>
					</Select>
				</FormControl>
				<Autocomplete
					id="search-box-product"
					options={searchSuggestions}
					disableClearable={true}
					clearOnBlur={false}
					getOptionLabel={(option) => {
						return option.name || "";
					}}
					style={{ width: 300 }}
					inputValue={searchText}
					renderInput={(params) => (
						<TextField
							{...params}
							// value={searchText}
							onChange={(e) => {
								setSearchText(e.target.value);
								setPageNo(1);
							}}
							// onKeyDown={(e) => handleSearchBoxKeyDown(e)}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							label="Search Subcategory"
							variant="outlined"
						/>
					)}
				/>
			</div>
			{subCategoryList?.length !== 0 ? (
				<>
					<TableContainer component={Paper}>
						<Table aria-label="collapsible table" className={classes.root}>
							<RenderHeader
								columns={columns}
								onSorting={(field, order) => setSorting({ field, order })}
							/>
							<TableBody style={{ width: "100%" }}>
								{subCategoryList?.map((row, index) => (
									<Row
										aria-label="expand row"
										size="small"
										key={row?.id}
										row={row}
										setSelectedRow={(value) => setSelectedRow(value)}
										selectedRow={selectedRow}
										data={subCategoryList[index]}
										tableColumnWidth={tableColumnWidth}
										setOpenEdit={setOpenEdit}
										pageNo={pageNo}
										setSubCategoryList={setSubCategoryList}
										setTotalCount={setTotalCount}
										// setShowLoader={setShowLoader}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Pagination
						count={totalPages}
						page={pageNo}
						onChange={handleChangePage}
					/>
					{/* <TablePagination
            rowsPerPageOptions={[]}
            // backIconButtonProps={{ disabled: false }}
            component="div"
            count={totalCount}
            rowsPerPage={10}
            page={pageNo}
            onChangePage={handleChangePage}
            // onChange={(e) => handleChangePage(e, page)}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              <>
                <span className="paginationLabel">Showing </span>
                {`${from}-${to}`}
                <span className="paginationLabel"> out of </span>
                {`${count}`}
              </>
            )}
          /> */}
				</>
			) : (
				<div className="noResultFound flexColumnCenter">
					<NoRecordsFound message="No records found." />
				</div>
			)}
			<UiDrawer
				open={openEdit}
				onClose={() => setOpenEdit(false)}
				showLoader={showLoader}
				setShowLoader={(e) => setShowLoader(e)}
				isEdit={true}
				selectedRow={selectedRow}
				setSubCategoryList={setSubCategoryList}
				setTotalCount={setTotalCount}
			/>
		</div>
	);
}
