import {
  Box,
  Anchor,
  Send,
  Users,
  Activity,
  ShoppingBag,
  Globe,
  Database,
  Layers,
  Slash,
  Trello,
  UserPlus,
  UploadCloud,
  DownloadCloud
} from "react-feather";

export const MENUITEMS = [
  // {
  //     menutitle:"General",
  //     menucontent:"Dashboards,Widgets",
  //     Items:[
  //         {
  //             title: 'Dashboard', icon: Home, type: 'sub', active: false, children: [
  //                 { path: `${process.env.PUBLIC_URL}/dashboard/default`, title: 'Default', type: 'link' },
  //                 { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`, title: 'Ecommerce', type: 'link' },
  //             ]
  //         }
  //     ]

  // },
  {
    Items: [
      {
        title: "Asset",
        icon: Box,
        type: "sub",
        active: false,
        path: `${process.env.PUBLIC_URL}/products/products`,
        type: "link",
        permission: {
          view: true,
          add: true,
          edit: true,
          delete: true,
        },
      },
      {
        title: "Airdrop",
        icon: DownloadCloud,
        type: "sub",
        active: false,
        path: `${process.env.PUBLIC_URL}/products/airdrop`,
        type: "link",
        permission: {
          view: true,
          add: true,
          edit: true,
          delete: true,
        },
      },
      {
				title: "Collection",
				icon: Trello,
				type: "sub",
				active: false,
				path: `${process.env.PUBLIC_URL}/collection`,
				type: "link",
				permission: {
					view: true,
					add: true,
					edit: true,
					delete: true,
				},
			},
      {
        title: "Category",
        icon: Layers,
        type: "sub",
        active: false,
        path: `${process.env.PUBLIC_URL}/products/categories`,
        type: "link",
        permission: {
          view: true,
          add: true,
          edit: true,
          delete: true,
        },
      },
      
      {
        title: "User",
        icon: Users,
        type: "sub",
        active: false,
        path: `${process.env.PUBLIC_URL}/users/users`,
        type: "link",
        permission: {
          view: true,
          add: true,
          edit: true,
          delete: true,
        },
      },
      {
        title: "Auction",
        path: `${process.env.PUBLIC_URL}/auction/auction`,
        icon: Activity,
        // type: "sub",
        active: false,
        type: "link",
        permission: {
          view: true,
          add: true,
          edit: true,
          delete: true,
        },
      },
      {
        title: "News",
        path: `${process.env.PUBLIC_URL}/product/news`,
        icon: Send,
        // type: "sub",
        active: false,
        type: "link",
        permission: {
          view: true,
          add: true,
          edit: true,
          delete: true,
        },
      },
      {
        title: "Order",
        path: `${process.env.PUBLIC_URL}/orders/orders`,
        icon: ShoppingBag,
        active: false,
        type: "link",
        permission: {
          view: true,
          add: true,
          edit: true,
          delete: true,
        },
      },
      {
        title: "Transaction History",
        path: `${process.env.PUBLIC_URL}/orders/transactionhistory`,
        icon: Database,
        type: "sub",
        active: false,
        type: "link",
        permission: {
          view: true,
          add: true,
          edit: true,
          delete: true,
        },
      },
      {
        title: "Commission",
        path: `${process.env.PUBLIC_URL}/commission/commission`,
        icon: Globe,
        type: "sub",
        active: false,
        type: "link",
        permission: {
          view: true,
          add: true,
          edit: true,
          delete: true,
        },
      },
      {
        title: "Analytic Dashboard",
        path: `${process.env.PUBLIC_URL}/analytics/dashboard`,
        icon: Anchor,
        type: "sub",
        active: false,
        type: "link",
        permission: {
          view: true,
          add: true,
          edit: true,
          delete: true,
        },
      },
      {
        title: "Add Creator",
        path: `${process.env.PUBLIC_URL}/creators/dashboard`,
        icon: UserPlus,
        type: "sub",
        active: false,
        type: "link",
        permission: {
          view: true,
          add: true,
          edit: true,
          delete: true,
        },
      },
      {
        title: "Report",
        path: `${process.env.PUBLIC_URL}/user/reports`,
        icon: Slash,
        type: "sub",
        active: false,
        type: "link",
        permission: {
          view: true,
          add: true,
          edit: true,
          delete: true,
        },
      },
      {
        title: "Media Upload",
        path: `${process.env.PUBLIC_URL}/Image/uploadImage`,
        icon: UploadCloud,
        type: "sub",
        active: false,
        type: "link",
        permission: {
          view: true,
          add: true,
          edit: true,
          delete: true,
        },
      },
      // {
      //   title: "Sub Admin",
      //   path: `${process.env.PUBLIC_URL}/manage-subadmin/subadmin`,
      //   icon: Anchor,
      //   type: "sub",
      //   active: false,
      //   type: "link",
      //   permission: {
      //     view:true
      //   }
      // },
    ],
  },

  // {
  //   menutitle: "Assets",
  //   menucontent: "Assets ,Category",
  //   Items: [
  //     {
  //       title: "Assets",
  //       icon: Home,
  //       type: "sub",
  //       active: false,
  //       children: [
  //         {
  //           path: `${process.env.PUBLIC_URL}/products/products`,
  //           title: "Assets",
  //           type: "link",
  //         },
  //         {
  //           path: `${process.env.PUBLIC_URL}/products/categories`,
  //           title: "Categories",
  //           type: "link",
  //         },
  //         {
  //           path: `${process.env.PUBLIC_URL}/users/users`,
  //           title: "Users",
  //           type: "link",
  //         },
  //         {
  //           path: `${process.env.PUBLIC_URL}/auction/auction`,
  //           title: "Auction",
  //           type: "link",
  //         },
  //         // {
  //         //   path: `${process.env.PUBLIC_URL}/auction/auction`,
  //         //   title: "Auction",
  //         //   type: "link",
  //         // },
  //         {
  //           path: `${process.env.PUBLIC_URL}/product/news`,
  //           title: "News",
  //           type: "link",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   menutitle: "Order",
  //   menucontent: "Manage Orders",
  //   Items: [
  //     {
  //       title: "Orders",
  //       icon: Anchor,
  //       type: "sub",
  //       active: false,
  //       children: [
  //         {
  //           path: `${process.env.PUBLIC_URL}/orders/orders`,
  //           title: "Orders",
  //           type: "link",
  //         },
  //         {
  //           path: `${process.env.PUBLIC_URL}/orders/transactionhistory`,
  //           title: "Transaction History",
  //           type: "link",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   menutitle: "Commission",
  //   menucontent: "",
  //   Items: [
  //     {
  //       title: "Commission",
  //       icon: Anchor,
  //       type: "sub",
  //       active: false,
  //       children: [
  //         {
  //           path: `${process.env.PUBLIC_URL}/commission/commission`,
  //           title: "Commission",
  //           type: "link",
  //         },
  //         // {
  //         //   path: `${process.env.PUBLIC_URL}/products/categories`,
  //         //   title: "Categories",
  //         //   type: "link",
  //         // },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   menutitle: "",
  //   menucontent: "",
  //   Items: [
  //     {
  //       title: "Dashboard",
  //       icon: Anchor,
  //       type: "sub",
  //       active: false,
  //       children: [
  //         {
  //           path: `${process.env.PUBLIC_URL}/analytics/dashboard`,
  //           title: "Analytics Dashboard",
  //           type: "link",
  //         },
  //         // {
  //         //   path: `${process.env.PUBLIC_URL}/products/categories`,
  //         //   title: "Categories",
  //         //   type: "link",
  //         // },
  //       ],
  //     },
  //   ],
  // },
];
