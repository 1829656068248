import React, { Fragment, useEffect } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TransitionGroup } from "react-transition-group";
import Swal from "sweetalert2";

import SignUp from "./signup/Signup";
import Login from "./Login/Login";
import ProtectedRoute from "../utility/protected-routes/protectedRoutes";
import ConfigDB from "../data/customizer/config";
import Wrapper from "../layout/unAuthWrappers/unAuthWrappers";
import Resetpw from "./Login/Resetpw";
import { routes } from "./../route/index";
// import { classes } from "../data/layouts";
import { axiosInstance } from "../utility/api";
import { SAVE_TOKEN, SAVE_PROFILE_DETAILS } from "../redux/actionTypes";
import { handleAPIError } from "../utility/helper";

const App = ({ children }) => {
  console.warn = () => {};
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  // const defaultLayoutObj = classes.find(
  //   (item) => Object.values(item).pop(1) === "compact-wrapper"
  // );
  // const layout =
  //   localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();
  const [anim, setAnim] = React.useState("");
  // const [count, setCount] = React.useState(0);
  const animation =
    localStorage.getItem("animation") ||
    ConfigDB.data.router_animation ||
    "fade";
  const abortController = new AbortController();
  const token = JSON.parse(localStorage.getItem("token"));
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const userToken = useSelector((state) => state.adminReducer.userToken);

  useEffect(() => {
    if (userToken) {
      getProfileDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  React.useEffect(() => {
    setAnim(animation);
    // let inactivityTime = function () {}
    let time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;

    function logout() {
      dispatch({
        type: SAVE_TOKEN,
        payload: null,
      });
      localStorage.removeItem("token");
      if (location.pathname !== "/")
        Swal.fire("", "Session logged out due to inactivity", "info").then(
          (res) => (window.location.href = "/")
        );
    }

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(logout, 3600000);
      // 1000 milliseconds = 1 second
    }

    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  const getProfileDetails = async () => {
    await axiosInstance
      .get(`/v1/admin/transactions?`, header)
      .then((result) => {
        console.log("I m running");
        // setTransactionHistory(result?.data?.result?.transactions?.rows);
        // setTotalCount(result?.data?.result?.transactions?.count);
        let payload = {
          firstname: "Adm,in Test",
          lastname: "Last",
          email: "99@yopmail.com",
          profilePicture: "https://www.w3schools.com/howto/img_avatar.png",
        };
        dispatch({
          type: SAVE_PROFILE_DETAILS,
          payload: payload,
        });
      })
      .catch((err) => {
        let respErr = handleAPIError(err);
        Swal.fire("Oops!", respErr, "error");
      });
    dispatch({
      type: SAVE_PROFILE_DETAILS,
      payload: null,
    });
  };

  return (
    <Fragment>
      <Switch>
        <TransitionGroup>
          {/* <div className="outer">
              <div className="inner"> */}
          <Route exact path="/" component={Wrapper(Login)} />
          <Route exact path="/sign-in" component={Wrapper(Login)} />
          <Route exact path="/sign-up" component={Wrapper(SignUp)} />
          {/* </div>
            </div> */}
          {/* <Route exact path={`${process.env.PUBLIC_URL}/dashboard/default/${layout}`} render={() => {
              return (<Redirect to={``} />) 
            }} /> */}
          {routes.map(({ path, Component }) => (
            <ProtectedRoute
              key={path}
              exact
              path={`${process.env.PUBLIC_URL}${path}`}
              Component={Component}
            >
              {/* {({ match }) => (
                  <CSSTransition
                    in={match != null}
                    timeout={100}
                    // classNames={anim}
                    unmountOnExit
                  >
                    <div>
                      <Component />
                    </div>
                  </CSSTransition>
                )} */}
            </ProtectedRoute>
          ))}
          <Route path="/forgot-password" component={Wrapper(Resetpw)} />
        </TransitionGroup>
      </Switch>
    </Fragment>
  );
};

export default App;
