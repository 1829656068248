/* eslint-disable eqeqeq */
import React from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";

import { Drawer } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import "./Uidrawer.css";
import Loader from "../common/Loader/Loader";
import AddProductForm from "../products/addProduct";
import { axiosInstance } from "../../utility/api";
import decryptFunction from "../common/decryptURL";
import { camelToLowerCase, objectDeepClone } from "../../utility/helper";

const styles = {
  sideNav: {
    marginTop: "-60px",
    zIndex: 3,
    marginLeft: "0px",
    position: "fixed",
  },
  link: {
    color: "black",
    textDecoration: "none",
  },
  title: {
    width: "90%",
    marginBottom: "20px",
  },
  productCard: {
    boxShadow: "0px 0px 2px #bbb",
    padding: "10px",
    marginBottom: "20px",
  },
  rootDrawer: {
    zIndex: "200 !important",
    width: "54%",
  },
};

// const useStyles = makeStyles({
//   root: {
//     background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
//     borderRadius: 3,
//     border: 0,
//     color: "white",
//     height: 48,
//     padding: "0 30px",
//     boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
//   },
//   label: {
//     textTransform: "capitalize",
//   },
// });

class UiDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpened: false,
      value: "",
      categoryId: "",
      subCategoryId: "",
      saleType: "fixed",
      name: "",
      shortDescription: "",
      catagories: [],
      subCategoryList: [],
      artist: {
        id: "",
        royaltyCommissionPercentage: "",
        royaltyCommissionCapAmount: "",
        primarySalePercentage: "",
      },
      creator: {
        id: "",
        royaltyCommissionPercentage: "",
        royaltyCommissionCapAmount: "",
        primarySalePercentage: "",
      },
      status: "active",
      description: "",
      quantity: 1,
      creatorDescription: "",
      creatorName: "",
      auctionBuyNowPrice: null,
      isBuyNowForAuction: false,
      // saleType: "fixed",
      isDownloadable: "false",
      auction: {
        auctionType: "normal",
        openingPrice: null,
        lowestPriceToAccept: null,
        bidIncrement: null,
        noOfBids: null,
        startDate: new Date(moment.utc().format()),
        endingDate: new Date(moment.utc().add(1, "days").format()),
        status: "active",
      },
      isMediaChange: false,
      ipfsAudioUrlPath: "",
      ipfsShortVideoUrlPath: "",
      ipfsLongVideoUrlPath: "",
      ipfsImageUrlPath: "",
      ipfsAnimationUrlPath: "",
      // isMediaChange: false,
      ipfsMetaUrl: "",
      royaltyCommission: 0,
      mediaType: "",
      multiFileStore: {
        mainFile: [],
        supplimentary1: [],
        supplimentary2: [],
        supplimentary3: [],
        supplimentary4: [],
      },
      isDownloadable: true,
      isCarrosel: "false",
      isEditionMintToAdmin: false,
      descriptionMint: "",

      error: {
        status: false,
        message: "",
        location: "",
      },
      showLoader: {
        open: false,
        message: "",
      },
      setOpenSnackBar: false,
      isEdit: props.isEdits,
      isMainFileVideo: false,
      isMainFileAudio: false,
      isMainFile3D: false,
      isSupplimentary13dFile: false,
      disableSupportingDocs: true,
      isSuplimentaryFile1Video: false,
      isSuplimentaryFile2Video: false,
      isSuplimentaryFile3Video: false,
      isSuplimentaryFile4Video: false,
      isResellable : true,
      // isMainFileVideo: false,
      is3dFile: false,
      ipfs3dSupplimentary1Id: false,
      airdropStartDate: this.state?.saleType == 'free' ? new Date(moment.utc().format()) : null,
      airdropEndDate : this.state?.saleType == 'free' ? new Date(moment.utc().add(1, "days").format()) : null,
    };
  }

  config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  // classes = useStyles();

  componentDidMount() {
    axiosInstance
      .get("/v1/admin/category?page=1&limit=200", this.config)
      .then((res) => {
        this.setState({ catagories: res?.data?.result?.assets });
      })
      .catch(() => {});

    let tempMultiFiles = {};
    if (this.props.data) {      
      if (this.props?.data?.files?.length > 0) {
        let fileIndex = 1;
        let result = this.props?.data?.files?.map((file) => {
          if (file.isIpfs == true) {
            tempMultiFiles.mainFile = [
              file?.id,
              file?.name,
              decryptFunction(file?.url),
              file?.type,
              "mainFile",
            ];
          } else {
            tempMultiFiles[`supplimentary${fileIndex}`] = [
              file?.id,
              file?.name,
              file?.url,
              file?.type,
              "supplimentaryFile",
            ];
            fileIndex++;
          }
        });
      }
      let creator1_1 = null,
        creator1_2 = null;
      if (this.props?.data?.UserAssetCommission?.length) {
        creator1_1 = this.props.data.UserAssetCommission.find(
          (el) => el?.creatorType === "creator" && el?.type === "primary"
        );
        creator1_2 = this.props.data.UserAssetCommission.find(
          (el) => el?.creatorType === "creator" && el?.type === "royalty"
        );
      }

      let artist1_1,
        artist1_2 = null;
      if (this.props?.data?.UserAssetCommission?.length) {
        artist1_1 = this.props.data.UserAssetCommission.find(
          (el) => el?.creatorType === "artist" && el?.type === "primary"
        );
        artist1_2 = this.props.data.UserAssetCommission.find(
          (el) => el?.creatorType === "artist" && el?.type === "royalty"
        );
      }     
      console.log(this.props.data, 'first')
      this.setState({
        ...this.props.data,    
        artist: {
          id: artist1_1?.userId,
          royaltyCommissionPercentage: artist1_2?.percentage,
          royaltyCommissionCapAmount: artist1_2?.capAmount,
          primarySalePercentage: artist1_1?.percentage,
        },
        creator: {
          id: creator1_1?.userId,
          royaltyCommissionPercentage: creator1_2?.percentage,
          royaltyCommissionCapAmount: creator1_2?.capAmount,
          primarySalePercentage: creator1_1?.percentage,
        },
        categoryId: this.props.data?.category?.id,
        ipfsAudioUrlPath: this.props.data?.ipfsAudioUrl,
        audioUrl: this.props.data?.ipfsAudioUrl,
        ipfsShortVideoUrlPath: this.props.data?.ipfsShortVideoUrl,
        shortVideoUrl: this.props.data?.ipfsShortVideoUrl,
        ipfsLongVideoUrlPath: this.props.data?.ipfsLongVideoUrl,
        ipfsImageUrlPath: this.props.data?.ipfsPreviewImageUrl,
        imageUrl: this.props.data?.ipfsImageUrl,
        ipfsAnimationUrlPath: this.props.data?.ipfsAnimationUrl,
        ipfsAudioThumbnailPath: this.props?.data?.ipfsAudioThumbnail,
        ipfsMetaUrl: this.props.data?.ipfsMetaUrl,
        royaltyCommission: this.props.data?.royaltyCommission,
        isEdit: true,
        auction: this.props.data?.auctions?.[0],
        auctions: this.props.data?.auctions,
        isCarrosel: this?.props?.data?.isCarrosel?.toString(),
        multiFileStore: tempMultiFiles,
        disableSupportingDocs: false,
        ipfsAudioThumbnailPath: this.props?.data?.audioThumbnail
          ? this.props?.data?.audioThumbnail
          : null,
        isMainFileVideo: this.props.data?.mediaType === "video" ? true : false,
        isMainFileAudio: this.props.data?.mediaType === "audio" ? true : false,
        isSuplimentaryFile1Video:
          this?.props?.data?.files?.[1]?.type === "video" ? true : false,
        isSuplimentaryFile2Video:
          this?.props?.data?.files?.[2]?.type === "video" ? true : false,
        isSuplimentaryFile3Video:
          this?.props?.data?.files?.[3]?.type === "video" ? true : false,
        isSuplimentaryFile4Video:
          this?.props?.data?.files?.[4]?.type === "video" ? true : false,
        is3dFile:
          this?.props?.data?.files?.[0]?.type === "3D Model" ? true : false,
        ipfs3dId:
          this?.props?.data?.files?.[0]?.type === "3D Model"
            ? this?.props?.data?.files?.[0]?.previewUrl
            : null,
        ipfs3dUrl:
          this?.props?.data?.files?.[0]?.type === "3D Model"
            ? this?.props?.data?.files?.[0]?.previewUrl
            : null,

        isSuplimentaryFile13d:
          this?.props?.data?.files?.[1]?.type === "3D Model" ? true : false,
        ipfs3dSupplimentary1Id:
          this?.props?.data?.files?.[1]?.type === "3D Model"
            ? this?.props?.data?.files?.[1]?.previewUrl
            : null,
        saleType : this.props?.saleType,
      
        // auction: { ...this.state.auction, id: this.props.data.auctions[0]?.id },
      });
    } else {
      this.setState({
        ...this.state,
        isEdit: false,
        saleType: this.props?.saleType
      });
    }
  
  }

  handleCreator = (arr) => {
    let finalArr = [];
    arr.forEach((el) => {
      let obj = {
        id: el?.id,
        royaltyCommissionPercentage: el?.royaltyCommissionPercentage,
        royaltyCommissionCapAmount: el?.royaltyCommissionCapAmount,
        primarySalePercentage: el?.primarySalePercentage,
      };
      finalArr.push(obj);
    });
    this.setState({
      ...this.state,
      coCreators: finalArr,
    });
  };

  resetCreatorOrArtist = (type) => {
    this.setState({
      ...this.state,
      [type]: {
        id: "",
        primarySalePercentage: "",
        royaltyCommissionCapAmount: "",
        royaltyCommissionPercentage: "",
      },
    });
  };

  handleChange = (event) => {
    const { name, value } = event?.target;
    console.log("name, value", name, value);
    const auctionParamaters = [
      "productCondition",
      "auctionType",
      "openingPrice",
      "lowestPriceToAccept",
      "bidIncrement",
      "startDate",
      "endingDate",
    ];
    if (auctionParamaters.find((item) => item == event.target.name)) {
      this.setState({
        ...this.state,
        auction: {
          ...this.state.auction,
          [event.target.name]: event.target.value,
        },
      });
    } else if (name === "creatorId" || name === "artistId") {
      let obj = objectDeepClone(this.state);
      if (name === "creatorId") obj.creator.id = value;
      if (name === "artistId") obj.artist.id = value;
      this.setState(obj);
    } else if (name.includes("artist.") || name.includes("creator.")) {
      console.log(
        "name.includes()",
        value,
        value?.length,
        value.includes("."),
        value.indexOf(".")
      );
      if (
        name.includes("royaltyCommissionPercentage") ||
        name.includes("primarySalePercentage")
      ) {
        if (value?.length >= 7) return;
        if (value?.length === 4 && !value.includes(".")) return;
      }
      if (name.includes("royaltyCommissionCapAmount")) {
        if (value?.length >= 9) return;
        if (value?.length === 6 && !value.includes(".")) return;
      }
      const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
      let num = value.match(regex)[0];
      let obj = objectDeepClone(this.state);
      let key1 = name.split(".")[0];
      let key2 = name.split(".")[1];
      // if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
      console.log({ num });
      if (num) {
        obj[key1][key2] = Number(num);
        this.setState(obj);
      } else {
        obj[key1][key2] = "";
        this.setState(obj);
      }
    } else {
      if (event.target.name == "saleType" && event.target.value == "fixed") {
        this.setState({
          ...this.state,
          saleType: "fixed",
          currentEdition: null,
          auction: null,
        });
      } else if (
        event.target.name == "saleType" &&
        event.target.value == "auction"
      ) {
        this.setState({
          ...this.state,
          saleType: "auction",
          regularPrice: null,
          quantity: 1,
        });
      } else if (event.target.name === "isEditionMintToAdmin") {
        console.log("mint admin 12", event.target.name, event.target.value);
        this.setState({
          ...this.state,
          [event.target.name]: event.target.value === "true" ? true : false,
        });
      } else if (
        event.target.name === "name" ||
        event.target.name === "creatorName"
      ) {
        const { name, value } = event.target;
        if (value.length <= 90) {
          this.setState({
            ...this.state,
            [name]: value,
          });
        }
      } else if (event.target.name === "descriptionMint") {
        const { name, value } = event.target;
        if (value.length <= 1000) {
          this.setState({
            ...this.state,
            [name]: value,
          });
        }
      }else if(event.target.name === 'isResellable'){
        this.setState({
          ...this.state,
          [event.target.name]: event.target.checked,
        });
      } else {
        this.setState({
          ...this.state,
          [event.target.name]: event.target.value,
        });
      }
    }
  };

  handleDeleteAssetFile = async (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };
    await axiosInstance
      .delete(`/v1/admin/asset/files/remove/${id}`, config)
      .then((res) => {
        Swal.fire("Success!", res?.data?.message, "success");
      })
      .then(() => {})
      .catch((err) => {
        console.log("rrr eror", err?.response.data?.message);
        Swal.fire("Error!", err?.response?.data?.message, "error");
      });
  };

  handleDeleteMultipleAssetFiles = (idList) => {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
      };
      axiosInstance
        .delete(`/v1/admin/asset/files/all?fileId=${idList.toString()}`, config)
        .then((res) => {
          resolve(res);
          // Swal.fire("Success!", res?.data?.message, "success");
        })
        .catch((err) => {
          reject(err);
          console.log("rrr eror", err?.response.data?.message);
          // Swal.fire("Error!", err?.response?.data?.message, "error");
        });
    });
  };

  assetIds = (obj) => {
    let filteredAssetID = [];
    let createdArr = Object.values(obj);
    for (let i = 0; i < createdArr.length; i++) {
      if (createdArr[i].length > 0) {
        filteredAssetID.push(createdArr[i][0]);
      }
    }
    return filteredAssetID;
  };

  assetIdsWithoutMainFile = (obj) => {
    let filteredAssetID = [];
    let createdArr = Object.values(obj);
    for (let i = 0; i < createdArr.length; i++) {
      if (createdArr[i].length > 0) {
        console.log(createdArr[i][4]);
        if (createdArr[i][4] !== "mainFile") {
          filteredAssetID.push(createdArr[i][0]);
        }
      }
    }
    return filteredAssetID;
  };

  // To handle delete all file upload. - Upload section
  handleDeleteAll = async () => {
    // this.state.isEdit
    let fileIds = [];
    // if (this.state.isEdit == true) {
    //   fileIds = this.assetIdsWithoutMainFile(this.state.multiFileStore);
    // } else {
      fileIds = this.assetIds(this.state.multiFileStore);
    // }

    if (fileIds?.length > 0) {
      // await this.handleDeleteMultipleAssetFiles(fileIds);
      this.handleDeleteMultipleAssetFiles(fileIds)
        .then((res) => {
          let multiFileStore = {
            ...this.state.multiFileStore,
            mainFile: [],
            supplimentary1: [],
            supplimentary2: [],
            supplimentary3: [],
            supplimentary4: [],
          };

          // not to delete audio thumbnail while editing
          // if (
          //   this.state?.isEdit == true &&
          //   this.state?.multiFileStore?.supplimentary1?.[3] == "audio"
          // ) {
          //   multiFileStore = {
          //     ...this.state.multiFileStore,
          //     supplimentary2: [],
          //     supplimentary3: [],
          //     supplimentary4: [],
          //   };
          // } else if (this.state?.isEdit == true) {
          //   multiFileStore = {
          //     ...this.state.multiFileStore,
          //     supplimentary1: [],
          //     supplimentary2: [],
          //     supplimentary3: [],
          //     supplimentary4: [],
          //   };
          // }
          
          this.setState({
            ...this.state,
            multiFileStore: multiFileStore,
            isMainFileVideo:
              this.state?.isEdit == true &&
              this.state?.multiFileStore?.mainFile?.[3] == "video"
                ? true
                : false,
            isMainFileAudio: false,
            isMainFile3D: false,
            ipfsImageUrlPath: this.state.isEdit
              ? this?.state?.ipfsImageUrlPath
              : "",
            ipfsShortVideoUrlPath: this.state.isEdit
              ? this?.state?.ipfsShortVideoUrlPath
              : "",
            ipfsLongVideoUrlPath: this.state.isEdit
              ? this?.state?.ipfsLongVideoUrlPath
              : "",
            ipfsAudioUrlPath: this.state.isEdit
              ? this?.state?.ipfsAudioUrlPath
              : "",
            ipfs3dUrl: this.state.isEdit ? this?.state?.ipfs3dUrl : "",
            is3dFile: this?.state?.isEdit ? this?.state?.is3dFile : "",
            isSuplimentaryFile13d: false,
            isSuplimentaryFile1Video: false,
            isSuplimentaryFile2Video: false,
            isSuplimentaryFile3Video: false,
            isSuplimentaryFile4Video: false,
          });
          Swal.fire("Success!", res?.data?.message, "success");
        })
        .catch((err) => {
          console.log("Promise Reject", err);
          Swal.fire("Success!", "Error while deleting files.", "error");
        });
    }
  };

  // Remove file upload section
  handleRemoveFile = (type) => {
    let id = null;
    switch (type) {
      case "mainFile":
        id = this?.state?.multiFileStore?.mainFile[0];
        if (id) {
          this.handleDeleteAssetFile(id);
        }
        this.setState({
          ...this.state,
          multiFileStore: {
            ...this.state.multiFileStore,
            mainFile: [],
          },
          isMainFileVideo: false,
          isMainFileAudio: false,
          isMainFile3D: false,
          ipfsImageUrlPath: "",
          ipfsShortVideoUrlPath: "",
          ipfsLongVideoUrlPath: "",
          ipfsAudioUrlPath: "",
          ipfs3dUrl: "",
          is3dFile: "",
          disableSupportingDocs: this?.state?.multiFileStore?.supplimentary1?.[0]
            ? false
            : true,
        });
        break;
      case "supplimentary1":
        id = this?.state?.multiFileStore?.supplimentary1[0];
        if (id) {
          this.handleDeleteAssetFile(id);
        }
        this.setState({
          ...this.state,
          isSuplimentaryFile1Video: false,
          isSuplimentaryFile13d: false,
          ipfs3dSupplimentary1Id: false,
          multiFileStore: {
            ...this.state.multiFileStore,
            supplimentary1: [],
          },
        });
        break;
      case "supplimentary2":
        id = this?.state?.multiFileStore?.supplimentary2[0];
        if (id) {
          this.handleDeleteAssetFile(id);
        }
        this.setState({
          ...this.state,
          isSuplimentaryFile2Video: false,
          isSuplimentaryFile23d: false,
          multiFileStore: {
            ...this.state.multiFileStore,
            supplimentary2: [],
          },
        });
        break;
      case "supplimentary3":
        id = this?.state?.multiFileStore?.supplimentary3[0];
        if (id) {
          this.handleDeleteAssetFile(id);
        }
        this.setState({
          ...this.state,
          isSuplimentaryFile3Video: false,
          isSuplimentaryFile33d: false,
          multiFileStore: {
            ...this.state.multiFileStore,
            supplimentary3: [],
          },
        });
        break;
      case "supplimentary4":
        id = this?.state?.multiFileStore?.supplimentary4[0];
        if (id) {
          this.handleDeleteAssetFile(id);
        }
        this.setState({
          ...this.state,
          isSuplimentaryFile4Video: false,
          isSuplimentaryFile33d: false,
          multiFileStore: {
            ...this.state.multiFileStore,
            supplimentary4: [],
          },
        });
        break;

      default:
        break;
    }
  };

  toggleDrawerStatus = () => {
    this.setState({
      isDrawerOpened: true,
    });
  };

  closeDrawer = () => {
    this.setState({
      isDrawerOpened: false,
    });
  };

  errorToast = (msg) => {
    Swal.fire("Error!", msg, "error");
  };

  setShowLoader = (value, message) => {
    this.setState({
      ...this.state,
      showLoader: { open: value, message: message },
    });
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    this.setState({
      error: { status: false, message: "" },
    });
    if (this.state?.name?.trim() == "") {
      Swal.fire("Error!", "Please provide a valid name", "error");
      return false;
    }
    if (
      this.state?.description == "<p><br></p>" ||
      this.state?.description?.trim() === ""
    ) {
      Swal.fire("Error!", "Please provide a valid description", "error");
      return false;
    }
    if (
      this.state?.isMainFileAudio &&
      this.state?.multiFileStore?.supplimentary1?.length == 0
    ) {
      Swal.fire("Error!", "Thumbnail for the main audio is needed", "error");
      return false;
    }
    if (this?.state?.multiFileStore?.mainFile?.length === 0) {
      Swal.fire("Error!", "Please upload main file", "error");
      return false;
    }

    if (!this.state.creator?.id) {
      this.errorToast("Creator Name is required");
      return false;
    }
    if (!this.state.creator?.royaltyCommissionPercentage) {
      this.errorToast("Creator Commission Percentage is required");
      return false;
    }
    if (!this.state.creator?.primarySalePercentage && this.state.saleType !== 'free') {
      this.errorToast("Creator Primary Sale Percentage is required");
      return false;
    }
    if (!this.state.creator?.royaltyCommissionCapAmount && this.state.saleType !== 'free') {
      this.errorToast("Creator Commission Cap Amount is required");
      return false;
    }
    let ccError = false;
    let rcp = Number(this.state.creator?.royaltyCommissionPercentage);
    let psp = Number(this.state.creator?.primarySalePercentage);
    if (this.state.artist?.royaltyCommissionPercentage)
      rcp = rcp + Number(this.state.artist?.royaltyCommissionPercentage);
    if (this.state.artist?.primarySalePercentage)
      psp = psp + Number(this.state.artist?.primarySalePercentage);
    if (this.state.coCreators) {
      this.state.coCreators.forEach((el) => {
        for (const [key, value] of Object.entries(el)) {
          console.log("value", value, key);
          if (!value && !ccError) ccError = key;
        }
        if (Number(el?.royaltyCommissionPercentage))
          rcp = rcp + Number(el?.royaltyCommissionPercentage);
        if (Number(el?.primarySalePercentage))
          psp = psp + Number(el?.primarySalePercentage);
      });
    }

    if (rcp !== 100) {
      this.errorToast(
        "Creator, Artist and CoCreator royalty commission percentage should be 100% in total"
      );
      return false;
    }
    if (psp !== 100 && this.state.saleType !== 'free') {
      this.errorToast(
        "Creator, Artist and CoCreator primary sale percentage should be 100% in total"
      );
      return false;
    }
    if (ccError) {
      this.errorToast(
        `CoCreator ${
          ccError === "id" ? "Name" : camelToLowerCase(ccError)
        } cannot be blank else delete co-creator by clicking on cancel (x) button`
      );
      return false;
    }

    if (
      this.state.saleType == "auction" &&
      moment(this?.state?.auction.startDate) >
        moment(this?.state?.auction.endingDate)
    ) {
      Swal.fire("Error!", "Start date should lessthan end date.", "error");
      return false;
    } else if (
      this.state.saleType == "auction" &&
      moment(this?.state?.auction.startDate).format() < moment().format()
    ) {
      Swal.fire(
        "Error!",
        "Start date should greater than current time.",
        "error"
      );
      return false;
    }
    if (
      this.state.saleType == 'free'&&
      moment(this?.state?.airdropStartDate) >
        moment(this?.state?.airdropEndDate)
    ) {
      Swal.fire("Error!", "Start date should lessthan end date.", "error");
      return false;
    } else if (
      this.state.saleType == "free" &&
      !(
        moment(this.state?.airdropStartDate).format() < moment().format() &&
        this.state.isEdit == true
      ) &&
      moment(this?.state?.airdropStartDate).format() < moment().format()
    ) {
      Swal.fire(
        "Error!",
        "Start date should greater than current time.",
        "error"
      );
      return false;
    }


    if (
      this.state.saleType == "auction" &&
      parseInt(this.state?.currentEdition) > parseInt(this.state?.totalEdition)
    ) {
      Swal.fire(
        "Error!",
        "Current edition should be lessthan total edition.",
        "error"
      );
      return false;
    }

    if (
      (this.state.royaltyCommission < 0 &&
        this.state.royaltyCommission != "" &&
        this.state.royaltyCommission != null) ||
      (this.state.royaltyCommission > 20 &&
        this.state.royaltyCommission != "" &&
        this.state.royaltyCommission != null)
    ) {
      Swal.fire(
        "Error!",
        "Royality commission should be between 0% and 20%.",
        "error"
      );
      return false;
    }

    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter((key) => predicate(obj[key]))
        .reduce((res, key) => ((res[key] = obj[key]), res), {});

    let tempFileIds = [];
    let tempMulti = this.state.multiFileStore;

    console.log("temp", tempMulti);

    for (const [key, value] of Object.entries(tempMulti)) {
      console.log(`${value[0]}`);
      if (value[0]) {
        tempFileIds.push(value[0]);
      }
    }

    this.setShowLoader(true, "Please Wait.");

    const data = { ...this.state };
    data.fileId = tempFileIds;
    delete data.isDrawerOpened;
    delete data.showLoader;
    delete data.catagories;
    delete data.subCategoryList;
    // if (data.status === "active") data.status = "active";
    // else data.status = "inactive";
    // if (data.pactive === "yes") data.status = "active";
    // else data.status = "inactive";
    delete data.pactive;
    delete data.value;
    delete data.error;
    delete data.setOpenSnackBar;
    delete data.isEdit;
    delete data.ipfsImageUrl;
    delete data.ipfsAudioUrlPath;
    delete data.ipfsLongUrlPath;
    delete data.ipfsShortVideoUrlPath;
    delete data.ipfsLongVideoUrlPath;
    delete data.ipfsAnimationUrlPath;
    delete data.ipfsAudioThumbnailPath;
    delete data.ipfsImageUrlPath;
    delete data.selectedVideoFileType;
    delete data.selectedAudioType;
    delete data.isMediaChange;
    delete data.multiFileStore;
    delete data.subCategory;
    delete data.ipfsMetaUrl;
    delete data.shortVideoUrl;
    delete data.longVideoUrl;
    delete data.longVideoUrl;
    delete data.audioUrl;
    delete data.audioThumbnail;
    delete data.ipfsVideoVideoUrlPath;
    delete data.isSuplimentaryFile1Video;
    delete data.isSuplimentaryFile2Video;
    delete data.isSuplimentaryFile3Video;
    delete data.isSuplimentaryFile4Video;
    delete data.isMainFileVideo;
    delete data.isMainFileAudio;
    delete data.isMainFile3D;
    delete data.is3dFile;
    delete data.disableSupportingDocs;
    delete data.currency;
    delete data.animationUrl;
    delete data.soldEdition;
    delete data.assetState;
    delete data.parentId;
    delete data.mediaPreviewUrl;
    delete data.mediaType;
    delete data.shortDescription;
    delete data.creatorDescription;


    if (data.saleType === "auction") {
      data.currentEdition = 1;
      data.totalEdition = 1;
    }
    if (this.state.audioUrl) {
      delete data.ipfsImageUrl;
      delete data.imageUrl;
    }
    if (data?.audioUrl?.includes("https")) {
      delete data.audioUrl;
    }
    // if (this?.data?.saleType == "auction") {
    //   delete data.isCarrosel;
    // }
    if (!data?.artist?.id) delete data?.artist?.id;
    if (!data?.artist?.royaltyCommissionPercentage)
      delete data?.artist?.royaltyCommissionPercentage;
    if (!data?.artist?.royaltyCommissionCapAmount)
      delete data?.artist?.royaltyCommissionCapAmount;
    if (!data?.artist?.primarySalePercentage)
      delete data?.artist?.primarySalePercentage;

    if (!this.props.data) {
      console.log(data, 'dataaa')
      if(this.state.saleType == 'free') {
        axiosInstance
        .post("/v1/admin/createAirDropAsset", data, this.config)
        .then((res) => {
          this.setShowLoader(false, "");
          this.setState({
            showLoader: false,
            isDrawerOpened: false,
            value: "",
            categoryId: "",
            subCategoryId: "",
            saleType: "",
            name: "",
            // animationUrl: "",
            // shortVideoUrl: "",
            // ipfsLongVideoUrl: "",
            // audioUrl: "",
            // catagories: [],
            description: "",
            quantity: 1,
            imageUrl: "",
            ipfsAudioUrlPath: "",
            ipfsShortVideoUrlPath: "",
            ipfsLongVideoUrlPath: "",
            ipfsImageUrlPath: "",
            ipfsAudioThumbnailPath: "",
            ipfsAnimationUrlPath: "",
            regularPrice: 0,
            creatorName: "",
            creatorDescription: "",
            royaltyCommission: "",
            multiFileStore: {
              main: [],
              supplimentary1: [],
              supplimentary2: [],
              supplimentary3: [],
              supplimentary4: [],
            },
            error: {
              status: false,
              message: "",
            },
            selectedVideoFileType: null,
            selectedAudioType: null,
          });
          this.props.onClose();
          // this.props.toggleAction();
          window.location.reload(true);
          Swal.fire("Success!", res?.data?.message, "success");
        })
        .catch((err) => {
          this.setShowLoader(false, "");
          if (err.response?.data?.errorType == "duplicated") {
            Swal.fire("Error!", err?.response?.data?.message, "error");
          } else {
            if (
              err.response &&
              err.response.data.errors &&
              err.response.data.errors.length > 1
            ) {
              Swal.fire({
                title: "Oops!",
                text: err?.response?.data?.errors,
                icon: "error",
              });
            } else {
              Swal.fire("Oops!", err?.response?.data?.message, "error");
            }
          }
        });
      }
      else {
        axiosInstance
        .post("/v1/admin/asset", data, this.config)
        .then((res) => {
          this.setShowLoader(false, "");
          this.setState({
            showLoader: false,
            isDrawerOpened: false,
            value: "",
            categoryId: "",
            subCategoryId: "",
            saleType: "",
            name: "",
            // animationUrl: "",
            // shortVideoUrl: "",
            // ipfsLongVideoUrl: "",
            // audioUrl: "",
            // catagories: [],
            description: "",
            quantity: 1,
            imageUrl: "",
            ipfsAudioUrlPath: "",
            ipfsShortVideoUrlPath: "",
            ipfsLongVideoUrlPath: "",
            ipfsImageUrlPath: "",
            ipfsAudioThumbnailPath: "",
            ipfsAnimationUrlPath: "",
            regularPrice: 0,
            creatorName: "",
            creatorDescription: "",
            royaltyCommission: "",
            multiFileStore: {
              main: [],
              supplimentary1: [],
              supplimentary2: [],
              supplimentary3: [],
              supplimentary4: [],
            },
            error: {
              status: false,
              message: "",
            },
            selectedVideoFileType: null,
            selectedAudioType: null,
          });
          this.props.onClose();
          // this.props.toggleAction();
          window.location.reload(true);
          Swal.fire("Success!", res?.data?.message, "success");
        })
        .catch((err) => {
          this.setShowLoader(false, "");
          if (err.response?.data?.errorType == "duplicated") {
            Swal.fire("Error!", err?.response?.data?.message, "error");
          } else {
            if (
              err.response &&
              err.response.data.errors &&
              err.response.data.errors.length > 1
            ) {
              Swal.fire({
                title: "Oops!",
                text: err?.response?.data?.errors,
                icon: "error",
              });
            } else {
              Swal.fire("Oops!", err?.response?.data?.message, "error");
            }
          }
        });
      }
     
    } else {
      this.handleEditForm(e);
    }
  };

  handleEditForm = (e) => {
    e.preventDefault();
    this.setState({
      error: { status: false, message: "" },
    });

    // if (this.state.imageUrl == "") {
    //   this.setState({
    //     error: { status: true, message: "Please select a image." },
    //   });
    // }
console.log("e.target",e.target.value)


    // if (
    //   this.state?.availableEdition === 1 &&
    //   this.state?.quantity > 0 &&
    //   this.state?.saleType == "fixed"
    // ) {
    //   Swal.fire(
    //     "Error!",
    //     "Invalid Stock count, Remaining Edition is 0.",
    //     "error"
    //   );
    //   this.setShowLoader(false, "");
    //   return false;
    // }
console.log("avail edition",  this.state?.availableEdition)
    if (
      this.state?.quantity >  this.state?.availableEdition&&
      this.state?.saleType == "fixed"
    ) {
      Swal.fire(
        "Error!",
        `Invalid Stock count, Remaining Edition is ${this?.state?.availableEdition}`,
        "error"
      );
      this.setShowLoader(false, "");
      return false;
    }

    const data = { ...this.state };
    let tempFileIds = [];
    let tempMulti = this.state.multiFileStore;

    for (const [key, value] of Object.entries(tempMulti)) {
      console.log(`${value[0]}`);
      if (value[0]) {
        tempFileIds.push(value[0]);
      }
    }

    data.fileId = tempFileIds;

    if (data.shortVideoUrl) {
      data.imageUrl = null;
    }
    if (data?.audioUrl?.includes("https")) {
      delete data.audioUrl;
    }
    this.setShowLoader(true, "Please Wait.");

    // if(this.props.data){

    // }

    // if(data.img){
    //   data.imageUrl = null;
    // }
    // if(data.shortVideoUrl){
    //   data.imageUrl = null;
    // }
    delete data.isDrawerOpened;
    delete data.catagories;
    delete data.subCategoryList;
    // if (data.pactive === "yes") data.status = "active";
    // else data.status = "not active";
    delete data.pactive;
    delete data.value;
    delete data.error;
    delete data.showLoader;
    delete data.setOpenSnackBar;
    delete data.shortDescription;
    delete data.creatorDescription;
    delete data.isEdit;
    delete data.selectedVideoFileType;
    delete data.selectedAudioType;
    delete data.ipfsAudioUrlPath;
    delete data.ipfsShortVideoUrlPath;
    delete data.ipfsImageUrl;
    delete data.ipfsLongVideoUrl;
    delete data.ipfsAudioUrl;
    delete data.ipfsShortVideoUrl;
    delete data.ipfsAnimationUrl;
    if (!data.mediaType) {
      delete data.mediaType;
    }
    if (data.saleType == "fixed") {
      delete data.auction;
    }
    if (data.saleType === "auction") {
      data.currentEdition = 1;
      data.totalEdition = 1;
    }
    // if (data?.saleType == "auction") {
    //   delete data.isCarrosel;
    // }
    delete data.updatedAt;
    delete data.tokenId;
    delete data.isDeleted;
    delete data.ipfsLongVideoUrlPath;
    delete data.ipfsVideoVideoUrlPath;
    delete data.ipfsAnimationUrlPath;
    delete data.ipfsImageUrlPath;
    delete data.ipfsAudioThumbnailPath;
    delete data.id;
    delete data.createdAt;
    delete data.category;
    delete data.auctions;
    delete data.attributes;
    delete data.multiFileStore;
    delete data.subCategory;
    delete data.shortVideoUrl;
    delete data.longVideoUrl;
    delete data.longVideoUrl;
    delete data.audioUrl;
    delete data.audioThumbnail;
    delete data.ipfsVideoVideoUrlPath;
    delete data.currentEdition;
    delete data.files;
    delete data.imageUrl;
    delete data.ipfsPreviewImageUrl;
    delete data.purchasedBy;
    delete data.purchaseCount;
    delete data.isOnSell;
    delete data.isOnSell;
    delete data.unitAvailableSale;
    delete data.isSuplimentaryFile1Video;
    delete data.isSuplimentaryFile2Video;
    delete data.isSuplimentaryFile3Video;
    delete data.isSuplimentaryFile4Video;
    delete data.isMainFileVideo;
    delete data.isMainFileAudio;
    delete data.isMainFile3D;
    delete data.is3dFile;
    delete data.disableSupportingDocs;
    delete data.currency;
    delete data.animationUrl;
    delete data.soldEdition;
    delete data.assetState;
    delete data.parentId;
    delete data.mediaPreviewUrl;
    // delete data.purchasedBy;
    // delete data.files;
    // delete data.ipfsPreviewImageUrl;
    delete data.isBuyNowForAuction;
    // delete data.isOnSell;
    // delete data.longVideoUrl;
    // delete data.shortVideoUrl;
    // delete data.imageUrl;

    axiosInstance
      .patch(`/v1/admin/asset/${this.state.id}`, data, this.config)
      .then((res) => {
        this.setShowLoader(false, "");
        this.setState({
          ...this.state,
          isMediaChange: false,
          mainFile: {
            main: [],
            supplimentary1: [],
            supplimentary2: [],
            supplimentary3: [],
            supplimentary4: [],
          },
        });
        Swal.fire("Success!", res?.data?.message, "success");
      })
      .then(() => {
        this.props.onClose();
        window.location.reload(true);
      })
      .catch((err) => {
        this.setShowLoader(false, "");
        if (err?.response?.data?.errors?.status == 401) {
          Swal.fire("Error!", err?.response?.data?.errors?.message, "error");
        } else {
          console.log("Show me the error  : ", err.response);
          Swal.fire("Oops!", err?.response?.data?.errors?.message, "error");
        }
      });
  };

  handleMultiFileUpload = (type) => {
    let clickArea = "";
    if (type == "main_file") {
      clickArea = document.getElementById("main_file");
    } else if (type == "supporting_file1") {
      clickArea = document.getElementById("supporting_file1");
    } else if (type == "supporting_file2") {
      clickArea = document.getElementById("supporting_file2");
    } else if (type == "supporting_file3") {
      clickArea = document.getElementById("supporting_file3");
    } else if (type == "supporting_file4") {
      clickArea = document.getElementById("supporting_file4");
    }
    if (clickArea) {
      clickArea.click();
    }
  };

  loadfile = (e) => {
    if (e.target.files[0]) {
      const imageMimeType = ["image/png", "image/jpeg", "image/gif"];
      const videoMimeType = [
        "video/mp4",
        "video/mpeg",
        "video/x-msvideo",
        "video/webm",
      ];
      const audioMimeType = [
        "audio/aac",
        "audio/mpeg",
        "audio/ogg",
        "audio/wav",
        "audio/x-flac",
        "audio/flac",
      ];
      const supportingFiles = [
        "supporting_file1",
        "supporting_file2",
        "supporting_file3",
        "supporting_file4",
      ];

      //Validation for allowed files
      if (
        ![...imageMimeType, ...videoMimeType, ...audioMimeType].includes(
          e.target.files[0].type
        ) &&
        e.target.files[0].name.substr(e.target.files[0].name.length - 3) !=
          "glb" &&
        e.target.files[0].name.substr(e.target.files[0].name.length - 4) !=
          "gltf"
      ) {
        this.setState({
          error: {
            status: true,
            message: "Please upload a valid file.",
            location: "uploadFilePage",
          },
        });
        return false;
      }

      if (
        supportingFiles.includes(e.target.id) &&
        audioMimeType.includes(e.target.files[0].type)
      ) {
        this.setState({
          error: {
            status: true,
            message: "Audio file cannot be upload in SF",
            location: "uploadFilePage",
          },
        });
        return false;
      }

      this.setShowLoader(true, "Please wait while uploading your files.");

      //Reading upload data
      try {
        var binaryData = [];
        var image = document.getElementById("output");
        binaryData.push(e.target.files[0]);
        try {
          image.src = window.URL.createObjectURL(
            new Blob(binaryData, { type: "application/zip" })
          );
        } catch (error) {
          console.log("error", error);
        }

        // Setting position for multiple files
        let position = 1;
        if (e.target.id == "supporting_file1") {
          position = 2;
        } else if (e.target.id == "supporting_file2") {
          position = 3;
        } else if (e.target.id == "supporting_file3") {
          position = 4;
        } else if (e.target.id == "supporting_file4") {
          position = 5;
        }

        // var image = document.getElementById("output");
        // image.src = URL.createObjectURL(e.target.files[0]);
        let form_data = new FormData();
        form_data.append("file", e.target.files[0]);
        form_data.append("position", position);

        // Main file upload happens here
        if (e.target.id == "main_file") {
          axiosInstance
            .post("/v1/admin/upload/pinning", form_data, this.config)
            .then((res) => {
              // Notifiy center state fileupload type.
              if ([...videoMimeType].includes(e.target.files[0].type)) {
                this.setState({
                  ...this.state,
                  isMainFileVideo: true,
                  isMainFileAudio: false,
                  isMainFile3D: false,
                });
              }
              if ([...audioMimeType].includes(e.target.files[0].type)) {
                this.setState({
                  ...this.state,
                  isMainFileVideo: false,
                  isMainFileAudio: true,
                  isMainFile3D: false,
                });
              }
              if (
                e.target.files[0].name.split(".").pop() == "glb" ||
                e.target.files[0].name.split(".").pop() == "gltf"
              ) {
                this.setState({
                  ...this.state,
                  isMainFileVideo: false,
                  isMainFileAudio: false,
                  isMainFile3D: true,
                });
              }

              // Update center state with upload file data
              this.setState({
                ...this.state,
                imageUrl: res.data.result.filePath,
                multiFileStore: {
                  ...this.state.multiFileStore,
                  mainFile: [
                    res.data.result.id,
                    res.data.result.name,
                    res.data.result.previewUrl,
                    //decryptFunction(res.data.result.url),
                    res.data.result.type,
                  ],
                },
                // ipfsImageUrlPath: res.data.result.path,
                // mediaType: res.data.result.mediaType,
                audioThumbnail: "",
                animationUrl: "",
                shortVideoUrl: "",
                longVideoUrl: "",
                audioUrl: "",
                // audioThumbnail: "",
                ipfsLongVideoUrlPath: this.state.isMainFileVideo
                  ? res.data.result.url
                  : "",
                ipfsVideoVideoUrlPath: "",
                ipfsAnimationUrlPath: "",
                ipfsAudioUrlPath: this.state.isMainFileAudio
                  ? this.state.ipfsAudioUrlPath
                  : "",
                ipfsAudioThumbnailPath: "",
                ipfsImageUrlPath: [...imageMimeType].includes(
                  e.target.files[0].type
                )
                  ? res.data.result.previewUrl
                  : "",
                isMainFileVideo: this.state.isMainFileVideo ? true : false,
                isMainFile3D:
                  e.target.files[0].name.split(".").pop() == "glb" ||
                  e.target.files[0].name.split(".").pop() == "gltf"
                    ? true
                    : false,
                is3dFile:
                  e.target.files[0].name.split(".").pop() == "glb" ||
                  e.target.files[0].name.split(".").pop() == "gltf"
                    ? true
                    : false,
                ipfs3dUrl:
                  e.target.files[0].name.split(".").pop() == "glb" ||
                  e.target.files[0].name.split(".").pop() == "gltf"
                    ? res.data.result.url
                    : "",
                ipfs3dId:
                  e.target.files[0].name.split(".").pop() == "glb" ||
                  e.target.files[0].name.split(".").pop() == "gltf"
                    ? res.data.result.previewUrl
                    : "",
                disableSupportingDocs: false,
              });
              if ([...audioMimeType].includes(e.target.files[0].type)) {
                this.setState({
                  ...this.state,
                  ipfsAudioUrlPath: res.data.result.url,
                });
              }
              if (this.props.data) {
                this.setState({
                  ...this.state,
                  isMediaChange: true,
                });
              }
              this.setShowLoader(false, "");
            })
            .catch((err) => {
              this.setShowLoader(false, "");
              if (
                err.response &&
                err.response.data.errors &&
                err.response.data.errors.length > 1
              ) {
                Swal.fire({
                  title: "Oops!",
                  text: err?.response?.data?.errors,
                  icon: "error",
                });
              } else {
                Swal.fire("Oops!", err?.response?.data?.message, "error");
              }
            });
        } else {
          // File upload section for supporting file
          if (e.target.id == "supporting_file1") {
            if (this.state.isMainFileAudio) {
              // If audio file upload as main file, need to upload image as thumbnail -- Validation
              if (![...imageMimeType].includes(e.target.files[0].type)) {
                this.setShowLoader(false, "");
                this.setState({
                  error: {
                    status: true,
                    message: "Please upload the image format file.",
                    location: "uploadFilePage",
                  },
                });
                return false;
              }
            }
          }

          // Notifying BE whether Audio Thumnail enabled
          if (this.state.isMainFileAudio) {
            form_data.append(
              "isThumbnail",
              e.target.id == "supporting_file1" ? true : false
            );
            // form_data.append("isShortVideo", false);
          }
          if (this.state.isMainFileVideo) {
            // form_data.append("isShortVideo", true);
            form_data.append("isThumbnail", false);
          }
          axiosInstance
            .post("/v1/admin/upload/bucket", form_data, this.config)
            .then((res) => {
              if (e.target.id == "supporting_file1") {
                if (
                  e.target.files[0].name.split(".").pop() == "glb" ||
                  e.target.files[0].name.split(".").pop() == "gltf"
                ) {
                  console.log(res.data.result.previewUrl);
                  this.setState({
                    isSuplimentaryFile13d: true,
                    ipfs3dSupplimentary1Id: res.data.result.previewUrl,
                    // isSuplimentaryFile13d: true,
                  });
                }

                if ([...videoMimeType].includes(e.target.files[0].type)) {
                  this.setState({
                    ...this.state,
                    isSuplimentaryFile1Video: true,
                  });
                } else {
                  this.setState({
                    ...this.state,
                    isSuplimentaryFile1Video: false,
                  });
                }
              }

              if (e.target.id == "supporting_file2") {
                if (
                  e.target.files[0].name.split(".").pop() == "glb" ||
                  e.target.files[0].name.split(".").pop() == "gltf"
                ) {
                  console.log(res.data.result.previewUrl);
                  this.setState({
                    is3dFile: true,
                    ipfs3dId: res.data.result.previewUrl,
                    isSuplimentaryFile23d: true,
                  });
                }

                if ([...videoMimeType].includes(e.target.files[0].type)) {
                  this.setState({
                    ...this.state,
                    isSuplimentaryFile2Video: true,
                  });
                } else {
                  this.setState({
                    ...this.state,
                    isSuplimentaryFile2Video: false,
                  });
                }
              }
              if (e.target.id == "supporting_file3") {
                if (
                  e.target.files[0].name.split(".").pop() == "glb" ||
                  e.target.files[0].name.split(".").pop() == "gltf"
                ) {
                  console.log(res.data.result.previewUrl);
                  this.setState({
                    is3dFile: true,
                    ipfs3dId: res.data.result.previewUrl,
                    isSuplimentaryFile33d: true,
                  });
                }

                if ([...videoMimeType].includes(e.target.files[0].type)) {
                  this.setState({
                    ...this.state,
                    isSuplimentaryFile3Video: true,
                  });
                } else {
                  this.setState({
                    ...this.state,
                    isSuplimentaryFile3Video: false,
                  });
                }
              }
              if (e.target.id == "supporting_file4") {
                if (
                  e.target.files[0].name.split(".").pop() == "glb" ||
                  e.target.files[0].name.split(".").pop() == "gltf"
                ) {
                  console.log(res.data.result.previewUrl);
                  this.setState({
                    is3dFile: true,
                    ipfs3dId: res.data.result.previewUrl,
                    isSuplimentaryFile43d: true,
                  });
                }

                if ([...videoMimeType].includes(e.target.files[0].type)) {
                  this.setState({
                    ...this.state,
                    isSuplimentaryFile4Video: true,
                  });
                } else {
                  this.setState({
                    ...this.state,
                    isSuplimentaryFile4Video: false,
                  });
                }
              }

              if (e.target.id == "supporting_file1") {
                this.setState({
                  ...this.state,
                  multiFileStore: {
                    ...this.state.multiFileStore,
                    supplimentary1: [
                      res.data?.result?.id,
                      res.data?.result?.name,
                      res.data?.result?.url,
                      res.data.result.type,
                    ],
                  },
                  // ipfsImageUrlPath: res.data.result.path,
                  // mediaType: res.data.result.mediaType,
                  audioThumbnail: "",
                  animationUrl: "",
                  shortVideoUrl: "",
                  longVideoUrl: "",
                  audioUrl: "",
                  // audioThumbnail: "",
                  ipfsVideoVideoUrlPath: "",
                  ipfsAnimationUrlPath: "",
                  ipfsAudioUrlPath: this.state.isMainFileAudio
                    ? this.state.ipfsAudioUrlPath
                    : "",
                  ipfsAudioThumbnailPath: this.state.isMainFileAudio
                    ? res.data.result.url
                    : "",
                });
              } else if (e.target.id == "supporting_file2") {
                this.setState({
                  ...this.state,
                  multiFileStore: {
                    ...this.state.multiFileStore,
                    supplimentary2: [
                      res.data?.result?.id,
                      res.data?.result?.name,
                      res.data?.result?.url,
                      res.data.result.type,
                    ],
                  },
                  // ipfsImageUrlPath: res.data.result.path,
                  // mediaType: res.data.result.mediaType,
                  audioThumbnail: "",
                  animationUrl: "",
                  shortVideoUrl: "",
                  longVideoUrl: "",
                  audioUrl: "",
                  // audioThumbnail: "",
                  ipfsVideoVideoUrlPath: "",
                  ipfsAnimationUrlPath: "",
                  ipfsAudioUrlPath: this.state.isMainFileAudio
                    ? this.state.ipfsAudioUrlPath
                    : "",
                  ipfsAudioThumbnailPath: this.state.isMainFileAudio
                    ? this.state.ipfsAudioThumbnailPath
                    : "",
                });
                console.log(
                  "log",
                  this.state.isMainFileAudio
                    ? this.state.ipfsAudioThumbnailPath
                    : ""
                );
              } else if (e.target.id == "supporting_file3") {
                this.setState({
                  ...this.state,
                  multiFileStore: {
                    ...this.state.multiFileStore,
                    supplimentary3: [
                      res.data?.result?.id,
                      res.data?.result?.name,
                      res.data?.result?.url,
                      res.data.result.type,
                    ],
                  },
                  // ipfsImageUrlPath: res.data.result.path,
                  // mediaType: res.data.result.mediaType,
                  audioThumbnail: "",
                  animationUrl: "",
                  shortVideoUrl: "",
                  longVideoUrl: "",
                  audioUrl: "",
                  // audioThumbnail: "",
                  ipfsVideoVideoUrlPath: "",
                  ipfsAnimationUrlPath: "",
                  ipfsAudioUrlPath: this.state.isMainFileAudio
                    ? this.state.ipfsAudioUrlPath
                    : "",
                  ipfsAudioThumbnailPath: this.state.isMainFileAudio
                    ? this.state.ipfsAudioThumbnailPath
                    : "",
                });
              } else if (e.target.id == "supporting_file4") {
                this.setState({
                  ...this.state,
                  multiFileStore: {
                    ...this.state.multiFileStore,
                    supplimentary4: [
                      res.data?.result?.id,
                      res.data?.result?.name,
                      res.data?.result?.url,
                      res.data.result.type,
                    ],
                  },
                  // ipfsImageUrlPath: res.data.result.path,
                  // mediaType: res.data.result.mediaType,
                  audioThumbnail: "",
                  animationUrl: "",
                  shortVideoUrl: "",
                  longVideoUrl: "",
                  audioUrl: "",
                  // audioThumbnail: "",
                  ipfsVideoVideoUrlPath: "",
                  ipfsAnimationUrlPath: "",
                  ipfsAudioUrlPath: this.state.isMainFileAudio
                    ? this.state.ipfsAudioUrlPath
                    : "",
                  ipfsAudioThumbnailPath: this.state.isMainFileAudio
                    ? this.state.ipfsAudioThumbnailPath
                    : "",
                });
              }
              this.setShowLoader(false, "");
            })
            .catch((err) => {
              this.setShowLoader(false, "");
              if (
                err.response &&
                err.response.data.errors &&
                err.response.data.errors.length > 1
              ) {
                Swal.fire({
                  title: "Oops!",
                  text: err?.response?.data?.errors,
                  icon: "error",
                });
              } else {
                Swal.fire("Oops!", err?.response?.data?.message, "error");
              }
            });
        }
      } catch (error) {
        this.setShowLoader(false, "");
      }
    }
  };

  handleClickSnackBar = () => {
    this.setState({ ...this.state, setOpenSnackBar: true });
  };

  handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ ...this.state, setOpenSnackBar: false });
  };

  //Close Drawer Actions
  closeDrawer = () => {
    this.props.onClose();
    this.setState({
      ...this.props.data,
      value: "",
      categoryId: "",
      subCategoryId: "",
      saleType: this.props?.saleType ?? "fixed",
      name: "",
      status: "active",
      description: "",
      quantity: 1,
      creatorDescription: "",
      creatorName: "",
      shortDescription: "",
      auctionBuyNowPrice: null,
      isBuyNowForAuction: false,
      isDownloadable: "false",
      artist: {
        id: "",
        royaltyCommissionPercentage: "",
        royaltyCommissionCapAmount: "",
        primarySalePercentage: "",
      },
      creator: {
        id: "",
        royaltyCommissionPercentage: "",
        royaltyCommissionCapAmount: "",
        primarySalePercentage: "",
      },
      auction: {
        auctionType: "normal",
        openingPrice: null,
        lowestPriceToAccept: null,
        bidIncrement: null,
        noOfBids: null,
        startDate: new Date(moment.utc().format()),
        endingDate: new Date(moment.utc().add(1, "days").format()),
        status: "active",
      },
      isMediaChange: false,
      ipfsAudioUrlPath: "",
      ipfsShortVideoUrlPath: "",
      ipfsLongVideoUrlPath: "",
      ipfsImageUrlPath: "",
      ipfsAnimationUrlPath: "",
      ipfsMetaUrl: "",
      royaltyCommission: null,
      mediaType: "",
      multiFileStore: {
        mainFile: [],
        supplimentary1: [],
        supplimentary2: [],
        supplimentary3: [],
        supplimentary4: [],
      },
      isCarrosel: "false",
      isEditionMintToAdmin: false,
      descriptionMint: "",

      error: {
        status: false,
        message: "",
        location: "",
      },
      showLoader: {
        open: false,
        message: "",
      },
      setOpenSnackBar: false,
      isEdit: false,
      isMainFileVideo: false,
      isMainFileAudio: false,
      isMainFile3D: false,
      isSupplimentary13dFile: false,
      disableSupportingDocs: true,
      isSuplimentaryFile1Video: false,
      isSuplimentaryFile2Video: false,
      isSuplimentaryFile3Video: false,
      isSuplimentaryFile4Video: false,
      is3dFile: false,
      ipfs3dSupplimentary1Id: false,
      totalEdition: null,
      regularPrice: null,
    });
  };

  // const classes = useStyles();

  render() {
    const isDrawerOpened = this.props.open;
    return (
      <div>
        {this.state.showLoader.open == true && (
          <Loader loaderInfo={this.state.showLoader.message} />
        )}
        <Drawer
          anchor="right"
          variant="temporary"
          open={isDrawerOpened}
          onClose={() => {
            this.props.onClose();
            this.setState({
              ...this.props.data,
              value: "",
              categoryId: "",
              subCategoryId: "",
              saleType: this.props?.saleType ?? 'fixed',
              name: "",
              status: "active",
              description: "",
              quantity: 1,
              creatorDescription: "",
              creatorName: "",
              shortDescription: "",
              auctionBuyNowPrice: null,
              isBuyNowForAuction: false,
              isDownloadable: "false",
              artist: {
                id: "",
                royaltyCommissionPercentage: "",
                royaltyCommissionCapAmount: "",
                primarySalePercentage: "",
              },
              creator: {
                id: "",
                royaltyCommissionPercentage: "",
                royaltyCommissionCapAmount: "",
                primarySalePercentage: "",
              },
              auction: {
                auctionType: "normal",
                openingPrice: null,
                lowestPriceToAccept: null,
                bidIncrement: null,
                noOfBids: null,
                startDate: new Date(moment.utc().format()),
                endingDate: new Date(moment.utc().add(1, "days").format()),
                status: "active",
              },
              isMediaChange: false,
              ipfsAudioUrlPath: "",
              ipfsShortVideoUrlPath: "",
              ipfsLongVideoUrlPath: "",
              ipfsImageUrlPath: "",
              ipfsAnimationUrlPath: "",
              ipfsMetaUrl: "",
              royaltyCommission: null,
              mediaType: "",
              multiFileStore: {
                mainFile: [],
                supplimentary1: [],
                supplimentary2: [],
                supplimentary3: [],
                supplimentary4: [],
              },
              isCarrosel: "false",
              isEditionMintToAdmin: false,
              descriptionMint: "",

              error: {
                status: false,
                message: "",
                location: "",
              },
              showLoader: {
                open: false,
                message: "",
              },
              setOpenSnackBar: false,
              isEdit: false,
              isMainFileVideo: false,
              isMainFileAudio: false,
              isMainFile3D: false,
              isSupplimentary13dFile: false,
              disableSupportingDocs: true,
              isSuplimentaryFile1Video: false,
              isSuplimentaryFile2Video: false,
              isSuplimentaryFile3Video: false,
              isSuplimentaryFile4Video: false,
              is3dFile: false,
              ipfs3dSupplimentary1Id: false,
              totalEdition: null,
              regularPrice: null,
            });
          }}
          className={this.props.classes.rootDrawer}
        >
          <AddProductForm
            style={{ ...styles.title, marginBottom: "30px" }}
            state={this.state}
            onChangeDescription={(data) => {
              // const data = editor.getData()
              this.setState({ description: data });
            }}
            handleChange={this.handleChange}
            handleCreator={this.handleCreator}
            // handleImageUpload={(type) => this.handleImageUpload(type)}
            handleMultiFileUpload={(type) => this.handleMultiFileUpload(type)}
            loadfile={this.loadfile}
            categoryList={this.state.catagories}
            subCategoryList={this.state.subCategoryList}
            isMainFileVideo={this.state.isMainFileVideo}
            isMainFileAudio={this.state.isMainFileAudio}
            isMainFile3D={this.state.isMainFile3D}
            disableSupportingDocs={this.state.disableSupportingDocs}
            isSuplimentaryFile1Video={this.state.isSuplimentaryFile1Video}
            isSuplimentaryFile2Video={this.state.isSuplimentaryFile2Video}
            isSuplimentaryFile3Video={this.state.isSuplimentaryFile3Video}
            isSuplimentaryFile4Video={this.state.isSuplimentaryFile4Video}
            isSuplimentaryFile13d={this.state.isSuplimentaryFile13d}
            isSuplimentaryFile23d={this.state.isSuplimentaryFile23d}
            isSuplimentaryFile33d={this.state.isSuplimentaryFile33d}
            isSuplimentaryFile43d={this.state.isSuplimentaryFile43d}
            is3dFile={this.state.is3dFile}
            ipfs3dUrl={this.state.ipfs3dUrl}
            isResellable = {this.state.isResellable}
            handleSubmitForm={this.handleSubmitForm}
            resetCreatorOrArtist={this.resetCreatorOrArtist}
            isEdit={this.state.isEdit}
            setSelectedVideoFileType={(value) =>
              this.setState({ ...this.state, selectedVideoFileType: value })
            }
            setSelectedAudioType={(value) =>
              this.setState({ ...this.state, selectedAudioType: value })
            }
            setSaleType={(name, value) => {
              this.setState({ ...this.state, name: value });
            }}
            setError={(status, message, page) => {
              this.setState({
                error: {
                  status: status,
                  message: message,
                  location: page,
                },
              });
            }}
            handleRemoveFile={this.handleRemoveFile}
            handleDeleteAll={this.handleDeleteAll}
            onCloseDrawer={this.closeDrawer}
            // setShowLoader={(value) => setShowLoader(value)}
          />
        </Drawer>
      </div>
    );
  }
}

const mapDispatchtoprops = {
  toggleAction: (currentState) => {
    // Dispatch(actionToggle(currentState));
  },
};

export default withStyles(styles)(connect(null, mapDispatchtoprops)(UiDrawer));
