import React, { useEffect, useState } from "react";
import Select from "react-select";
import { axiosInstance } from "../../../../utility/api";

const formatInput = (e) => {
  let checkIfNum;
  if (e.key !== undefined) {
    checkIfNum = e.key === "e" || e.key === "+" || e.key === "-";
  } else if (e.keyCode !== undefined) {
    checkIfNum = e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189;
  }
  return checkIfNum && e.preventDefault();
};

function CreatorBox({ isEdit, state, handleSelectChange, handleChange, collectionFormData, multipleCoCreator }) {
  const [creatorListMain, setCreatorListMain] = useState([]);
  const [creatorList, setCreatorList] = useState([]);

  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  const getCreatorName = () => {
    const templist = creatorList.filter((item) => {
      if (
        item?.id != state.artist?.id && item.isCreatedByAdmin&&
        !inCocreator(item?.id) 
      ) {
        return item;
      }
    });
    console.log("creator", templist);
    return templist;
  };

  const getCreatorList = async (search) => {
    if (config?.headers?.Authorization) {
      let query = `?page=1&limit=10&search=${
        search || ""
      }&isBlock=&fromDate=&toDate=`;
      let newq = `?limit=9999999&page=1&isDropDown=true`;
      // let query = {
      // 	page: 1,
      // 	limit: 10,
      // 	search: '',
      // 	isBlock: '',
      // 	fromDate: '',
      // 	toDate: ''
      // }
      axiosInstance
        .get(`/v1/admin/user${newq}`, config)
        .then((res) => {
          console.log("/admin/user res", res);
          let arr = [];
          if (res?.data?.result?.users?.length) {
            res.data.result.users.forEach((el) => {
              let obj = {
                ...el,
                value: el?.id,
                label: el?.userName? el?.userName : el.firstName|| el.lastName? el?.firstName + " "+el?.lastName: "userId: "+ el?.id

              };
              arr.push(obj);
            });
          }

          if (!search) setCreatorListMain(arr);
          setCreatorList(arr);
        })
        .catch((err) => {
          console.log("/admin/user Error while getting subcategories:", err);
        });
    }
  };

  useEffect(() => {
    getCreatorList("");
  }, []);

  const inCocreator = (id) => {
    let flag = 0;
    for (let i = 0; i < multipleCoCreator?.length; i++) {
      if (id == multipleCoCreator[i]?.id) {
        flag = 1;
        return true;
      }
    }
    if (!flag) {
      return false;
    }
  };

  return (
    <div>
      <div className="row d-flex-aic separator">
        <div className="form-group mb-3 col-xs-12 col-sm-6">
          <label htmlFor="stock">Creator Name</label>
          <span className="colorRed">*</span>
          <Select
            classNamePrefix="creator-select-input"
            value={
              creatorListMain.find((el) => el?.id === collectionFormData?.creator?.id) ||
              null
            }
            options={getCreatorName()}
            onChange={(e) => handleSelectChange(e, "creatorId")}
            isDisabled={isEdit ? true : false}
            // onInputChange={(e) => setSearch(e)}
          />
        </div>
        <div className="form-group mb-3 col-xs-12 col-sm-6">
          <label className="ws-no-wrap" htmlFor="stock">
            Commission Percentage
          </label>
          <span className="colorRed">*</span>
          <div className="displayFlexBaseline">
            <div className="percent-symbol">%</div>
            <span className="currencyInput w-100 removePadding">
              <input
                type="number"
                name="creator.royaltyCommissionPercentage"
                min="0"
                className="form-control validate percentageText"
                maxLength="12"
                disabled={isEdit ? true : false}
                onWheel={(event) => event.currentTarget.blur()}
                value={collectionFormData?.creator?.royaltyCommissionPercentage}
                onChange={(e) => {
                  if (/[0-9]/i.test(Number(e.target.value))) {
                    handleSelectChange(e, 'creator', 'royaltyCommissionPercentage');
                  }
                }}
                required
              />
            </span>
          </div>
        </div>
        <div className="form-group mb-3 col-xs-12 col-sm-6">
          <label className="ws-no-wrap" htmlFor="stock">
            Primary Earnings
          </label>
          <span className="colorRed">*</span>
          <div className="displayFlexBaseline">
            <div className="percent-symbol">%</div>
            <span className="currencyInput w-100 removePadding">
              <input
                type="number"
                name="creator.primarySalePercentage"
                min="0"
                className="form-control validate percentageText"
                maxLength="12"
                onWheel={(event) => event.currentTarget.blur()}
                disabled={isEdit ? true : false}
                value={collectionFormData?.creator?.primarySalePercentage}
                onChange={(e) => {
                  console.log("changed");
                  console.log(collectionFormData.creator?.primarySalePercentage);
                  if (e.target.value >= 0) {
                    handleSelectChange(e, 'creator', 'primarySalePercentage');
                  }
                }}
                onKeyDown={formatInput}
                required
              />
            </span>
          </div>
        </div>
        <div
          className={
            state.creator.id
              ? "form-group mb-3 col-xs-12 col-sm-5"
              : "form-group mb-3 col-xs-12 col-sm-6"
          }
        >
          <label htmlFor="stock">Cap Amount (USD)</label>
          {/* <span className="colorRed">*</span> */}
          <input
            type="number"
            name="creator.royaltyCommissionCapAmount"
            min="0"
            className="form-control validate"
            maxLength="12"
            value={collectionFormData?.creator?.royaltyCommissionCapAmount}
            disabled={isEdit ? true : false}
            onWheel={(event) => event.currentTarget.blur()}
            onChange={(e) => {
              if (/[0-9]/i.test(Number(e.target.value))) {
                handleSelectChange(e, 'creator', 'royaltyCommissionCapAmount');
              }
            }}
            required
          />
        </div>
      </div>
    </div>
  );
}

export default CreatorBox;
