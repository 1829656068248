/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import "./tableExpandedRow-MaterialUI.css";
import EditIcon from "../../../../assets/images/icons/edit.svg";
import PreviewIcon from "../../../../assets/images/icons/preview.svg";
import DeleteIcon from "../../../../assets/images/icons/delete.svg";
import { ciphertextCustomerAssetView } from "../../../../utility/Security";

import { axiosInstance, baseURL, customerWebUrl } from "../../../../utility/api";
import UiDrawer from "../../../Sidedrawer/Uidrawer";
import Swal from "sweetalert2";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//     flexWrap: "wrap",
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//     width: "25ch",
//   },

//   inputWidthHeight: {
//     maxHeight: "40px",
//     maxWidth: "64%",
//   },
// }));

// const inputProps = {
//   width: "50%",
//   height: "10px",
// };

export default function ExpandedRow(props) {
	const { rowData, setShowLoader, showLoader } = props;
	// const classes = useStyles();

	// const [checked, setChecked] = useState({
	//   unlimited: false,
	//   outofstock: false,
	// });

	const [salePrice, setSalePrice] = useState(null);
	const [regularPrice, setRegularPrice] = useState(null);
	const [stock, setStock] = useState(null);
	// const [deleteProductID, setDeleteProductID] = useState(null);

	const [open, setOpen] = useState(false);

	useEffect(() => {
		setRegularPrice(rowData?.regularPrice);
		setSalePrice(rowData?.salePrice);
		setStock(rowData?.quantity);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowData?.regularPrice, rowData?.salePrice, rowData?.quantity]);

	const deleteProduct = (id) => {
		if (id) {
			confirmAlert({
				title: "Delete product?",
				message: "Are you sure to delete.",
				buttons: [
					{
						label: "Yes",
						onClick: () => handleDelete(id),
					},
					{
						label: "No",
						onClick: () => console.log(""),
					},
				],
			});
		}
	};
	const handleDelete = (id) => {
		const config = {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
			},
		};
		axiosInstance
			.delete(`/v1/admin/asset/${id}`, config)
			.then((res) => {
				window.location.reload(true);
				Swal.fire("Success!", res?.data?.message, "success");
			})
			.catch((err) => {
				Swal.fire("Error!", err?.response?.data?.message, "error");
				console.log("rrr Error", err);
			});
	};

	// const handlePriceStockUpdate = ({ id }) => {
	//   const config = {
	//     headers: {
	//       Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
	//     },
	//   };

	//   let body = {
	//     salePrice: salePrice,
	//     regularPrice: regularPrice,
	//     quantity: stock,
	//   };
	//   axiosInstance
	//     .put(`/v1/admin/asset/${id}`, body, config)
	//     .then((res) => {
	//       Swal.fire("Success!", res?.data?.message, "success");
	//       window.location.reload(true);
	//     })
	//     .catch((err) => {
	//       console.log("Error", err);
	//       Swal.fire("Error!", err?.response?.data?.errors, "error");
	//     });
	// };

	// const formatInputDot = (e) => {
	//   let checkIfNum;
	//   if (e.key !== undefined) {
	//     checkIfNum =
	//       e.key === "e" || e.key === "+" || e.key === "-" || e.key === ".";
	//   } else if (e.keyCode !== undefined) {
	//     checkIfNum =
	//       e.keyCode === 69 ||
	//       e.keyCode === 187 ||
	//       e.keyCode === 189 ||
	//       e.keyCode === 190 ||
	//       e.keyCode === 110;
	//   }
	//   return checkIfNum && e.preventDefault();
	// };

	// const formatInput = (e) => {
	//   let checkIfNum;
	//   if (e.key !== undefined) {
	//     checkIfNum = e.key === "e" || e.key === "+" || e.key === "-";
	//   } else if (e.keyCode !== undefined) {
	//     checkIfNum = e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189;
	//   }
	//   return checkIfNum && e.preventDefault();
	// };

	// const handleInputChange = (event) => {
	//   let value = "";
	//   if (event.target.value != "") {
	//     if (
	//       event.target.value.startsWith(".0") ||
	//       event.target.value.startsWith("0")
	//     ) {
	//       value = event.target.value;
	//     } else {
	//       value =
	//         event.target.value < 0.0000000000000001 ? "" : event.target.value;
	//     }
	//   }
	//   switch (event.target.name) {
	//     case "regularPrice":
	//       setRegularPrice(value);
	//       break;
	//     case "salePrice":
	//       setSalePrice(value);
	//       break;

	//     default:
	//       break;
	//   }
	// };

	// const handleStockInputChange = (event) => {
	//   let value = "";
	//   if (event.target.value != "") {
	//     value = event.target.value < 0 ? "" : event.target.value;
	//   }
	//   setStock(value);
	// };
	console.log(rowData, 'rowdata')
	return (
		<>
			<div className="tableExpandWrapper">
				<div className="tableExpandHeader">
					<div className="section-three">
						{(rowData?.saleType !== "auction" ||
						rowData?.purchasedBy == false) ? (
							<>
							{props.selectedTab=="assets"&&
							<Tooltip title="Edit product">
								<img
									src={EditIcon}
									alt="edit"
									className="marginLeft14 cursorPointer"
									height="24px"
									onClick={() => setOpen(true)}
								/>
							</Tooltip>
							}
							</>
						) : null}
						<a
							href={`${customerWebUrl}item/${rowData?.id}?admin=${ciphertextCustomerAssetView}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Tooltip title="Preview">
								<img
									src={PreviewIcon}
									alt="preview"
									className="marginLeft14 cursorPointer"
									height="28px"
								/>
							</Tooltip>
						</a>
						{((rowData?.saleType == "auction" &&
							!rowData?.auctions[0]?.isExpired) ||
						!rowData?.purchasedBy) ? (
							<Tooltip
								title="Delete Product"
								onClick={() => {
									// setDeleteProductID(rowData?.id);
									deleteProduct(rowData?.id);
								}}
							>
								<img
									src={DeleteIcon}
									alt="delete"
									className="marginLeft14 cursorPointer"
									height="24px"
								/>
							</Tooltip>
						) : null}
					</div>
				</div>
				{open && (
					<UiDrawer
						open={open}
						onClose={() => setOpen(false)}
						data={rowData}
						setShowLoader={() => setShowLoader(!showLoader)}
						isEdit={true}
						saleType = {rowData?.isAirdropNft? 'free' : rowData?.saleType}
					/>
				)}
			</div>
		</>
	);
}

ExpandedRow.propTypes = {
	graphType: PropTypes.string,
	apigraphData: PropTypes.array,
	onChangeGraph: PropTypes.func,
};
