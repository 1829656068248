/* CUSTOMIZER */
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIX_BACKGROUND_LAYOUT = "ADD_MIX_BACKGROUND_LAYOUT";
export const ROUTER_ANIMATION = "ROUTER_ANIMATION";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const SAVE_NAME = "SAVE_NAME";
export const SAVE_USERID = "SAVE_USERID";
export const ACTION_TOGGLE = "ACTION_TOGGLE";
export const SAVE_PROFILE_DETAILS = "SAVE_PROFILE_DETAILS";
export const SAVE_CRYPTO_VALUE = "SAVE_CRYPTO_VALUE";
export const REFRESH_PROFILE_DATA = "REFRESH_PROFILE_DATA";
export const SAVE_PROFILE_PIC = "SAVE_PROFILE_PIC";
export const SAVE_PROFILE_INFO = "SAVE_PROFILE_INFO";

