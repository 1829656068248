import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
// import { useParams } from "react-router";
import parse from "html-react-parser";
// import Loader from './../LoaderCircle/index';

const ThreeDeeViewer = (props) => {
	let { id, setLoader3D } = props;

	const [player, setPlayer] = useState("");
	const [loading, setLoading] = useState("");

	useEffect(() => {
		console.log("idid", id);
		let fileId = id?.split("/")[id?.split("/")?.length - 1];
		if (fileId) {
			setLoading(true);
			setLoader3D(true);
			getData(fileId)
				.then((data) => {
					setLoader3D(false);
					let result = data.data.html
						.replace('width="640"', 'width="420"')
						.replace('height="360"', 'height="270"');
					setPlayer(result);
					setLoading(false);
				})
				.catch((err) => console.log("error", err));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	const getData = async (id) => {
		const sketchFabId = id.split("/")[id.split("/").length - 1];

		return new Promise(async (resolve, reject) => {
			let retries = 0;
			let success = false;
			const maxRetries = 10000;

			while (retries < maxRetries && !success) {
				try {
					const response = await axios.get(
						`https://sketchfab.com/oembed?url=https://sketchfab.com/models/${sketchFabId}`
					);
					console.log("Response ", response?.data?.html);
					success = true;
					// setLoader3D(false);
					resolve(response);
					// setLoader3D(false);
				} catch (err) {
					const status = err?.response?.status || 500;
					console.log(`Error Status: ${status}`);
				}
				await sleep(3000);
				retries++;
			}
			console.log(`Too many request retries.`);
			reject();
		});
	};

	return <div style={{ margin: "auto" }}>{parse(player)}</div>;
};

export default ThreeDeeViewer;
