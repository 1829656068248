import {
  SAVE_TOKEN,
  SAVE_NAME,
  SAVE_USERID,
  SAVE_PROFILE_DETAILS,
  SAVE_CRYPTO_VALUE,
  SAVE_PROFILE_PIC,
  SAVE_PROFILE_INFO,
} from "../actionTypes";

const initial_state = {
  userToken: "",
  firstName: "Admin",
  lastName: "",
  userId: null,
  profilePicture: null,
  email: null,
  cryptoValue: null,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SAVE_TOKEN:
      const token = action.payload;
      state.userToken = token;
      return { ...state };
    case SAVE_NAME:
      // state.firstname = action.payload;
      return { ...state, firstName: action.payload };

    case SAVE_USERID:
      state.userId = action.payload;
      return { ...state };

    case SAVE_PROFILE_DETAILS:
      state.userId = action.payload;
      return {
        ...state,
        firstName: action.payload?.firstName,
        lastName: action.payload?.lastName,
        email: action.payload?.email,
        profilePicture: action.payload?.profilePicture,
      };

    case SAVE_PROFILE_INFO:
      // state.firstname = action.payload?.firstname;
      console.log("Entered here", state, action);
      return {
        ...state,
        firstName: action.payload?.firstName,
        lastName: action.payload?.lastName,
        email: action.payload?.email,
      };

    case SAVE_PROFILE_PIC:
      return { ...state, profilePicture: action.payload };

    case SAVE_CRYPTO_VALUE:
      state.cryptoValue = action.payload?.exchangeRate;
      return { ...state };

    default:
      return { ...state };
  }
};
