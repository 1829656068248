/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import "react-confirm-alert/src/react-confirm-alert.css";

// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import "./commissionListing.css";
import Loader from "../common/Loader/Loader";
import { axiosInstance, baseURL } from "../../utility/api";
import { formatDateTime } from "../common/date";
import DataRangePopOver from "../common/DateRange/DateRangePopOver";
import NoRecordsFound from "../common/Error/NoRecordsFound";
import { useDebounce } from "../../hooks/useDebounce";

const useRowStyles = makeStyles({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
});

//Each row of table
function Row(props) {
  const { data, tableColumnWidth, fiatValue } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const polygon =
    process.env.NODE_ENV === "development"
      ? "https://mumbai.polygonscan.com/tx/"
      : "https://mumbai.polygonscan.com/tx/";

  const getDate = (date) => {
    let d = formatDateTime(date);
    // let d =  moment.utc(date).local().format('DD/MM/YYYY hh:mm:ss A')
    let index = d.indexOf(" ");
    return (
      <>
        {d.substring(0, index)}{" "}
        <span style={{ whiteSpace: "nowrap" }}>{d.substring(index + 1)}</span>
      </>
    );
  };

  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.rowDatas}
        style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          <div className="columnStyles">
            {/* {console.log(moment.utc(data?.createdAt).local().format('DD/MM/YYYY hh:mm:ss A'))} */}
            {data?.createdAt ? getDate(data?.createdAt) : "-"}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
        {/* {data?.type=="resell"
              ? `#${data?.asset?.currentEdition}/${data?.asset?.totalEdition}`
              : data?.asset?.purchasedBy && data?.status == "success"
              ? `#${data?.asset?.currentEdition}/${data?.asset?.totalEdition}`
              : ""} */}
              {data?.asset?.name} { data?.status == "success"
              && `#${data?.asset?.currentEdition?data?.asset.currentEdition:1}/${data?.asset?.totalEdition}`
              }
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div className="columnStyles">{data?.id ? data?.id : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles">{data?.from ? data?.from : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="columnStyles">{data?.to ? data?.to : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
          <div className="columnStyles">
            {data?.unitPrice ? data?.unitPrice : "-"}
          </div>
        </TableCell>

        <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
          <div className="columnStyles">{`${
            data?.balanceAfterCommission
              ? `${data?.balanceAfterCommission.toFixed(4)} $`
              : "-"
          } ${
            data?.balanceAfterCommission
              ? `(${data?.balanceAfterCommission.toFixed(4)} USDC)`
              : ""
          }`
          
          // ${
          //   data?.ethBalanceAfterCommission
          //     ? `(${(data?.balanceAfterCommission * fiatValue).toFixed(
          //         3
          //       )} MATIC)`
          //     : ""
          // }`
          }
          </div>
        </TableCell>
        {/* <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles">
            {data?.royaltyCommission ? `${data?.royaltyCommission} $` : "0"}
            {
            data?.royaltyCommission
              ? `(${(data?.royaltyCommission * fiatValue).toFixed(
                  2
                )} MATIC)`
              : ""
          }
          </div>
        </TableCell> */}
        <TableCell style={{ width: `${tableColumnWidth[7]}%` }}>
          <div className="columnStyles">
            {data?.platformCommission
              ? data?.platformCommission % 1 !== 0
                ? data?.platformCommission?.toFixed(4)
                : data?.platformCommission
              : "0"}{" "}
            ${" "}
            {data?.platformCommission
              ? `(${data?.platformCommission % 1 !== 0
                ? data?.platformCommission?.toFixed(4)
                : data?.platformCommission} USDC)`
              : ""}
            {/* {data?.ethBalanceAfterCommission
              ? `(${(data?.platformCommission * fiatValue).toFixed(3)} MATIC)`
              : ""} */}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[7]}%` }}>
          <div className="columnStyles">
            {data?.royaltyCommission
              ? data?.royaltyCommission % 1 !== 0
                ? data?.royaltyCommission?.toFixed(4)
                : data?.royaltyCommission
              : "0"}{" "}
            ${" "}
            {data?.royaltyCommission
              ? `(${(data?.royaltyCommission).toFixed(3)} USDC)`
              : ""}
            {/* {data?.royaltyCommission
              ? `(${(data?.royaltyCommission * fiatValue).toFixed(3)} MATIC)`
              : ""} */}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[8]}%` }}>
          <div className="columnStyles">
            {data?.orderTx ? (
              <div className="columnStyles-sm columnStyles">
                <a
                  href={`${polygon}${data?.orderTx}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="viewOnPolygonScan"
                >
                  View
                </a>
              </div>
            ) : (
              <div className="columnStyles-sm columnStyles">-</div>
            )}
          </div>
        </TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <ExpandedRow
                rowData={data}
                setShowLoader={(e) => setShowLoader(e)}
                showLoader={showLoader}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {columns?.map(({ name, field, sortable, image }) => (
          <TableCell
            align={columns.align}
            key={name}
            className={classes.cursorDefault}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function CommissionListing({ showLoader }) {
  const classes = useRowStyles();
  const [sorting, setSorting] = useState({ field: "id", order: "desc" });
  //const [tableData, setTableData] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [commissionList, setCommissionList] = useState(null);
  const [totalCount, setTotalCount] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filterSelected, setFilterSelected] = useState("");
  const [isDateFilterEnabled, setIsDateFilterEnabled] = useState(null);
  const token = JSON.parse(localStorage.getItem("token"));
  const [totalPlatformCommission, setTotalPlatformCommission] = useState(0);
  const [totalSale, setTotalSale] = useState(0);

  // Date conversation for DateRange filer
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let day = today.getDate();
  // let date1MonthBefore = new Date(year, month - 1, day);
  let date1DayBefore = new Date(year, month, day - 1);

  const [selectionRange, setSelectionRange] = useState({
    startDate: date1DayBefore,
    endDate: new Date(),
    key: "selection",
  });
  const fiatValue = useSelector((state) => state?.adminReducer?.cryptoValue);
  // const header = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // const headers = {
  //   Authorization: `Bearer ${token}`,
  // };

  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    },
  };

  const tableColumnWidth = [10, 10, 10, 10, 10, 10,10, 10, 10, 10];
  const columns = [
    {
      name: "Date",
      field: "date",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Asset",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Order ID",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "From Address",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "To Address",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Unit Price",
      field: "unitPrice",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "Balance After Commission",
      field: "balanceAfterCommission",
      // image: Divider,
      numeric: false,
      sortable: false,
      align: "left",
    },
    // {
    //   name: "Royality Commission",
    //   field: "royalityCommission",
    //   // image: Divider,
    //   numeric: false,
    //   sortable: false,
    //   align: "left",
    // },
    {
      name: "Platform Commission",
      field: "platformCommission",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Royalty Commission",
      field: "royaltyCommission",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "View",
      field: "view",
      // image: Divider,
      numeric: true,
      sortable: false,
      align: "left",
    },
  ];

  useEffect(() => {
    getCommissionList();
    // getCommissionDisplayInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, filterSelected, selectionRange]);

  useDebounce(
    () => {
      if (searchText != null && searchText != "" && searchText != undefined) {
        getCommissionList();
        // getCommissionDisplayInfo();
      }
    },
    [searchText],
    1000
  );

  // const handleChangePage = (event, nextPageNo) => {
  //   setPageNo(nextPageNo);
  //   window.scrollTo(0, 0);
  // };

  const handleChangePage = (event, value) => {
    setPageNo(value);
    window.scrollTo(0, 0);
  };

  const getCommissionList = async () => {
    let urlParam = {
      params: {
        page: pageNo,
        sortBy: sorting.field,
        limit: 10,
        sorting: sorting.order,
        search: searchText,
        status: filterSelected != -1 ? filterSelected : "",
        fromDate: isDateFilterEnabled ? selectionRange?.startDate : "",
        toDate: isDateFilterEnabled ? selectionRange?.endDate : "",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axiosInstance
      .get("/v1/admin/commissions/order/list", urlParam)
      .then((result) => {
        setCommissionList(result?.data?.result?.orders);
        setTotalCount(result?.data?.result?.totalPages);
        setTotalPlatformCommission(
          result?.data?.result?.totalPlatformCommission
        );
        setTotalSale(result?.data?.result?.totalSale);
      })
      .catch((err) => {
        if (
          err?.response &&
          err?.response?.data?.errors &&
          err?.response?.data?.errors?.length !== 0
        ) {
          Swal.fire({
            title: "Oops!",
            text: err?.response?.data?.errors.map((err) => err.msg),
            icon: "error",
          });
        } else {
          Swal.fire("Oops!", err?.response?.data?.message, "error");
        }
      });
  };

  // handle date range changes

  const handleSelect = (ranges) => {
    setSelectionRange(ranges?.selection);
    setPageNo(1);
  };

  // set search text without space
  const handleSearchBoxKeyDown = (e) => {
    if (e.keyCode == 13) {
      setSearchText(e.target.value);
    }
  };

  //get category collection

  //   const getCommissionDisplayInfo = async () => {
  //     await axiosInstance
  //       .get("v1/admin/total/commissions/sale", config)
  //       .then((res) => {
  //         // setCategoryList(res?.data?.result?.assets);
  //         setTotalPlatformCommission(res?.data?.result?.totalPlatformCommission);
  //         setTotalSale(res?.data?.result?.totalSale);
  //       })
  //       .catch((err) => {
  //         if (
  //           err?.response &&
  //           err?.response?.data?.errors &&
  //           err?.response?.data?.errors?.length > 1
  //         ) {
  //           Swal.fire({
  //             title: "Oops!",
  //             text: err?.response?.data?.errors.map((err) => err.msg),
  //             icon: "error",
  //           });
  //         } else {
  //           Swal.fire("Oops!", err?.response?.data?.message, "error");
  //         }
  //       });
  //   };

  const downloadReport = () => {
    // Caluculating startDate and endDate to pass to backend

    let startDate = new Date(
      selectionRange?.startDate?.getTime() -
        selectionRange?.startDate?.getTimezoneOffset() * 60000
    ).toISOString();

    let endDate = new Date(
      selectionRange?.endDate?.getTime() -
        selectionRange?.endDate?.getTimezoneOffset() * 60000
    ).toISOString();
    //To set endDate time last minute of the day
    // endDate = moment(endDate).add(1, "days");
    endDate = moment(endDate);

    // Data Range library issue while selecting yesterday, Below bypass time correction for dates selected from sidemenu (eg: select yesterday from side bar of dataRange interface)
    if (moment(selectionRange?.endDate).format("ss") != 59) {
      endDate = moment(endDate).add(1, "days");
    }

    endDate.set({ hour: 5, minute: 29, second: 59, millisecond: 59 });
    endDate = endDate.toISOString();

    // const url = `${baseURL}v1/user/csv/download`;
    const url = `${baseURL}v1/admin/commissions/download?page=${pageNo}&limit=200&fromDate=${
      isDateFilterEnabled ? startDate : ""
    }&toDate=${isDateFilterEnabled ? endDate : ""}&search=${encodeURIComponent(
      searchText
    )}`;
    window.open(url, "_blank");
  };

  return (
    <div className="table_div tableWidth100 paddingBottom66px">
      {showLoader == true ? <Loader /> : null}

      <div className="searchBoxProduct">
        {/* <div
          className="CommissionBlueBox"
          style={{ width: "18em", background: "#FDEFE8" }}
        >
          <div className="boxLabel">Total Platform Commission</div>
          <div className="boxContent">{totalPlatformCommission} USD</div>
        </div>
        <div className="CommissionBlueBox">
          <div className="boxLabel">Total Sale</div>
          <div className="boxContent">{totalSale} USD</div>
        </div> */}
        <div style={{display:"flex",justifyContent:"center",flexDirection:"column",backgroundColor:"#3f51b51c",padding:"0 15px",borderRadius:"5px"}}>
          <p className="mb-0 total-value-p">
            Total Commission : <span>{totalPlatformCommission} USD</span>
          </p>
          <p  className="mb-0 total-value-p">
            Total Sale : <span>{totalSale} USD</span>
          </p>
        </div>
        <div className="flexingWrapper">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<CloudDownloadIcon>Report</CloudDownloadIcon>}
            onClick={() => downloadReport()}
          >
            Report
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setSelectionRange({
                startDate: date1DayBefore,
                endDate: new Date(),
                key: "selection",
              });
              setIsDateFilterEnabled(false);
              // setStatusFilterSelected(-1);
              setSearchText("");
              setFilterSelected(-1);
              setPageNo(1);
            }}
          >
            Reset Filter
          </Button>
          <div
            className="height55px displayFlex"
            onClick={() => {
              setIsDateFilterEnabled(true);
              setPageNo(1);
            }}
          >
            <DataRangePopOver
              selectionRange={selectionRange}
              setSelectionRange={setSelectionRange}
              handleSelect={handleSelect}
            />
          </div>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
            <Select
              native
              value={filterSelected}
              onChange={(e) => {
                setFilterSelected(e.target.value);
                setPageNo(1);
              }}
              label="Filter"
              inputProps={{
                name: "Filter",
                id: "outlined-age-native-simple",
              }}
              IconComponent={FilterListIcon}
              displayEmpty={true}
            >
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                  clipRule="evenodd"
                />
              </svg>
              <optgroup label="Status">
                <option value={"-1"}>All</option>
                <option value={"success"}>Success</option>
                <option value={"pending"}>Pending</option>
                <option value={"failed"}>Failed</option>
              </optgroup>
            </Select>
          </FormControl>
          <Autocomplete
            id="search-box-product"
            options={searchSuggestions}
            inputValue={searchText}
            disableClearable={true}
            clearOnBlur={false}
            getOptionLabel={(option) => {
              return option.name || "";
            }}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                // value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setPageNo(1);
                }}
                onKeyDown={(e) => handleSearchBoxKeyDown(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                label="Search"
                variant="outlined"
              />
            )}
          />
        </div>
      </div>
      {commissionList?.length !== 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className={classes.root}>
              <RenderHeader
                columns={columns}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <TableBody style={{ width: "100%" }}>
                {commissionList?.map((row, index) => (
                  <Row
                    aria-label="expand row"
                    size="small"
                    key={row?.id}
                    row={row}
                    setSelectedRow={(value) => setSelectedRow(value)}
                    selectedRow={selectedRow}
                    data={commissionList[index]}
                    tableColumnWidth={tableColumnWidth}
                    setOpenEdit={setOpenEdit}
                    fiatValue={fiatValue}
                    // setShowLoader={setShowLoader}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalCount}
            page={pageNo}
            onChange={handleChangePage}
          />
        </>
      ) : (
        <div className="noResultFound flexColumnCenter">
          <NoRecordsFound message="No records found." />
        </div>
      )}
    </div>
  );
}
