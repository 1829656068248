/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import "react-confirm-alert/src/react-confirm-alert.css";

// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";

import Loader from "../common/Loader/Loader";
import { axiosInstance } from "../../utility/api";
import DataRangePopOver from "../common/DateRange/DateRangePopOver";
import { formatDateTime } from "../common/date";
import NoRecordsFound from "../common/Error/NoRecordsFound";
import { useDebounce } from "../../hooks/useDebounce";
import { handleAPIError } from "../../utility/helper";

const useRowStyles = makeStyles({
	root: {
		marginTop: "0px",
		border: "none",
		width: "100%",
	},
	header: {
		backgroundColor: "#F5F5FC",
	},
	rowDatas: {
		"& td,th": {
			border: "none",
			height: "90px",
		},
	},
	border__right: {
		borderRight: "2px solid white",
		height: "70%",
	},
	cursorDefault: {
		cursor: "default",
	},
});

//Each row of table
function Row(props) {
	const { data, tableColumnWidth } = props;
	const [open, setOpen] = React.useState(false);
	//const [deleteCategoryID, setDeleteCategoryID] = useState(null);
	// const token = JSON.parse(localStorage.getItem("token"));
	const classes = useRowStyles();

	// const handleDelete = (id) => {
	//   const config = {
	//     headers: {
	//       Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
	//     },
	//   };
	//   axiosInstance
	//     .delete(`/v1/admin/category/${id}`, config)
	//     .then((res) => {
	//       Swal.fire("Success!", res?.data?.message, "success");
	//     })
	//     .then(() => {
	//       window.location.reload(true);
	//     })
	//     .catch((err) => {
	//       console.log("rrr eror", err?.response.data?.message);
	//       Swal.fire("Error!", err?.response?.data?.message, "error");
	//     });
	// };

	return (
		<React.Fragment>
			<TableRow
				onClick={() => {
					setOpen(!open);
				}}
				className={classes.rowDatas}
				style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
			>
				<TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
					<div className="columnStyles">{data?.id}</div>
				</TableCell>
				<TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
					<div className="columnStyles" style={{ wordBreak: "break-all" }}>
						{data?.currentBidder ? data?.currentBidder : "No bids received."}
					</div>
				</TableCell>

				<TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
					<div className="columnStyles">{data?.asset?.name} {data.status=="expired"&& `${data?.asset?.currentEdition}/${data?.asset?.totalEdition}`}</div>
				</TableCell>
				<TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
					<div className="columnStyles">
						{data?.currentBid ? `${data?.currentBid} ($)` : "-"}
					</div>
				</TableCell>
				<TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
					<div className="columnStyles">
						{data?.user?.userName ? data?.user?.userName : "-"}
					</div>
				</TableCell>
				<TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
					<div className="columnStyles">
						{data?.openingPrice ? `${data?.openingPrice} ($)` : "-"}
					</div>
				</TableCell>
				<TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
					<div className="columnStyles">
						{data?.asset?.currentOwner_?.userName ? data?.asset?.currentOwner_?.userName : "-"}
					</div>
				</TableCell>
				<TableCell
					style={{
						width: `${tableColumnWidth[7]}%`,
						textTransform: "capitalize",
					}}
				>
					<div className="columnStyles">
						{data?.status ? data?.status : "-"}
					</div>
				</TableCell>
				<TableCell
					style={{
						width: `${tableColumnWidth[8]}%`,
						textTransform: "capitalize",
					}}
				>
					<div className="columnStyles">
						{data?.startDate && formatDateTime(data?.startDate)}
					</div>
				</TableCell>
				<TableCell style={{ width: `${tableColumnWidth[9]}%` }}>
					<div className="columnStyles">
						{data?.endingDate && formatDateTime(data?.endingDate)}
					</div>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

const RenderHeader = ({ columns, onSorting, tableColumnWidth }) => {
	const [sortingField, setSortingField] = useState("");
	const [sortingOrder, setSortingOrder] = useState("asc");

	const onSortingChange = (field) => {
		const order =
			field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
		setSortingField(field);
		setSortingOrder(order);
		onSorting(field, order);
	};

	const classes = useRowStyles();
	return (
		<TableHead className={classes.header}>
			<TableRow>
				{columns?.map(({ name, field, sortable, image }, index) => (
					<TableCell
						align={columns.align}
						key={name}
						style={{ width: tableColumnWidth[index] }}
						className={classes.cursorDefault}
					>
						<div className="dividerFlex">
							<div
								style={{
									display: "flex",
									justifyContent: "left",
									alignItems: "center",
									fontWeight: "600",
									fontSize: "12px",
									cursor: sortable ? "pointer" : "default",
								}}
								onClick={() => (sortable ? onSortingChange(field) : null)}
							>
								{name}
								{/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
							</div>
						</div>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default function AuctionTable({ data, showLoader, setShowLoader }) {
	const classes = useRowStyles();
	const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
	const [totalCount, setTotalCount] = useState(0);
	const [auctionList, setAuctionList] = useState([]);
	const [openEdit, setOpenEdit] = useState(false);
	const [pageNo, setPageNo] = useState(1);

	const [searchSuggestions, setSearchSuggestions] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [filterSelected, setFilterSelected] = useState([]);
	const [isDateFilterEnabled, setIsDateFilterEnabled] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const token = JSON.parse(localStorage.getItem("token"));
	const header = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	// Date conversation for DateRange filer
	let today = new Date();
	let year = today.getFullYear();
	let month = today.getMonth();
	let day = today.getDate();
	//let date1MonthBefore = new Date(year, month - 1, day);
	let date1DayBefore = new Date(year, month, day - 1);
	const [selectionRange, setSelectionRange] = useState({
		startDate: date1DayBefore,
		endDate: new Date(),
		key: "selection",
	});

	const tableColumnWidth = [6, 13, 13, 12, 10, 7, 8, 5, 12, 12];
	const columns = [
		{
			name: "Id",
			field: "id",
			// image: Divider,
			numeric: false,
			sortable: false,
			align: "left",
		},
		{
			name: "Highest Bidder",
			field: "lastBider",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
		{
			name: "Asset Name",
			field: "assetName",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
		{
			name: "Highest Bid",
			field: "currentBid",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
		{
			name: "Highest Bidder Name",
			field: "higestBidderName",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
		{
			name: "Reserve Price",
			field: "OpeningPrice",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
		{
			name: "Creator",
			field: "creator",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
		{
			name: "Status",
			field: "status",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
		{
			name: "Start Date",
			field: "action",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
		{
			name: "End Date",
			field: "action",
			// image: Divider,
			numeric: true,
			sortable: false,
			align: "left",
		},
	];

	useEffect(() => {
		getAuctionList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNo, filterSelected, selectionRange, isDateFilterEnabled]);

	useDebounce(
		() => {
			if (searchText != null && searchText != "" && searchText != undefined) {
				getAuctionList();
			}
		},
		[searchText],
		1000
	);

	// const handleChangePage = (event, nextPageNo) => {
	//   setPageNo(nextPageNo);
	//   window.scrollTo(0, 0);
	// };

	const handleChangePage = (event, value) => {
		setPageNo(value);
		window.scrollTo(0, 0);
	};

	const handleSearchBoxKeyDown = (e) => {
		if (e.keyCode == 13) {
			setSearchText(e.target.value);
		}
	};

	const handleSelect = (ranges) => {
		setSelectionRange(ranges?.selection);
		setPageNo(1);
	};

	const getAuctionList = async () => {
		//Date Ranger Date convertions

		let startDate = new Date(
			selectionRange?.startDate?.getTime() -
				selectionRange?.startDate?.getTimezoneOffset() * 60000
		).toISOString();
		let endDate = new Date(
			selectionRange?.endDate?.getTime() -
				selectionRange?.endDate?.getTimezoneOffset() * 60000
		).toISOString();

		// Data Range library issue while selecting yesterday, Below bypass time correction for dates selected from sidemenu (eg: select yesterday from side bar of dataRange interface)
		if (moment(selectionRange?.endDate).format("ss") != 59) {
			endDate = moment(endDate).add(1, "days");
		}
		//To set endDate time last minute of the day
		// endDate = moment(endDate).add(1, "days");
		endDate = moment(endDate);
		endDate.set({ hour: 5, minute: 29, second: 59, millisecond: 59 });
		endDate = endDate.toISOString();

		//Url Parameter for the api
		let payload = {
			page: pageNo,
			limit: 10,
			search: searchText,
			fromDate: isDateFilterEnabled ? startDate : "",
			toDate: isDateFilterEnabled ? endDate : "",
			status: filterSelected == 0 ? "" : filterSelected,
		};
		// &auctionStatus=all

		await axiosInstance
			.get(
				`/v1/admin/asset/auctions?${Object.keys(payload)
					.map((key) => key + "=" + payload[key])
					.join("&")}`,
				header
			)
			.then((result) => {
				console.log("result", result);
				setAuctionList(result?.data?.result?.auctions);
				setTotalCount(result?.data?.result?.totalPages);
			})
			.catch((err) => {
				let respErr = handleAPIError(err)
				Swal.fire("Oops!", respErr, "error");
			});
	};

	return (
		<div className="table_div tableWidth100">
			{showLoader == true ? <Loader /> : null}
			<div className="searchBoxProduct">
				<Button
					variant="outlined"
					onClick={() => {
						setSelectionRange({
							startDate: date1DayBefore,
							endDate: new Date(),
							key: "selection",
						});
						setIsDateFilterEnabled(false);
						// setStatusFilterSelected(-1);
						setSearchText("");
						setFilterSelected(0);
						setPageNo(1);
					}}
				>
					Reset Filter
				</Button>
				<div
					className="height55px displayFlex"
					onClick={() => {
						setIsDateFilterEnabled(true);
						setPageNo(1);
					}}
				>
					<DataRangePopOver
						selectionRange={selectionRange}
						setSelectionRange={setSelectionRange}
						handleSelect={handleSelect}
					/>
				</div>
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
					<Select
						native
						value={filterSelected}
						onChange={(e) => {
							setFilterSelected(e.target.value);
							setPageNo(1);
						}}
						label="Filter"
						inputProps={{
							name: "Filter",
							id: "outlined-age-native-simple",
						}}
						IconComponent={FilterListIcon}
						displayEmpty={true}
					>
						<svg
							className="w-6 h-6"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
								clipRule="evenodd"
							/>
						</svg>
						<optgroup label="Status">
							<option value={"0"}>All</option>
							<option value={"active"}>Active</option>
							<option value={"expired"}>Expired</option>
						</optgroup>
					</Select>
				</FormControl>
				<Autocomplete
					id="search-box-product"
					options={searchSuggestions}
					inputValue={searchText}
					disableClearable={true}
					clearOnBlur={false}
					getOptionLabel={(option) => {
						return option.name || "";
					}}
					style={{ width: 300 }}
					renderInput={(params) => (
						<TextField
							{...params}
							// value={searchText}
							onChange={(e) => {
								setSearchText(e.target.value);
								setPageNo(1);
							}}
							onKeyDown={(e) => handleSearchBoxKeyDown(e)}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							label="Search Asset"
							variant="outlined"
						/>
					)}
				/>
			</div>
			{auctionList?.length !== 0 ? (
				<>
					<TableContainer component={Paper}>
						<Table aria-label="collapsible table" className={classes.root}>
							<RenderHeader
								columns={columns}
								onSorting={(field, order) => setSorting({ field, order })}
								tableColumnWidth={tableColumnWidth}
							/>
							<TableBody style={{ width: "100%" }}>
								{auctionList?.map((row, index) => (
									<Row
										aria-label="expand row"
										size="small"
										key={row?.id}
										row={row}
										setSelectedRow={(value) => setSelectedRow(value)}
										selectedRow={selectedRow}
										data={auctionList[index]}
										tableColumnWidth={tableColumnWidth}
										setOpenEdit={setOpenEdit}
										// setShowLoader={setShowLoader}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Pagination
						count={totalCount}
						page={pageNo}
						onChange={handleChangePage}
					/>
					{/* <TablePagination
            rowsPerPageOptions={[]}
            // backIconButtonProps={{ disabled: false }}
            component="div"
            count={totalCount}
            rowsPerPage={10}
            page={pageNo}
            onChangePage={handleChangePage}
            // onChange={(e) => handleChangePage(e)}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              <>
                <span className="paginationLabel">Showing </span>
                {`${from}-${to}`}
                <span className="paginationLabel"> out of </span>
                {`${count}`}
              </>
            )}
          /> */}
				</>
			) : (
				<div className="noResultFound flexColumnCenter">
					<NoRecordsFound message="No records found." />
				</div>
			)}
		</div>
	);
}
