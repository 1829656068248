import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Edit } from "react-feather";
import { useSelector, useDispatch } from "react-redux";

import TextField from "@material-ui/core/TextField";
import { FormHelperText } from "@material-ui/core";

import styles from "./EditProfile.module.scss";
import { axiosInstance } from "../../../utility/api";
import { isSpclChar } from "../../common/inputUtilities";
import Loader from "../../common/Loader/Loader";
import {
	SAVE_PROFILE_INFO,
	SAVE_PROFILE_PIC,
	//SAVE_NAME,
} from "../../../redux/actionTypes";

const EditProfile = () => {
	const dispatch = useDispatch();
	const config = {
		headers: {
			Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
		},
	};
	//const adminId = useSelector((state) => state.adminReducer.adminId);
	const adminAccountDetails = useSelector((state) => state.adminReducer);
	const [isSubmitted, setIsSubmitted] = React.useState(false);
	const [editEmail, setEditEmail] = React.useState(false);
	const [formValue, setFormValue] = useState({
		firstName: "",
		lastName: "",
		email: "",
		thumbnailImageUrl: "",
	});
	const [showLoader, setShowLoader] = useState(false);
	//const [profilePic, setProfilePic] = React.useState(null);

	useEffect(() => {
		if (adminAccountDetails?.userId) {
			console.log({ adminAccountDetails });
			setFormValue({
				...formValue,
				firstName: adminAccountDetails?.userId?.firstName,
				lastName: adminAccountDetails?.userId?.lastName,
				email: adminAccountDetails?.userId?.email,
				thumbnailImageUrl: adminAccountDetails?.userId?.thumbnailImageUrl,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adminAccountDetails?.userId]);

	const uploadFile = (e) => {
		setShowLoader(true);
		// const config = getConfig();
		// const pic = document.getElementById("profile_pic");
		let files = e.target.files[0];
		let formData = new FormData();
		formData.append("file", files);
		formData.append("position", 2);
		axiosInstance
			.post("/v1/admin/upload/bucket", formData, config)
			.then((res) => {
				setShowLoader(false);
				Swal.fire("Great!", "Profile Pic successfully uploaded", "success");
				setFormValue({
					...formValue,
					thumbnailImageUrl: res?.data?.result?.previewUrl,
				});
				dispatch({
					type: SAVE_PROFILE_PIC,
					payload: res?.data?.result?.previewUrl,
				});
			})
			.catch((err) => {
				setShowLoader(false);
				Swal.fire("Oops!", err?.response?.data?.message, "error");
			});
	};
	const handleImageUpload = (e) => {
		document.getElementById("my_file").click();
	};

	const handleProfileFormChange = (event) => {
		setFormValue({ ...formValue, [event.target.name]: event.target.value });
	};

	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};
	const saveProfileChanges = () => {
		setIsSubmitted(true);
		if (!formValue?.firstName) {
			Swal.fire("Oops!", "Enter a valid first name", "error");
			return false;
		} else if (!formValue?.lastName) {
			Swal.fire("Oops!", "Enter a valid last name", "error");
			return false;
		} else if (!formValue?.email || !validateEmail(formValue?.email)) {
			Swal.fire("Oops!", "Enter a valid Email", "error");
			return false;
		} else if (isSpclChar(formValue?.firstName)) {
			Swal.fire(
				"Oops!",
				"Special Characters not allowed. Enter a valid first name.",
				"error"
			);
			return false;
		} else if (isSpclChar(formValue?.lastName)) {
			Swal.fire(
				"Oops!",
				"Special Characters not allowed. Enter a valid last name.",
				"error"
			);
			return false;
		}
		if (
			(formValue?.firstName !== "" ||
				formValue?.firstName !== undefined ||
				formValue?.firstName !== null) &&
			(formValue?.lastName !== "" ||
				formValue?.lastName !== undefined ||
				formValue?.lastName !== null) &&
			(formValue?.email !== "" ||
				formValue?.email !== undefined ||
				formValue?.email !== null)
		) {
			axiosInstance
				.patch(`/v1/admin/my-profile`, formValue, config)
				.then((res) => {
					console.log("just", res, formValue);
					dispatch({
						type: SAVE_PROFILE_INFO,
						payload: {
							firstName: formValue.firstName,
							lastName: formValue.lastName,
							email: formValue?.email,
						},
					});
					Swal.fire("Success!", "Profile updated!", "success");
					setIsSubmitted(false);
				})
				.catch((err) => {
					Swal.fire("Error!", err?.response?.data?.message, "error");
				});
		}
	};
	const handleEmailEdit = (e) => {
		e.preventDefault();
		setEditEmail(!editEmail);
	};
	// const logout = () => {
	//   axiosInstance
	//     .get(`/v1/admin/auth/logout`, config)
	//     .then((result) => {
	//       console.log("logout");
	//       //  dispatch({
	//       //   type: SAVE_TOKEN,
	//       //   payload: null,
	//       // });
	//       localStorage.removeItem("token");
	//       window.location.href = `${process.env.PUBLIC_URL}/sign-in`;
	//     })
	//     .catch((err) => {
	//       console.log("logout error", err);
	//     });
	// };
	return (
		<div className={styles.edit_profile_container}>
			{showLoader && (
				<Loader loaderInfo={"Uploading your files, please wait."} />
			)}
			<h3>Edit profile</h3>
			<div className={styles.edit_profile_form_wrap}>
				<form>
					<TextField
						className="text_field"
						id="outlined-basic"
						label="First name"
						variant="outlined"
						name="firstName"
						type="text"
						style={
							isSubmitted && formValue?.firstName?.length === 0
								? { marginBottom: "0px" }
								: { marginBottom: "10px" }
						}
						value={formValue?.firstName}
						onChange={handleProfileFormChange}
						required={true}
					/>
					{isSubmitted && formValue?.firstName?.length === 0 ? (
						<FormHelperText error>Enter a valid first name</FormHelperText>
					) : (
						<></>
					)}
					<TextField
						label="Last Name"
						variant="outlined"
						name="lastName"
						style={
							isSubmitted && formValue?.firstName?.length === 0
								? { marginBottom: "0px" }
								: { marginBottom: "10px" }
						}
						value={formValue?.lastName}
						onChange={handleProfileFormChange}
						required
					/>
					{isSubmitted && formValue?.lastName?.length === 0 ? (
						<FormHelperText error>Enter last name</FormHelperText>
					) : (
						<></>
					)}
					<div className="w100" style={{ position: "relative" }}>
						<TextField
							label="E-mail"
							variant="outlined"
							name="email"
							type="email"
							value={formValue?.email}
							onChange={handleProfileFormChange}
							required
							disabled={!editEmail}
							className="w100"
						/>
						<button
							className={`btn btn-primary ${styles.edit_icon_btn}`}
							onClick={handleEmailEdit}
						>
							<Edit className={styles.edit_icon} />
						</button>
						{isSubmitted && formValue?.email?.length === 0 ? (
							<FormHelperText error>Enter valid email</FormHelperText>
						) : (
							<></>
						)}
					</div>
				</form>
				<div className={styles.uploadPic}>
					<img
						id="profile_pic"
						alt="profile.png"
						src={
							adminAccountDetails?.profilePicture
								? adminAccountDetails?.profilePicture
								: formValue?.thumbnailImageUrl
								? formValue?.thumbnailImageUrl
								: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
						}
						onClick={handleImageUpload}
					/>
					<input
						type="file"
						id="my_file"
						style={{ display: "none" }}
						onChange={uploadFile}
						accept="image/*"
					/>
					<button onClick={handleImageUpload} className={"button-small"}>
						Upload Image
					</button>
					<div style={{ display: "flex", justifyContent: "center" }}>
						Allowed file types : .png , .jpg
					</div>
				</div>
			</div>

			<div
				style={{ display: "flex", justifyContent: "end", marginTop: "40px" }}
			>
				<button
					className="btn btn-primary"
					style={{ width: "213px" }}
					onClick={saveProfileChanges}
				>
					Save Changes
				</button>
			</div>
		</div>
	);
};

export default EditProfile;
