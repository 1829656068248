import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { axiosInstance } from "../../utility/api";
import "./EditUserForm.css";

export default function EditUserForm({ selectedRow, isEdit }) {
	const [status, setStatus] = useState("");
	const [lastName, setLastName] = useState("");
	const [firstName, setFirstName] = useState("");
	const [userName, setUserName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	// const statusList = ["verified", "unverified"];
	const config = {
		headers: {
			Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
		},
	};

	useEffect(() => {
		if (selectedRow) {
			setStatus(selectedRow?.status);
			setFirstName(selectedRow?.firstName);
			setLastName(selectedRow?.lastName);
			setUserName(selectedRow?.userName);
			setEmail(selectedRow?.email);
		}
	}, [selectedRow]);

	const handleEditForm = async (e) => {
		e.preventDefault();
		// this.setState({
		//   error: { status: false, message: "" },
		// });

		// this.setShowLoader(true, "Please Wait.");
		if (!firstName || !lastName || !userName) {
			Swal.fire("Oops!", "Please enter the mandatory fields", "error");
			return;
		}
		let data = {
			firstName: firstName,
			lastName: lastName,
			userName: userName,
			status: status,
		};
		await axiosInstance
			.patch(`/v1/admin/user/${selectedRow?.id}`, data, config)
			.then((res) => {
				console.log("posted");
				// this.setState({
				//   ...this.state,
				//   showLoader: false,
				// });
				// this.setShowLoader(false, "");
				// this.props.onClose();
				Swal.fire("Success!", res?.data?.message, "success");
				window.location.reload(true);
				// Swal.fire("Success!", res?.data?.message, "success");
			})
			.catch((err) => {
				Swal.fire("Oops!", err?.data?.message, "error");
				// this.setShowLoader(false, "");
			});
	};

	const handleAddForm = async (e) => {
		e.preventDefault();

		// if (!firstName || !lastName || !userName) {
		//   Swal.fire("Oops!", "Please enter the mandatory fields", "error");
		//   return;
		// }
		let data = {
			firstName: firstName,
			lastName: lastName,
			userName: userName,
			status: status,
			email: email,
			password: password,
		};
		await axiosInstance
			.post(`/v1/admin/user`, data, config)
			.then((res) => {
				console.log("posted");
				// this.setState({
				//   ...this.state,
				//   showLoader: false,
				// });
				// this.setShowLoader(false, "");
				// this.props.onClose();
				window.location.reload(true);
				Swal.fire("Success!", res?.data?.message, "success");
			})
			.catch((err) => {
				console.log("rrr Error", err?.data);
				Swal.fire("Error!", err?.data?.message, "success");
				// this.setShowLoader(false, "");
			});
	};

	const handleChange = (event) => {
		switch (event.target.name) {
			case "firstName":
				setFirstName(event.target.value);
				break;

			case "lastName":
				setLastName(event.target.value);
				break;
			case "userName":
				setUserName(event.target.value);
				break;
			case "status":
				setStatus(event.target.value);
				break;
			case "email":
				setEmail(event.target.value);
				break;
			case "password":
				setPassword(event.target.value);
				break;

			default:
				break;

			// case "userName":
			//   setUserName(event.target.value);
			//   break;￼

			// case "status":
			//   setStatus(event.target.value);
			//   break;
		}
	};

	return (
		<div className="col-xl-12 width30vw">
			<div className="container col-xl-12">
				<div className="row col-12">
					<div className="col-12">
						<h2 className="tm-block-title d-inline-block">
							{selectedRow ? "Edit" : "Add"} Users
						</h2>
					</div>
				</div>
			</div>
			{/* <div className="row tm-edit-product-row"> */}
			<div className="col-xl-6 col-lg-6 col-md-12">
				<form action="" className="col-md-12">
					<div className="form-group">
						<label htmlFor="name">First Name</label>
						<span className="colorRed">*</span>
						<input
							type="text"
							name="firstName"
							className="form-control validate"
							value={firstName}
							onChange={(e) => handleChange(e)}
							required
						/>
					</div>
					<div className="form-group mb-12">
						<label htmlFor="name">Last Name</label>
						<span className="colorRed">*</span>
						<input
							type="text"
							name="lastName"
							className="form-control validate"
							value={lastName}
							onChange={(e) => handleChange(e)}
							required
						/>
					</div>
					<div className="form-group mb-12">
						<label htmlFor="name">User Name</label>
						<span className="colorRed">*</span>
						<input
							type="text"
							name="userName"
							className="form-control validate"
							value={userName}
							onChange={(e) => handleChange(e)}
							required
						/>
					</div>
					<div className="form-group mb-12">
						<label htmlFor="name">Email</label>
						<span className="colorRed">*</span>
						<input
							type="text"
							name="email"
							className="form-control validate"
							value={email}
							disabled={true}
							onChange={(e) => handleChange(e)}
							required
						/>
					</div>
					{/* <div className="form-group mb-12">
            <label htmlFor="name">Password</label>
            <span className="colorRed">*</span>
            <input
              type="text"
              name="password"
              className="form-control validate"
              value={password}
              onChange={(e) => handleChange(e)}
              required
            />
          </div> */}
					{/* <div className="form-group mb-12">
            <label htmlFor="name">Status</label>
            <select
              className="custom-select tm-select-accounts"
              name="status"
              id="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              required
            >
              <option value="" selected>
                Select
              </option>
              {statusList?.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </div> */}
					<div className="form-group mb-12">
						<button
							type="submit"
							className="btn btn-primary btn-block text-uppercase"
							onClick={selectedRow ? handleEditForm : handleAddForm}
						>
							Save
						</button>
					</div>
				</form>
			</div>
			{/* </div> */}
		</div>
	);
}
