/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ReactPlayer from "react-player";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "../common/Loader/Loader";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Swal from "sweetalert2";
import axios from "axios";
import Button from "@material-ui/core/Button";

import { axiosInstance } from "../../utility/api";
import SearchIcon from "@material-ui/icons/Search";
import { formatDateTime } from "../common/date";
import FilterListIcon from "@material-ui/icons/FilterList";
import decryptFunction from "../common/decryptURL";
import DataRangePopOver from "./DateRange/DateRangePopOver";

import "./tableExpandable.css";
import ExpandedRow from "./Table/Components/tableExpandedRow-MaterialUI";
import NoRecordsFound from "../common/Error/NoRecordsFound";
import Pagination from "@material-ui/lab/Pagination";
import decryptURl from "../common/decryptURL";
import { useHistory } from 'react-router-dom'
import moment from "moment";



const useRowStyles = makeStyles({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
  tableRoot: {
    overflowX: "scroll !important",
    overflowY: "hidden",
  },
});

//Each row of table
function Row(props) {
  const { data, tableColumnWidth, setShowLoader, showLoader, selectedTab } =
    props;
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  // const [videoThumbnail, setVideoThumbnail] = useState(null);
  const polygon =
    process.env.REACT_APP_NODE_ENV === "development"
      ? process.env.REACT_APP_POLYGON_LINK
      : "https://mumbai.polygonscan.com/";

  // const token = JSON.parse(localStorage.getItem("token"));
  const classes = useRowStyles();

  // To Convert Date
  const getCreatedAt = (date) => {
    let d = formatDateTime(date);
    // let d =  moment.utc(date).local().format('DD/MM/YYYY hh:mm:ss A')
    let index = d?.indexOf(" ");
    console.log("date", d);
    return (
      <>
        {d?.substring(0, index)}{" "}
        <span style={{ whiteSpace: "nowrap" }}>{d?.substring(index + 1)}</span>
      </>
    );
  };
  const viewMetaData = (metaData) => {
    const dataKey = decryptURl(metaData);
    window.open(`https://ipfs.io/ipfs/${dataKey}`, "_blank");
  };
  return (
    <React.Fragment>
      <TableRow
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.rowDatas}
        style={{ borderBottom: "3px solid #F5F5FC", cursor: "pointer" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          {/* <div className="tableCellContainer"> */}
          <div
            className="imgCard"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            {data?.ipfsAudioUrl && (
              <ReactPlayer
                url={data?.ipfsAudioUrl}
                width="120px"
                height="120px"
                controls={true}
                style={{
                  backgroundImage: "url(" + data?.audioThumbnail + ")",
                  backgroundSize: "cover",
                }}
                // onReady={true}
                light={data?.audioThumbnail ? data?.audioThumbnail : true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
                // playIcon="true"
              />
            )}
            {data?.ipfsLongVideoUrl && !data?.ipfsAudioUrl && (
              <ReactPlayer
                url={data?.ipfsLongVideoUrl}
                width="120px"
                height="120px"
                controls={true}
                // onReady={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
                // playIcon="true"
              />
            )}
            {/* <img src={videoThumbnail && videoThumbnail} alt="Image" /> */}
            {data?.ipfsPreviewImageUrl &&
              !data?.ipfsLongVideoUrl &&
              data?.ipfsImageUrl.split(".").pop() != "glb" &&
              data?.ipfsImageUrl.split(".").pop() != "gltf" && (
                <img
                  src={
                    data?.ipfsPreviewImageUrl
                      ? `${data?.ipfsPreviewImageUrl}`
                      : null
                  }
                  alt="Img"
                />
              )}
            {/* {data?.ipfsImageUrl &&
              !data?.ipfsLongVideoUrl &&
              data?.ipfsImageUrl.split(".").pop() == "glb" &&
              data?.ipfsImageUrl.split(".").pop() == "gltf" && (
                <img src={data["3DURL"] ? data["3DURL"] : null} alt="Image" />
              )} */}
            {data?.thumbnailUrl &&
              !data?.ipfsLongVideoUrl &&
              !data?.ipfsAudioUrl && (
                <img
                  src={data["thumbnailUrl"] ? data["thumbnailUrl"] : null}
                  alt="Img"
                />
              )}
            {/* {data?.ipfsAudioUrl && <img src={AudioImg} alt="Image" />} */}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
          <div className="columnStyles">{data?.id ? data?.id : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div className="columnStyles">
            {selectedTab == "assets"
              ? data?.name
              : `${data?.name} #${data?.currentEdition?data?.currentEdition:1}/${data?.totalEdition}`}
          </div>
        </TableCell>
      {!data?.isAirdropNft && <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles">
            {data?.isOnSell
              ? data?.resellPrice
              : data?.saleType == "fixed"
              ? data?.regularPrice
              : data?.auctions[0]?.openingPrice}
          </div>
        </TableCell>}
        <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="columnStyles">
            {data?.isOnSell
              ? getCreatedAt(data?.resellPublishDate)
              : data?.createdAt
              ? getCreatedAt(data?.createdAt)
              : "-"}
          </div>
        </TableCell>
        {data.isAirdropNft && <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="columnStyles">
            {data?.airdropEndDate
              ? getCreatedAt(data?.airdropEndDate)
              : "-"}
          </div>
        </TableCell>}
        {selectedTab == "assets" && (
          <TableCell style={{ width: `${tableColumnWidth[5]}%` }}>
            <div className="columnStyles">
              {data?.availableStock ? data?.availableStock : "-"}
            </div>
          </TableCell>
        )}
        {selectedTab == "assets" && (
          <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
            <div className="columnStyles">
              {data?.availableEdition ? data?.availableEdition : "-"}
            </div>
          </TableCell>
        )}
        {selectedTab == "assets" && (
          <TableCell style={{ width: `${tableColumnWidth[7]}%` }}>
            <div className="columnStyles">
              {data?.totalEdition && data?.totalEdition}
            </div>
          </TableCell>
        )}

          {data?.isAirdropNft && <TableCell style={{ width: `${tableColumnWidth[7]}%` }}>
            <div className="columnStyles">
              {data?.requestCount}
            </div>
          </TableCell>}
          {data?.isAirdropNft && <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
            <div className="columnStyles">
              <Button style ={{textTransform:'capitalize'}} variant = 'outlined'onClick={()=> {history.push({
                                        pathname: `${process.env.PUBLIC_URL}/users/users/${data?.id}/:layout`,
                                        state: {
                                          data: data
                             
                                        },
                                })}}>
                View Requesters
                </Button>
            </div>
          </TableCell>}
        {/* <TableCell style={{ width: `${tableColumnWidth[6]}%` }}>
          <div className="columnStyles">
            {data?.availableStock && data?.availableStock}
            {data?.quantity && `/${data?.quantity}`}
          </div>
        </TableCell> */}
        {!data?.isAirdropNft && <TableCell style={{ width: `${tableColumnWidth[8]}%` }}>
          <div className="columnStyles">
            <div
              className={
                data?.saleType == "fixed" ? "fixedLabel" : "auctionLabel"
              }
            >
              {data?.saleType}
            </div>
            {/* {data?.regularPrice ? data?.regularPrice : "0"}{" "} */}
          </div>
        </TableCell>}
        {selectedTab == "resaleAssets" && (
          <TableCell style={{ width: `${tableColumnWidth[7]}%` }}>
            <div className="columnStyles">
              {data?.currentOwner_ &&
                `${data?.currentOwner_.firstName} ${data?.currentOwner_.lastName}`}
            </div>
          </TableCell>
        )}
        {/* <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="positionToggle width64">
            <Switch
              checked={true}
              onChange={(e) => console.log(e)}
              name="activate"
              inputProps={{ "aria-label": "checkbox" }}
              disabled={true}
            />
          </div>
        </TableCell> */}
       {!data.isAirdropNft &&  <TableCell
          style={{ width: `${tableColumnWidth[10]}%` }}
          onClick={() => viewMetaData(data?.ipfsMetaUrl)}
        >
          <div className="columnStyles viewOnPolygonScan">View</div>
        </TableCell>}
        {!data.isAirdropNft && <TableCell style={{ width: `${tableColumnWidth[9]}%` }}>
          <a
            href={`${polygon}token/0x026368208bf469e2688c710c20139739ed253e29?a=${data?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="columnStyles viewOnPolygonScan"
          >
            View
          </a>
        </TableCell>}
        <TableCell style={{ width: `${tableColumnWidth[10]}%` }}>
          <div className="columnStyles category-wrapper">{data?.status}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[10]}%` }}>
          <div className="columnStyles category-wrapper">{data?.isResellable ? "yes" : 'No'}</div>
        </TableCell>
        {!data.isAirdropNft && <TableCell style={{ width: `${tableColumnWidth[11]}%` }}>
          <div className="columnStyles category-wrapper">
            {data?.category?.name}
          </div>
        </TableCell>}
      </TableRow>

      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            borderBottom: "12px solid rgb(245, 245, 252)",
          }}
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <ExpandedRow
                rowData={data}
                setShowLoader={(e) => setShowLoader(e)}
                showLoader={showLoader}
                selectedTab={selectedTab}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {columns.map(({ name, field, sortable, image }) => (
          <TableCell
            align={columns.align}
            key={name}
            className={classes.cursorDefault}
            style={
              name == "Metadata" || name == "View on Polygonscan"
                ? { minWidth: 103 }
                : {}
            }
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function TableExpandable({
  data,
  columns,
  columnsSoldAsset,
  columnsResaleAsset,
  tableColumnWidth,
  totalDoc,
  totalAvailableStock,
  totalSoldAsset,
  pageNo,
  setPageNo,
  showLoader,
  setShowLoader,
  searchText,
  setSearchText,
  filterSelected,
  setFilterSelected,
  selectedTab,
  setSelectedTab,
  totalPage,
  setSelectionRange,
  selectionRange,
  handleSelect,
  isDateFilterEnabled,
  setIsDateFilterEnabled,
}) {
  const classes = useRowStyles();
  const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
  // const [ThreeDTempURL, setThreeDTempURL] = useState("");
  const [tableData, setTableData] = useState(data);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [categoryList, setCategoryList] = useState(null);
  const [page, setPage] = React.useState(1);
  const [isAirdropRoute, setIsAirdropRoute] = useState(false);
  const history = useHistory();
  // Date conversation for DateRange filer
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let day = today.getDate();
  let date1MonthBefore = new Date(year, month - 1, day);

  const handleChange = (event, value) => {
    setPage(value);
    setPageNo(value - 1);
    window.scrollTo(0, 0);
  };

  const token = JSON.parse(localStorage.getItem("token"));

  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const getPreviewImg = async (urltoget) => {
    try {
      const axiosInstanceForPreview = axios.create();
      const response = await axiosInstanceForPreview.get(urltoget);
      return response?.data?.thumbnails?.images[0]?.url
        ? response?.data?.thumbnails?.images[0]?.url
        : "";
    } catch (error) {
      console.log("3d catch", urltoget);
      console.log("3d error", error);
    }
  };

  useEffect(() => {
    // Check if the route contains the word "airdrop"
    setIsAirdropRoute(history.location.pathname.includes('airdrop'));

    // Listen for route changes and update the state variable
    const unlisten = history.listen((location) => {
      setIsAirdropRoute(location.pathname.includes('airdrop'));
    });

    // Cleanup the listener when the component unmounts
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
   
    let call = async () => {
      if (data.length > 0) {
        const decryptData = data.map((obj) => {
          if (obj?.ipfsImageUrl && obj?.ipfsImageUrl.length > 0) {
            obj.ipfsImageUrl = obj?.ipfsImageUrl;
          }
          if (obj?.ipfsLongVideoUrl && obj?.ipfsLongVideoUrl.length > 0) {
            obj.ipfsLongVideoUrl = obj?.ipfsLongVideoUrl;
          }
          if (obj?.ipfsAudioUrl && obj?.ipfsAudioUrl.length > 0) {
            obj.ipfsAudioUrl = obj?.ipfsAudioUrl;
          }

          return obj;
        });
       
        // const decryptData = data.map((obj) => {
        // 	if (obj?.ipfsImageUrl && obj?.ipfsImageUrl.length > 0) {
        // 		obj.ipfsImageUrl = decryptFunction(obj?.ipfsImageUrl);
        // 	}
        // 	if (obj?.ipfsLongVideoUrl && obj?.ipfsLongVideoUrl.length > 0) {
        // 		obj.ipfsLongVideoUrl = decryptFunction(obj?.ipfsLongVideoUrl);
        // 	}
        // 	if (obj?.ipfsAudioUrl && obj?.ipfsAudioUrl.length > 0) {
        // 		obj.ipfsAudioUrl = decryptFunction(obj?.ipfsAudioUrl);
        // 	}

        // 	return obj;
        // });
        const add3DURL = await Promise.all(
          decryptData.map(async (obj) => {
            if (
              obj?.ipfsImageUrl &&
              obj?.ipfsImageUrl.split(".").pop() == "glb"
            ) {
              obj["3DURL"] = await getPreviewImg(obj?.mediaPreviewUrl);
            }
            return obj;
          })
        );
    
        setTableData(decryptData);
        if (data.length > 0) {
          let searchSuggestionTitle = data.map(function (item) {
            return {
              name: item.name,
            };
          });
          setSearchSuggestions(searchSuggestionTitle);
        }
      }
    };
    call();
  }, [data]);


  useEffect(() => {
    getCategoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleChangePage = (event, nextPageNo) => {
  //   setPageNo(nextPageNo);
  //   window.scrollTo(0, 0);
  // };

  const handleSearchBoxKeyDown = (e) => {
    if (e.keyCode == 13) {
      setSearchText(e.target.value);
    }
  };

  const getCategoryList = async () => {
    await axiosInstance
      .get("/v1/admin/category?page=1&limit=200", header)
      .then((result) => {
        setCategoryList(result?.data?.result?.assets);
      })
      .catch((err) => {
        if (
          err?.response &&
          err?.response?.data?.errors &&
          err?.response?.data?.errors?.length > 0
        ) {
          Swal.fire({
            title: "Oops!",
            text: err?.response?.data?.errors?.map((err) => err.msg),
            icon: "error",
          });
        } else {
          Swal.fire("Oops!", err?.response?.data?.message, "error");
        }
      });
  };

  return (
    <div className="table_div tableWidth100">
      {showLoader == true ? <Loader /> : null}
      <div className="productTableHeaderWrapper">
        <div className="tabSectionProducts">
          <div className="boleroTabsSection">
            <div
              className={
                "UsersTabOption " +
                (selectedTab == "assets" ? "userTabSelected" : "")
              }
              onClick={() => {
                setSelectedTab("assets");
                setPage(1);
                setPageNo(0);
              }}
            >
              Assets
            </div>
            <div
              className={
                "boleroTabCurve" +
                (selectedTab == "assets" ? "userTabSelected" : "")
              }
              onClick={() => {
                setSelectedTab("assets");
                setPage(1);
                setPageNo(0);
              }}
            ></div>

         {!isAirdropRoute && <><div
              className={
                "UsersTabOption " +
                (selectedTab == "soldAssets" ? "userTabSelected" : "")
              }
              onClick={() => {
                setSelectedTab("soldAssets");
                setPage(1);
                setPageNo(0);
              }}
            >
              Sold Assets
            </div>
            <div
              className={
                "boleroTabCurve " +
                (selectedTab == "soldAssets" ? "userTabSelected" : "")
              }
              onClick={() => {
                setSelectedTab("soldAssets");
                setPage(1);
                setPageNo(0);
              }}
            ></div>

            <div
              className={
                "UsersTabOption " +
                (selectedTab == "resaleAssets" ? "userTabSelected" : "")
              }
              onClick={() => {
                setSelectedTab("resaleAssets");
                setPage(1);
                setPageNo(0);
              }}
            >
              Resell Assets
            </div>
            <div
              className={
                "boleroTabCurve " +
                (selectedTab == "resaleAssets" ? "userTabSelected" : "")
              }
              onClick={() => {
                setSelectedTab("resaleAssets");
                setPage(1);
                setPageNo(0);
              }}
            ></div></>}
            
          </div>
        </div>
        <div className="searchBoxProduct">
          <div className="totalAsset">
            <div className="totalAssetLabel">
              {selectedTab == "soldAssets" ? "Sold " : "Total "}Assets
            </div>
            <div className="totalAssetContent">
              {selectedTab == "soldAssets"
                ? totalSoldAsset
                : totalAvailableStock}
            </div>
          </div>
          <Button
            variant="outlined"
            style={{ height: "55px" }}
            onClick={() => {
              setSelectionRange({
                startDate: date1MonthBefore,
                endDate: new Date(),
                key: "selection",
              });
              // setStatusFilterSelected(-1);
              setSearchText("");
              setFilterSelected(0);
              setIsDateFilterEnabled(false);
            }}
          >
            Reset Filter
          </Button>
         {!isAirdropRoute && <><div
            className="height55px displayFlex"
            onClick={() => {
              setIsDateFilterEnabled(true);
            }}
          >
            <DataRangePopOver
              selectionRange={selectionRange}
              setSelectionRange={setSelectionRange}
              handleSelect={handleSelect}
            />
          </div>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
            <Select
              native
              value={filterSelected}
              onChange={(e) => setFilterSelected(e.target.value)}
              label="Filter"
              inputProps={{
                name: "Filter",
                id: "outlined-age-native-simple",
              }}
              IconComponent={FilterListIcon}
              displayEmpty={true}
            >
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                  clipRule="evenodd"
                />
              </svg>
              <optgroup label="Status">
                <option value={"0"}>All</option>
                <option value={"-1"}>Active</option>
                <option value={"-2"}>Inactive</option>
              </optgroup>
              <optgroup label="Category">
                {/* <option value={"0"}>All</option> */}
                {categoryList?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </optgroup>
            </Select>
          </FormControl></>}
          <Autocomplete
            id="search-box-product"
            options={searchSuggestions}
            disableClearable={true}
            clearOnBlur={false}
            inputValue={searchText}
            getOptionLabel={(option) => {
              return option.name || "";
            }}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                // value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setPageNo(0);
                }}
                onKeyDown={(e) => handleSearchBoxKeyDown(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                label="Search Asset"
                variant="outlined"
              />
            )}
          />
        </div>
      </div>
      {totalDoc > 0 ? (
        <>
          <TableContainer component={Paper} className={classes.tableRoot}>
            <Table aria-label="collapsible table" className={classes.root}>
              <RenderHeader
                columns={
                  selectedTab == "assets"
                    ? columns
                    : selectedTab == "soldAssets"
                    ? columnsSoldAsset
                    : columnsResaleAsset
                }
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <TableBody style={{ width: "100%" }}>
                {tableData?.map((row, index) => (
                  <Row
                    aria-label="expand row"
                    size="small"
                    key={row?.id}
                    row={row}
                    data={tableData[index]}
                    tableColumnWidth={tableColumnWidth}
                    setShowLoader={setShowLoader}
                    selectedTab={selectedTab}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination count={totalPage} page={page} onChange={handleChange} />
        </>
      ) : (
        <div className="noResultFound flexColumnCenter">
          <NoRecordsFound message="No records found." />
        </div>
      )}
    </div>
  );
}
