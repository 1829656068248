import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { axiosInstance } from "../../utility/api";
import Auth from "../../utility/auth";
import { SAVE_TOKEN } from "../../redux/actionTypes";
import Logo from "../../assets/images/logo/logo_asly.png";
import "./Login.css";

export default function LoginStep2({ loginStepInfo }) {
	let history = useHistory();
	const [loginDetails, setLoginDetails] = useState({
		otp: "",
		email: "",
	});
	//const [showLoader, setShowLoader] = useState(false);
	const dispatch = useDispatch();
	const userToken = useSelector((state) => state.adminReducer.userToken);

	useEffect(() => {
		if (userToken) {
			Auth.login(() => {
				history.push("/welcome/:layout");
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userToken]);

	// const handleChange = (e) => {
	//   if (e.target.name !== "username") {
	//     setLogin({
	//       ...login,
	//       [e.target.name]: e.target.value,
	//     });
	//   } else if (e.target.name === "username") {
	//     setLogin({
	//       ...login,
	//       [e.target.name]: e.target.value.toLowerCase(),
	//     });
	//   }
	// };

	const submitHandler = (e) => {
		e.preventDefault();
		if (loginDetails?.otp) {
			//setShowLoader(true);
			axiosInstance
				.post("/v1/admin/auth/2FA/verify", loginDetails)
				.then((res) => {
					localStorage.clear();
					console.log("qwe Show : ", res.data.accessToken);
					//setShowLoader(false);
					Swal.fire("Welcome to lii!", res.data.message, "success");

					console.log("rrr", res);
					if (res) {
						localStorage.setItem(
							"token",
							JSON.stringify(res?.data?.accessToken)
						);
						dispatch({
							type: SAVE_TOKEN,
							payload: JSON.stringify(res?.data?.accessToken),
						});
					} else {
						Swal.fire("Error", "Error while login.", "error");
					}
					// history.push("/products/products/:layout");
					//   localStorage.setItem("isAuth", res.data.success);
					//   localStorage.setItem("token", JSON.stringify(res.data.result.token));
					//   localStorage.setItem("user", JSON.stringify(res.data.result));
					//   localStorage.setItem("role", JSON.stringify(res.data.result.role));
					//   localStorage.setItem("userID", JSON.stringify(res.data.result._id));
					// //   localStorage.setItem("email", JSON.stringify(res.data.result.email));
					// Auth.login(() => {
					//   history.push("/products/products/:layout");
					// });
				})
				.catch((err) => {
					//setShowLoader(false);
					if (
						err?.response &&
						err?.response?.data?.errors &&
						err?.response?.data?.errors?.length > 1 &&
						err?.response?.data?.errors?.length !== undefined
					) {
						Swal.fire("Oops!", err?.response?.data?.errors, "error");
					} else {
						Swal.fire("Oops!", err.response?.data?.message, "error");
					}
				});
			// }
		} else {
			Swal.fire("Oops!", "Please enter verification code.", "error");
		}
	};

	return (
		<>
			<div>
				<fieldset>
					<div className="twoFactorAuthenticate">
						<form
						// onSubmit={submitHandler}
						>
							<div className="logoWrapperLogin">
								<img src={Logo} alt="logo" className="logoImageLogin" />
							</div>
							<div className="loginCenter">
								<h3>2FA</h3>

								{loginStepInfo?.qr && (
									<>
										<div className="googleAuthenticatorInfo">
											Scan this with Google Authentication App
										</div>
										<img src={loginStepInfo?.qr} alt="logo" height="210px" />
									</>
								)}
							</div>

							<div className="form-group googleVerificationCode">
								{/* <label>Verification Code.</label> */}
								<input
									type="text"
									name="verificationCode"
									className="form-control"
									placeholder="Enter Verification code"
									value={loginDetails?.otp}
									onChange={(e) =>
										setLoginDetails({
											otp: e?.target?.value,
											email: loginStepInfo?.email,
										})
									}
								/>
							</div>

							<button
								type="submit"
								className="btn btn-dark btn-lg btn-block"
								onClick={submitHandler}
							>
								Submit
							</button>
							{/* <p className="forgot-password text-right">
              Forgot <a href="#">password?</a>
            </p> */}
						</form>
					</div>
				</fieldset>
			</div>
		</>
	);
}
