import React, { useState } from "react";
import NewsTable from "./components/NewsTable";
import UiDrawer from "./components/UiDrawer";

export default function News() {
	const [open, setOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [refreshTableList, setRefreshTableList] = useState(false);

	return (
		<>
			<div className="products-wrapper">
				<div className="container-fluide">
					<div className="page-title">
						<div className="row">
							<div className="col-6">
								<h3>News</h3>
							</div>
							<div
								className="col-12 mt-4"
								style={{ justifySelf: "flexgit -end" }}
							>
								<button
									className={"btn btn-primary"}
									onClick={() => setOpen(true)}
								>
									+ Add News
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="products-content">
					<NewsTable
						refreshTable={refreshTableList}
						setRefreshTableList={setRefreshTableList}
					/>
				</div>
			</div>

			<UiDrawer
				open={open}
				onClose={() => setOpen(false)}
				showLoader={showLoader}
				setShowLoader={(e) => setShowLoader(e)}
				isEdit={isEdit}
				refreshTableList={refreshTableList}
				setRefreshTableList={() => {
					setRefreshTableList(!refreshTableList);
				}}
			/>
		</>
	);
}
