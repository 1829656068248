import React from "react";
import ChangePassword from "./changePassword.jsx";

export default function ChangePasswordIndex() {
  return (
    <>
      <ChangePassword />
    </>
  );
}
