/* eslint-disable eqeqeq */
const maxLengthCheck = (object) => {
	if (object.target.value.length > object.target.maxLength) {
		object.target.value = object.target.value.slice(0, object.target.maxLength);
	}
};

const preventSpecialCharacters = (e) => {
	let checkIfNum;
	if (e.key !== undefined) {
		checkIfNum = e.key === "e" || e.key === "+" || e.key === "-";
	} else if (e.keyCode !== undefined) {
		checkIfNum = e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189;
	}
	return checkIfNum && e.preventDefault();
};

// const isSpclChar = () => {
//   var iChars = "!@#$%^&*()+=-[]\\';,./{}|\":<>?";
//   if (document.qfrm.q.value.indexOf(iChars) != -1) {
//     alert("The box has special characters. \nThese are not allowed.\n");
//     return false;
//   }
// };

function isSpclChar(str) {
	var iChars = "!@#$%^&*()+=-[]\\';,./{}|\":<>?";
	if (/^[a-zA-Z0-9- ,_]*$/.test(str) == false) {
		return true;
	}
}

export { maxLengthCheck, preventSpecialCharacters, isSpclChar };
