// dashbaord
import Default from "../components/dashboard/default";
import Ecommerce from "../components/dashboard/ecommerce";
import Products from "../components/products/products";
import Categories from "../components/categories/index";
import Orders from "../components/Orders/Orders";
import Commission from "../components/commission";
import TransactionHistory from "../components/TransactionHistory";
import Users from "../components/users";
import Creators from "../components/creators";
import Auction from "../components/Auction/index";
import News from "../components/News/index";
// starter kits
// import Starterkits from "../components/starter-kits";
import Analytics from "../components/analytics/Analytics";
import AnalyticsDetail from "../components/analytics/AnalyticsDetail";
import TotalAssets from "../components/analytics/TotalAssets";
import TwofactorAuth from "../components/Login/TwoFactorScreen";
import Test from "../components/test";
import ManageSubAdmin from "../components/SubAdmin/index";
import ChangePassword from "../components/ChangePassword";
import EditProfile from "../components/AccountSetting/EditProfile/EditProfile";
import AdminProfile from "../components/Profile/Profile";
// import ManageSubAdmin from "../components/ManageSubAdmin"
import Welcome from "./../components/common/Welcome";
import Reports from "../components/Reports";
import Collection from "../components/Collection";
import ImageUpload from "../components/ImageUpload";
import Airdrop from "../components/Airdrop/airdrop"
export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard/default/:layout`,
    Component: Default,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/ecommerce/:layout`,
    Component: Ecommerce,
  },
  {
    path: `${process.env.PUBLIC_URL}/login/step2/:layout`,
    Component: TwofactorAuth,
  },
  {
    path: `${process.env.PUBLIC_URL}/products/products/:layout`,
    Component: Products,
  },
  {
    path: `${process.env.PUBLIC_URL}/products/airdrop/:layout`,
    Component: Airdrop,
  },
  {
    path: `${process.env.PUBLIC_URL}/products/categories/:layout`,
    Component: Categories,
  },
  {
    path: `${process.env.PUBLIC_URL}/collection/:layout`,
    Component: Collection,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/news/:layout`,
    Component: News,
  },
  {
    path: `${process.env.PUBLIC_URL}/orders/orders/:layout`,
    Component: Orders,
  },
  {
    path: `${process.env.PUBLIC_URL}/commission/commission/:layout`,
    Component: Commission,
  },
  {
    path: `${process.env.PUBLIC_URL}/orders/transactionhistory/:layout`,
    Component: TransactionHistory,
  },
  {
    path: `${process.env.PUBLIC_URL}/test/test/:layout`,
    Component: Test,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/users/:layout`,
    Component: Users,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/users/:id/:layout`,
    Component: Users,
  },
  {
    path: `${process.env.PUBLIC_URL}/creators/dashboard/:layout`,
    Component: Creators,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/reports/:layout`,
    Component: Reports,
  },
  {
    path: `${process.env.PUBLIC_URL}/auction/auction/:layout`,
    Component: Auction,
  },
  {
    path: `${process.env.PUBLIC_URL}/analytics/dashboard/:layout`,
    Component: Analytics,
  },
  {
    path: `${process.env.PUBLIC_URL}/analytics/dashboard/totalorders/:layout`,
    Component: AnalyticsDetail,
  },
  {
    path: `${process.env.PUBLIC_URL}/analytics/dashboard/totalAssets/:layout`,
    Component: TotalAssets,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/changepassword/:layout`,
    Component: ChangePassword,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/editprofile/:layout`,
    Component: EditProfile,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/user/editprofile/:layout`,
  //   Component: AdminProfile,
  // },
  {
    path: `${process.env.PUBLIC_URL}/manage-subadmin/subadmin/:layout`,
    Component: ManageSubAdmin,
  },
  {
    path: `${process.env.PUBLIC_URL}/welcome/:layout`,
    Component: Welcome,
  },
  {
    path: `${process.env.PUBLIC_URL}/Image/uploadImage/:layout`,
    Component: ImageUpload,
  },
];
