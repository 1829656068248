/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import Swal from "sweetalert2";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import SearchIcon from "@material-ui/icons/Search";
// import FilterListIcon from "@material-ui/icons/FilterList";
// import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
// import Modal from "@material-ui/core/Modal";
// import Backdrop from "@material-ui/core/Backdrop";
// import Fade from "@material-ui/core/Fade";
// import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

// import "./userTable.css";
import { axiosInstance } from "../../utility/api";
import Loader from "../common/Loader/Loader";
import NoRecordsFound from "../common/Error/NoRecordsFound";
// import TransactionDetailsModal from "./UserLastTransaction";
// import DataRangePopOver from "../common/DateRange/DateRangePopOver";
// import Edit from "../../assets/images/icons/edit.svg";
// import UserActivate from "../../assets/images/UserActivate.svg";
// import UserBlock from "../../assets/images/UserBlock.svg";
// import optionsDots from "../../assets/images/optionsDots.svg";
import { formatDateTime } from "../common/date";

// import { useDebounce } from "../../hooks/useDebounce";
import { handleAPIError } from "../../utility/helper";

const useRowStyles = makeStyles((theme) => ({
  root: {
    marginTop: "0px",
    border: "none",
    width: "100%",
  },
  header: {
    backgroundColor: "#F5F5FC",
  },
  rowDatas: {
    "& td,th": {
      border: "none",
      height: "90px",
    },
  },
  border__right: {
    borderRight: "2px solid white",
    height: "70%",
  },
  cursorDefault: {
    cursor: "default",
  },
  height24: {
    height: "24px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

//Each row of table
function Row(props) {
  const {
    data,
    tableColumnWidth,
    selectedRowIndex,
    setselectedRowIndex,
    index,
    setOpenEdit,
    //selectedRow,
    setSelectedRow,
  } = props;

  // const token = JSON.parse(localStorage.getItem("token"));
  const classes = useRowStyles();
  // const style = useStyles();

  //   const blockUser = (id) => {
  //     console.log("deleted 2", id);
  //     if (id) {
  //       confirmAlert({
  //         title: "Block User?",
  //         message: "Are you sure to block.",
  //         buttons: [
  //           {
  //             label: "Yes",
  //             onClick: () => handleBlock(id),
  //           },
  //           {
  //             label: "No",
  //             onClick: () => console.log("Clicked No"),
  //           },
  //         ],
  //       });
  //     }
  //   };
  //   const activateUser = async (id) => {
  //     console.log("Activate", id);
  //     if (id) {
  //       await handleActivate(id);
  //     }

  //   const handleBlock = (id) => {
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  //       },
  //     };

  //     const payload = {
  //       isBlock: true,
  //     };
  //     axiosInstance
  //       .put(`/v1/admin/user/block/${id}`, payload, config)
  //       .then((res) => {
  //         Swal.fire("Success!", res?.data?.message, "success");
  //       })
  //       .then(() => {
  //         window.location.reload(true);
  //       })
  //       .catch((err) => {
  //         console.log("rrr eror", err?.response.data?.message);
  //         Swal.fire("Error!", err?.response?.data?.message, "error");
  //       });
  //   };

  //   const handleActivate = (id) => {
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  //       },
  //     };

  //     const payload = {
  //       isBlock: false,
  //     };

  //     axiosInstance
  //       .put(`/v1/admin/user/block/${id}`, payload, config)
  //       .then((res) => {
  //         Swal.fire("Success!", res?.data?.message, "success");
  //       })
  //       .then(() => {
  //         window.location.reload(true);
  //       })
  //       .catch((err) => {
  //         console.log("rrr eror", err?.response.data?.message);
  //         Swal.fire("Error!", err?.response?.data?.message, "error");
  //       });
  //   };

  const HandleOutSideClickClose = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setselectedRowIndex("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const BlockDisableContainerRef = React.useRef(null);
  HandleOutSideClickClose(BlockDisableContainerRef);

  return (
    <React.Fragment>
      <TableRow
        className={classes.rowDatas}
        style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
      >
        <TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
          <div className="columnStyles">
            {data?.profilePicUrl ? (
              <img
                src={data?.profilePicUrl ? data?.profilePicUrl : ""}
                className="pharmaIconAvatar"
                height="50px"
                alt="dp"
              />
            ) : (
              "-"
            )}
          </div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
          <div className="columnStyles">{`${
            data?.firstName ? data?.firstName : "-"
          } ${data?.lastName ? data?.lastName : " "}`}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles">{`${    
            data?.lastName ? data?.lastName : "-"
          } ${data?.lastName ? data?.lastName : " "}`}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
          <div className="columnStyles">{`${
            data?.country ? data?.country : "-"
          }`}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="columnStyles">{data?.email ? data?.email : "-"}</div>
        </TableCell>
        <TableCell style={{ width: `${tableColumnWidth[4]}%` }}>
          <div className="columnStyles">{formatDateTime(data?.bannedDate)}</div>
        </TableCell>

        {/* <TableCell
          align="left"
          style={{ width: `${tableColumnWidth[8]}%` }}
          onClick={(event) => event.preventDefault()}
        >
          <div
            className={"voiletTickImage alignItemStart hcpLastColumn"}
            style={{ position: "relative" }}
          >
            {selectedRowIndex === index && (
              <div
                className="threeDotContainer threeDotContainerAlignment"
                ref={BlockDisableContainerRef}
              >
                <div
                  className="editTableRow width100Percentage paddingLeft22px paddingTop18px cursorPointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedRow(data);
                    setOpenEdit(true);
                  }}
                >
                  <img src={Edit} alt="edit" className="paddingRight4px" />
                  Edit
                </div>
                <div
                  className="blockTableRow width100Percentage paddingLeft22px cursorPointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    data?.isBlock === true
                      ? activateUser(data?.id)
                      : blockUser(data?.id);
                  }}
                >
                  <img
                    src={data?.isBlock === true ? UserActivate : UserBlock}
                    alt="disable"
                    className="paddingRight4px"
                  />
                  {data?.isBlock === true ? "Activate" : "Block"}
                </div>
              </div>
            )}
          </div>
          <img
            className="optionsDotsCss cursorPointer"
            alt="options"
            src={optionsDots}
            onClick={(e) => {
              e.stopPropagation();
              handleChangeForDots(index);
            }}
          />
        </TableCell> */}
      </TableRow>
    </React.Fragment>
  );
}

const RenderHeader = ({ columns, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  const classes = useRowStyles();
  return (
    <TableHead className={classes.header}>
      <TableRow>
        {columns?.map(({ name, field, sortable, image }) => (
          <TableCell
            align={columns.align}
            key={name}
            className={classes.cursorDefault}
          >
            <div className="dividerFlex">
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  cursor: sortable ? "pointer" : "default",
                }}
                onClick={() => (sortable ? onSortingChange(field) : null)}
              >
                {name}
                {/* {sortable && (
                  <div className="sort_icons">
                    <img src={upSort} alt="" className="sortArrows" />
                    <img src={downSort} alt="" className="sortArrows" />
                  </div>
                )} */}
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function CreatorTable({ data, showLoader, setShowLoader }) {
  const classes = useRowStyles();
  const [sorting, setSorting] = useState({ field: "_id", order: "desc" });
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let day = today.getDate();
  let date1MonthBefore = new Date(year, month - 1, day);
  const [reportList, setReportList] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectionRange, setSelectionRange] = useState({
    startDate: date1MonthBefore,
    endDate: new Date(),
    key: "selection",
  });
  const [searchText, setSearchText] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [statusFilterSelected, setStatusFilterSelected] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [isDateFilterEnabled, setIsDateFilterEnabled] = useState(false);
  const token = JSON.parse(localStorage.getItem("token"));
  const [openEdit, setOpenEdit] = useState(false);
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const tableColumnWidth = [5, 10, 15, 15, 15, 5];
  const columns = [
    {
      name: "Profile Photo",
      field: "profilePhoto",
      numeric: false,
      sortable: false,
      align: "left",
    },
    {
      name: "First Name",
      field: "firstName",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Last Name",
      field: "lastName",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Location",
      field: "location",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Email",
      field: "email",
      numeric: true,
      sortable: false,
      align: "left",
    },
    {
      name: "Banned Date",
      field: "bannedDate",
      numeric: true,
      sortable: false,
      align: "left",
    },
  ];

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilterSelected, pageNo, selectionRange, isDateFilterEnabled]);

  //   useDebounce(
  //     () => {
  //       if (searchText != null && searchText != "" && searchText != undefined) {
  //         getUserDetails();
  //       }
  //     },
  //     [searchText],
  //     1000
  //   );

  const handleSelect = (ranges) => {
    setSelectionRange(ranges?.selection);
    setPageNo(1);
  };

  const handleChangePage = (event, value) => {
    setPageNo(value);
    window.scrollTo(0, 0);
  };

  const getUserDetails = async () => {
    // let startDate = new Date(
    //   selectionRange?.startDate?.getTime() -
    //   selectionRange?.startDate?.getTimezoneOffset() * 60000
    // ).toISOString();
    // let endDate = new Date(
    //   selectionRange?.endDate?.getTime() -
    //   selectionRange?.endDate?.getTimezoneOffset() * 60000
    // ).toISOString();
    //To set endDate time last minute of the day
    // endDate = moment(endDate).add(1, "days");
    // endDate = moment(endDate);
    // endDate.set({ hour: 5, minute: 29, second: 59, millisecond: 59 });
    // endDate = endDate.toISOString();

    // let statusFilter = "";
    // if (statusFilterSelected == "blocked") {
    //   statusFilter = true;
    // } else if (statusFilterSelected == "active") {
    //   statusFilter = false;
    // }
    await axiosInstance
      .get(`/v1/admin/user/blockedUsers?limit=10&page=${pageNo}`, header)
      .then((result) => {
        setReportList(result?.data?.result?.users);
        setTotalCount(result?.data?.result?.totalPages);
        setTotalItemCount(result?.data?.result?.totalItems);
        setTotalUser(result?.data?.result?.totalUsers);
      })
      .catch((err) => {
        let respErr = handleAPIError(err);
        Swal.fire("Oops!", respErr, "error");
      });
  };

  const [selectedFiles, setSelectedFiles] = useState(null);
  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };


  const handleUpload = async() => {
    setLoading(true)
    if (selectedFiles) {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('images', selectedFiles[i]);
      }

      // Send formData to the backend


      await axiosInstance
      .post(`/v1/images/uploadAndGetS3Url`,formData, header)
      .then((result) => {
        

        console.log("result",result)

        Swal.fire("Done!",' Images uploaded successfully refer the downloadable file for Image URLs', "success").then((resultData)=>{


          console.log("clicking Okay",result)
            const blob = new Blob([result.data], { type: 'text/csv' });
            console.log(blob); // Add this line for debugging
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'output.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
      })
      .catch((error) => {
        Swal.fire("Done!",' Images uploaded successfully refer the downloadable file for Image URLs', "success").then((result)=>{


          console.log("clicking Okay",error.response.data)
            const blob = new Blob([error.response.data], { type: 'text/csv' });
            console.log(blob); // Add this line for debugging
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'error_file.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
      });
  
  
    }
    else{
      Swal.fire("No file selected!",'Please pick atleast one file', "info")
    }
    setLoading(false)
  };
  const inputStyle = {
    border: '2px solid #ccc',
    padding: '5px',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    color: '#333',
    fontSize: '16px',
  };

  const [isLoading,setLoading]=useState(false);
  return (
    <div className="table_div tableWidth100">
      {showLoader == true ? <Loader /> : null}
      <div className="searchBoxProduct" style={{justifyContent:'center'}}>
        <div className="TotalUsers">
          <div className="totalUserLabel">


            

          <input type="file" multiple onChange={handleFileChange}  style={inputStyle} title="Hold Control (or Command on macOS) to select multiple files"/>
          <p>Hold Control (or Command on macOS) to select multiple files</p>

          </div>
        </div>

      </div>
      <div className="searchBoxProduct" style={{justifyContent:'center', marginTop:'50px'}}>

{!isLoading?<>      <button className="btn btn-primary" onClick={handleUpload} >Upload Files</button>
</>
:<>
      <button className="btn btn-primary"  >Please wait...</button>

</>
}
      </div>

    </div>
  );
}
