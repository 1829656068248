import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { formatDateTime } from "../common/date";
import { axiosInstance } from "../../utility/api";
import CloseButton from "../../assets/images/icons/x-circle.svg";
import { handleAPIError } from "../../utility/helper";

// const styles = (theme) => ({
// 	root: {
// 		margin: 0,
// 		padding: theme.spacing(2),
// 	},
// 	closeButton: {
// 		position: "absolute",
// 		right: theme.spacing(1),
// 		top: theme.spacing(1),
// 		color: theme.palette.grey[500],
// 	},
// });

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
});

// const DialogTitle = withStyles(styles)((props) => {
// 	const { children, classes, onClose, ...other } = props;
// 	return (
// 		<MuiDialogTitle disableTypography className={classes.root} {...other}>
// 			<Typography variant="h6">{children}</Typography>
// 			{onClose ? (
// 				<IconButton
// 					aria-label="close"
// 					className={classes.closeButton}
// 					onClick={onClose}
// 				>
// 					<CloseIcon />
// 				</IconButton>
// 			) : null}
// 		</MuiDialogTitle>
// 	);
// });

// const DialogContent = withStyles((theme) => ({
// 	root: {
// 		padding: theme.spacing(2),
// 	},
// }))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
// 	root: {
// 		margin: 0,
// 		padding: theme.spacing(1),
// 	},
// }))(MuiDialogActions);

export default function CustomizedDialogs({ userID, closeModal }) {
	// const [open, setOpen] = React.useState(false);
	const [transactions, setTransactions] = useState([]);
	const classes = useStyles();
	const token = JSON.parse(localStorage.getItem("token"));
	const header = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	// const handleClickOpen = () => {
	// 	setOpen(true);
	// };
	// const handleClose = () => {
	// 	setOpen(false);
	// };

	useEffect(() => {
		userID && getLastTransactionDetails(userID);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userID]);

	const getLastTransactionDetails = async (userID) => {
		await axiosInstance
			.get(`v1/admin/user/last/transactions?userId=${userID}`, header)
			.then((result) => {
				console.log(typeof result, result);
				if (
					(typeof result === "object" || typeof result === "function") &&
					result !== null
				) {
					setTransactions(result?.data?.result?.transactions?.rows);
				} else {
					setTransactions([]);
				}
			})
			.catch((err) => {
				setTransactions([]);
				let respErr = handleAPIError(err)
				Swal.fire("Oops!", respErr, "error");
			});
	};

	return (
		<div style={{ display: "inline-block", fontFamily: "Roboto" }}>
			<div
				className="transactionModalHeader"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					marginBottom: " 24px",
				}}
			>
				<h4>
					<b>Last 10 transactions</b>
				</h4>
				<img
					src={CloseButton}
					style={{
						width: "30px",
						cursor: "pointer",
					}}
					onClick={closeModal}
					alt="close"
				/>
			</div>

			<TableContainer component={Paper}>
				<Table
					className={classes.table}
					size="small"
					aria-label="a dense table"
				>
					<TableHead>
						<TableRow>
							<TableCell>Asset Name</TableCell>
							<TableCell align="center">Date</TableCell>
							<TableCell align="center">Quantity</TableCell>
							<TableCell align="center">Type</TableCell>
							<TableCell align="center">Price (USD)</TableCell>
							<TableCell align="center">From</TableCell>
							<TableCell align="center">Payment Type</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{typeof transactions != "string" && transactions?.length > 0 ? (
							transactions?.map((row) => (
								<TableRow key={row?.id}>
									<TableCell component="th" scope="row">
										{row.asset?.name}
									</TableCell>
									<TableCell align="right">
										{formatDateTime(row?.createdAt)}
									</TableCell>
									<TableCell align="right">{row?.quantity}</TableCell>
									<TableCell
										align="right"
										style={{ textTransform: "capitalize" }}
									>
										{row?.type}
									</TableCell>
									<TableCell align="right">{row?.totalPrice}</TableCell>
									<TableCell align="right">{row?.from}</TableCell>
									<TableCell
										align="right"
										style={{ textTransform: "capitalize" }}
									>
										{row?.paymentType}
									</TableCell>
								</TableRow>
							))
						) : (
							<span
								style={{
									textAlign: "center",
									margin: "18px 13px",
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								No transactions found
							</span>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}
