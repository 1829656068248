/* eslint-disable eqeqeq */
import React from "react";
import Done from "../../../assets/images/other-images/done.png";
import "./RemoveFileUploaded.css";

const RemoveFileUploaded = ({ state, handleRemoveFile, fileType }) => (
	<div
		className={`fileUploadActionItems `}
	>
		<div
			className={`fileUploadMainActionBox cursorPointer fileUploadMainFlex ${fileType != "mainFile" ? "width98px" : ""}`}
		>
			<div className="removeUploaded">
				<span
					className="closeButtonRemoveUpload"
					onClick={(e) => {
						e.stopPropagation();
						// if (fileType != "mainFile") {
						// 	handleRemoveFile(fileType);
						// } else if (!state.isEdit) {
						// 	handleRemoveFile(fileType);
						// }
							handleRemoveFile(fileType);

					}}
				>
					x
				</span>
			</div>
			<img src={Done} alt="filePreview" height="30px" />
		</div>
	</div>
);

export default RemoveFileUploaded;
