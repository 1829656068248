import { combineReducers } from "redux";
import Customizer from "./customizer/reducer";
import adminReducer from "./customizer/adminReducer";
import appReducer from "./appReducer";
import basicInfoReducer from "./customizer/basicInfoReducer";
import Counter from './customizer/reducer'

const reducers = combineReducers({
  Customizer,
  adminReducer,
  appReducer,
  basicInfoReducer,
});

export default reducers;
