import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import "./AssetByCollection.css";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { formatDateTime } from "../common/date";
import CloseButton from "../../assets/images/icons/x-circle.svg";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function AssetsByCollection({ userID, closeModal, assets }) {
  // const [open, setOpen] = React.useState(false);
  //   const [transactions, setTransactions] = useState([]);
  const classes = useStyles();
  //   const token = JSON.parse(localStorage.getItem("token"));
  //   const header = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  // const handleClickOpen = () => {
  // 	setOpen(true);
  // };
  // const handleClose = () => {
  // 	setOpen(false);
  // };

  //   useEffect(() => {
  //     userID && getLastTransactionDetails(userID);
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [userID]);

  //   const getLastTransactionDetails = async (userID) => {
  //     await axiosInstance
  //       .get(`v1/admin/user/last/transactions?userId=${userID}`, header)
  //       .then((result) => {
  //         console.log(typeof result, result);
  //         if (
  //           (typeof result === "object" || typeof result === "function") &&
  //           result !== null
  //         ) {
  //           setTransactions(result?.data?.result?.transactions?.rows);
  //         } else {
  //           setTransactions([]);
  //         }
  //       })
  //       .catch((err) => {
  //         setTransactions([]);
  //         let respErr = handleAPIError(err);
  //         Swal.fire("Oops!", respErr, "error");
  //       });
  //   };

  return (
    <div className="collectionModalWrapper">
      <div
        className="transactionModalHeader"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: " 24px",
          width: '100%'
        }}
      >
        <div className="collectionModalHeading">
          <h4>
            <b>Assets</b>
          </h4>
        </div>
        <div className="collectionModalCloseButton">
          <img
            src={CloseButton}
            style={{
              width: "30px",
              cursor: "pointer",
            }}
            onClick={closeModal}
            alt="close"
          />
        </div>
      </div>
      <div className="collectionTableWrapper">
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Asset Name</TableCell>
                <TableCell align="center">Date</TableCell>
                {/* <TableCell align="center">Quantity</TableCell> */}
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Price (USD)</TableCell>
                {/* <TableCell align="center">From</TableCell>
							<TableCell align="center">Payment Type</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {typeof assets != "string" && assets?.length > 0 ? (
                assets?.map((row) => (
                  <TableRow key={row?.id}>
                    <TableCell component="th" scope="row">
                      {row?.name}
                    </TableCell>
                    <TableCell align="right">
                      {formatDateTime(row?.updatedAt)}
                    </TableCell>
                    {/* <TableCell align="right">{row?.quantity}</TableCell> */}
                    <TableCell
                      align="right"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row?.saleType}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ textTransform: "capitalize" }}
                    >
                      {row?.status}
                    </TableCell>
                    {/* <TableCell
                    align="right"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row?.saleType}
                  </TableCell> */}
                    <TableCell align="right">{row?.regularPrice}</TableCell>
                    {/* <TableCell align="right">{row?.from}</TableCell> */}
                    {/* <TableCell
                    align="right"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row?.paymentType}
                  </TableCell> */}
                  </TableRow>
                ))
              ) : (
                <span
                  style={{
                    textAlign: "center",
                    margin: "18px 13px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No assets found
                </span>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
