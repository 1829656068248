import React from "react";
// import Swal from "sweetalert2";

import styles from "./Profile.module.css";
// import { axiosInstance } from "../..//utility/api";

function AdminProfile() {
	// const [fields, setFields] = useState({});
	// const config = {
	//   headers: {
	//     Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
	//   },
	// };

	// const handleChange = (e) => {
	//   let name = e?.target?.name;
	//   let value = e?.target?.value;
	//   setFields({ ...fields, [name]: value });
	// };

	// const handleSubmit = async (e) => {
	//   const userId = localStorage.getItem("userId");

	//   if (fields.newPassword !== fields.confirmNewPassword) {
	//     Swal.fire(
	//       "Error",
	//       "New password and confirm password should match.",
	//       "error"
	//     );
	//     return false;
	//   } else if (userId) {
	//     console.log("I am here");
	//     let body = {
	//       oldPassword: fields.oldPassword,
	//       newPassword: fields.newPassword,
	//     };

	//     await axiosInstance
	//       .put(`/change/password/${userId}`, body, config)
	//       .then((res) => {
	//         console.log("successful");
	//       })
	//       .catch((err) =>
	//         Swal.fire("Oops", err?.response?.data?.message, "warning")
	//       );
	//   }
	// };

	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.profile}>
					<div className={styles.content}>
						<h1>Edit profile</h1>
						<form action="">
							<fieldset>
								<div className={styles.grid35}>
									<label htmlFor="avatar">Your Photo</label>
								</div>
								<div className={styles.grid65}>
									<span
										className={styles.photo}
										title="Upload your Avatar!"
									></span>
									<input type="file" className={styles.btn} />
								</div>
							</fieldset>
							<fieldset>
								<div className={styles.grid35}>
									<label htmlFor="fname">First Name</label>
								</div>
								<div className={styles.grid65}>
									<input type="text" id="fname" tabindex="1" />
								</div>
							</fieldset>
							<fieldset>
								<div className={styles.grid35}>
									<label htmlFor="lname">Last Name</label>
								</div>
								<div className={styles.grid65}>
									<input type="text" id="lname" tabindex="2" />
								</div>
							</fieldset>

							<fieldset>
								<div className={styles.grid35}>
									<label htmlFor="email">Email Address</label>
								</div>
								<div className={styles.grid65}>
									<input type="email" id="email" tabindex="6" />
								</div>
							</fieldset>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default AdminProfile;
