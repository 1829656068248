/* eslint-disable eqeqeq */
import React from "react";
import Swal from "sweetalert2";
import { Drawer } from "@material-ui/core";

import Loader from "../common/Loader/Loader";
import { axiosInstance } from "../../utility/api";
import EditUserForm from "./EditUserForm";

// const useStyles = makeStyles({
//   root: {
//     background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
//     borderRadius: 3,
//     border: 0,
//     color: "white",
//     height: 48,
//     padding: "0 30px",
//     boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
//   },
//   label: {
//     textTransform: "capitalize",
//   },
// });

const styles = {
	sideNav: {
		marginTop: "-60px",
		zIndex: 3,
		marginLeft: "0px",
		position: "fixed",
	},
	link: {
		color: "black",
		textDecoration: "none",
	},
	title: {
		width: "90%",
		marginBottom: "20px",
	},
	productCard: {
		boxShadow: "0px 0px 2px #bbb",
		padding: "10px",
		marginBottom: "20px",
	},
};

// const useStyles = makeStyles((theme) => ({
//   rootSnackBar: {
//     width: "100%",
//     "& > * + *": {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));

export default class UiDrawer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isDrawerOpened: false,
			name: "",
			description: "",
			slug: "",
			error: {
				status: false,
				message: "",
			},
			showLoader: {
				open: false,
				message: "",
			},
			isEdit: false,
		};
	}

	config = {
		headers: {
			Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
		},
	};

	// classes = useStyles();

	componentDidMount() {
		if (this?.props?.selectedRow) {
			this.setState({
				...this.props.selectedRow,
				isEdit: true,
			});
		} else {
			this.setState({
				...this.state,
				isEdit: false,
			});
		}
	}

	//   componentDidUpdate(prevProps, prevState) {
	//     if (
	//       this.props.selectedRow &&
	//       this.state.slug !== this.props.selectedRow.slug
	//     ) {
	//       this.setState({
	//         ...this.props.selectedRow,
	//         isEdit: true,
	//       });
	//     } else {
	//       // this.setState({
	//       //   ...this.state,
	//       //   isEdit: false,
	//       // });
	//     }
	//   }

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	toggleDrawerStatus = () => {
		this.setState({
			isDrawerOpened: true,
		});
	};

	closeDrawer = () => {
		this.setState({
			isDrawerOpened: false,
		});
	};

	setShowLoader = (value, message) => {
		this.setState({
			...this.state,
			showLoader: { open: value, message: message },
		});
	};

	handleSubmitForm = (e) => {
		e.preventDefault();
		this.setState({
			error: { status: false, message: "" },
		});
		if (
			this.state.name == "" ||
			this.state.name == undefined ||
			this.state.name == null
		) {
			Swal.fire("Oops!", "Please enter name.", "error");
			// this.setState({
			//   error: { status: true, message: "Please enter a name." },
			// });
			return false;
		}
		if (
			this.state.slug == "" ||
			this.state.slug == undefined ||
			this.state.slug == null
		) {
			Swal.fire("Oops!", "Please enter label.", "error");
			// this.setState({
			//   error: { status: true, message: "Please enter a slug." },
			// });
			return false;
		}

		this.setShowLoader(true, "Please Wait.");

		const data = { ...this.state };
		delete data.isDrawerOpened;
		delete data.showLoader;
		// delete data.catagories;
		// delete data.pactive;
		// delete data.value;
		delete data.error;
		delete data.setOpenSnackBar;

		delete data.imageUrl;

		if (!this.state.isEdit) {
			delete data.isEdit;
			axiosInstance
				.post("/v1/admin/category", data, this.config)
				.then((res) => {
					console.log("posted");
					// this.setState({
					//   ...this.state,
					//   showLoader: false,
					// });
					this.setShowLoader(false, "");
					this.setState({
						showLoader: false,
						isDrawerOpened: false,
						name: "",
						// catagories: [],
						description: "",
						slug: "",
						// imageUrl: "",
						error: {
							status: false,
							message: "",
						},
					});
					this.props.onClose();
					// window.location.reload(true);
					Swal.fire("Success!", res?.data?.message, "success");
				})
				.catch((err) => {
					console.log("rrr Error", err?.data);
					this.setShowLoader(false, "");
				});
		} else {
			this.handleEditForm(e);
			this.setShowLoader(false, "");
		}
	};

	handleEditForm = (e) => {
		console.log("III was here");
		e.preventDefault();
		this.setState({
			error: { status: false, message: "" },
		});
		this.setShowLoader(true, "Please Wait");

		const data = { ...this.state };
		delete data.isDrawerOpened;
		delete data.catagories;
		// if (data.pactive === "yes") data.status = "active";
		// else data.status = "not active";
		delete data.pactive;
		delete data.value;
		delete data.error;
		delete data.showLoader;
		delete data.setOpenSnackBar;
		delete data.createdAt;
		delete data.counts;
		delete data.id;
		delete data.isDeleted;
		delete data.updatedAt;
		delete data.isEdit;
		delete data.imageUrl;

		axiosInstance
			.patch(`/v1/admin/category/${this.state.id}`, data, this.config)
			.then((res) => {
				console.log("Updated");
				// window.location.reload(true);
				this.props.onClose();
				this.setShowLoader(false, "");
				Swal.fire("Success!", res?.data?.message, "success");
			})
			.catch((err) => {
				this.setShowLoader(false, "");
				console.log("Error", err);
			});
	};

	render() {
		const isDrawerOpened = this.props.open;
		return (
			<div>
				<Drawer
					anchor="right"
					variant="temporary"
					open={isDrawerOpened}
					onClose={this.props.onClose}
				>
					{this.state.showLoader.open == true && (
						<Loader loaderInfo={this.state.showLoader.message} />
					)}
					<EditUserForm
						style={{ ...styles.title, marginBottom: "30px" }}
						selectedRow={this.props.selectedRow}
						// state={this.state}
						// onChangeDescription={(event, editor) => {
						//   const data = editor.getData();
						//   this.setState({ description: data });
						// }}

						// handleChange={this.handleChange}
						// handleImageUpload={this.handleImageUpload}
						// loadfile={this.loadfile}
						// categoryList={this.state.catagories}
						// handleSubmitForm={this.handleSubmitForm}
						isEdit={this.state.isEdit}
					/>
				</Drawer>
			</div>
		);
	}
}
