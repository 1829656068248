import React, { useState, useEffect } from "react";
import Select from "react-select";
import { axiosInstance } from "../../../../utility/api";

const config = {
  headers: {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  },
};

function ArtistSelectBox({
  state,
  handleSelectChange,
  isEdit,
  handleInputChange,
  getArtistnameList,
  creatorListMain,
  collectionFormData,
}) {
  console.log(
    "Artist.box, collectionFormData",
    collectionFormData,
    "creatorListMain",
    creatorListMain
  );

  // useEffect(() => {
  //   getCreatorList(search);
  // }, []);

  return (
    <div className="row d-flex-aic separator">
      <div className="form-group mb-3 col-xs-12 col-sm-6">
        <label htmlFor="stock">Artist Name</label>
        <Select
          classNamePrefix="creator-select-input"
          value={
            creatorListMain.find(
              (el) => el?.id === collectionFormData?.artist?.id
            ) || null
          }
          options={getArtistnameList()}
          onChange={(e) => {
            handleSelectChange(e, "artistId");
          }}
          isDisabled={isEdit ? true : false}
          // onInputChange={(e) => setSearch(e)}
        />
      </div>
      <div className="form-group mb-3 col-xs-12 col-sm-6">
        <label className="ws-no-wrap" htmlFor="stock">
          Commission Percentage
        </label>
        <div className="displayFlexBaseline">
          <div className="percent-symbol">%</div>
          <span className="currencyInput w-100 removePadding">
            <input
              type="number"
              name="artist.royaltyCommissionPercentage"
              min="0"
              className="form-control validate percentageText"
              maxLength="12"
              onWheel={(event) => event.currentTarget.blur()}
              disabled={isEdit ? true : false}
              value={collectionFormData?.artist?.royaltyCommissionPercentage}
              onChange={(e) => {
                if (/[0-9]/i.test(Number(e.target.value))) {
                  handleSelectChange(e, "royaltyCommissionPercentage");
                }
              }}
              required
            />
          </span>
        </div>
      </div>
      <div className="form-group mb-3 col-xs-12 col-sm-6">
        <label className="ws-no-wrap" htmlFor="stock">
          Primary Earnings
        </label>
        <div className="displayFlexBaseline">
          <div className="percent-symbol">%</div>
          <span className="currencyInput w-100 removePadding">
            <input
              type="number"
              name="artist.primarySalePercentage"
              min="0"
              className="form-control validate percentageText"
              maxLength="12"
              onWheel={(event) => event.currentTarget.blur()}
              disabled={isEdit ? true : false}
              value={collectionFormData?.artist?.primarySalePercentage}
              onChange={(e) => {
                if (/[0-9]/i.test(Number(e.target.value))) {
                  handleSelectChange(e, "primarySalePercentage");
                }
              }}
              required
            />
          </span>
        </div>
      </div>
      <div
        className={
          state.artist.id
            ? "form-group mb-3 col-xs-12 col-sm-5"
            : "form-group mb-3 col-xs-12 col-sm-6"
        }
      >
        <label htmlFor="stock">Cap Amount (USD)</label>
        <input
          type="number"
          name="artist.royaltyCommissionCapAmount"
          min="0"
          className="form-control validate"
          maxLength="12"
          value={collectionFormData?.artist?.royaltyCommissionCapAmount}
          disabled={isEdit ? true : false}
          onWheel={(event) => event.currentTarget.blur()}
          onChange={(e) => {
            if (/[0-9]/i.test(Number(e.target.value))) {
              handleSelectChange(e, "royaltyCommissionCapAmount");
            }
          }}
          required
        />
      </div>
      {state.artist?.id && (
        <>
          {isEdit ? null : (
            <a
              className="cursor-pointer"
              // onClick={() => resetCreatorOrArtist("artist")}
            >
              X
            </a>
          )}
        </>
      )}
    </div>
  );
}

export default ArtistSelectBox;
