/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";

import ProductTable from "../common/tableExpandable.jsx";
import UiDrawer from "./../Sidedrawer/Uidrawer";
import { useDebounce } from "../../hooks/useDebounce.js";
import { getProductList as getProduct } from "../../APIActions/Product";

export default function Products() {
  const [productList, setProductList] = useState([]);
  const [pageNo, setpageNo] = useState(0);
  const [selectedTab, setSelectedTab] = useState("assets");
  const [totalDoc, setTotalDoc] = useState(0);
  const [totalAvailableStock, setTotalAvailableStock] = useState(0);
  const [totalSoldAsset, setTotalSoldAsset] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filterSelected, setFilterSelected] = useState("0");
  const [showLoader, setShowLoader] = useState(false);
  const [isDateFilterEnabled, setIsDateFilterEnabled] = useState(false);

  // Date conversation for DateRange filer
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let day = today.getDate();
  let date1MonthBefore = new Date(year, month - 1, day);
  // let date1DayBefore = new Date(year, month, day - 1);
  let tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  // let todayStartingTime = new Date(year, month, day, 0, 0, 0, 0);
  let todayEndingTime = new Date(year, month, day, 23, 59, 59, 59);

  const [selectionRange, setSelectionRange] = useState({
    startDate: date1MonthBefore,
    endDate: todayEndingTime,
    key: "selection",
  });

  // Defines each column header, other props
  const columns = [
    {
      name: "Asset",
      field: "image",
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Asset ID",
      field: "assetID",
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Name",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Price($)",
      field: "price",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Date",
      field: "date",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Available Stock",
      field: "stock",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Remaining Balance in Edition",
      field: "stock",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Total Edition",
      field: "stock",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Sales Type",
      field: "rate",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "Metadata",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "View on Polygonscan",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "Status",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "Resellable",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "Category",
      field: "category",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "right",
    },
  ];
  const columnsSoldAsset = [
    {
      name: "Asset",
      field: "image",
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Asset ID",
      field: "assetID",
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Name",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Price($)",
      field: "price",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Date",
      field: "date",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Sales Type",
      field: "rate",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "Metadata",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "View on Polygonscan",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "Status",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "Resellable",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "Category",
      field: "category",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "right",
    },
  ];
  const columnsResaleAsset = [
    {
      name: "Asset",
      field: "image",
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Asset ID",
      field: "assetID",
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Name",
      field: "name",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Price($)",
      field: "price",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Date",
      field: "date",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "left",
    },
    {
      name: "Sales Type",
      field: "rate",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "Asset Owner",
      field: "owner",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "Metadata",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "View on Polygonscan",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "Status",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "Resellable",
      field: "status",
      // image: Divider,
      numeric: true,
      sortable: true,
      align: "left",
    },
    {
      name: "Category",
      field: "category",
      // image: Divider,
      numeric: false,
      sortable: true,
      align: "right",
    },
  ];
  const tableColumnWidth = [12, 8, 12, 7, 7, 7, 7, 7, 8, 8, 8, 9];
  // const [tableTotalCount, setTableTotalCount] = useState(10);
  const token = JSON.parse(localStorage.getItem("token"));

  const handleSelect = (ranges) => {
    setSelectionRange(ranges?.selection);
  };

  useEffect(() => {
    getProductList();
    setShowLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNo,
    filterSelected,
    selectedTab,
    selectionRange,
    isDateFilterEnabled,
  ]);

  useDebounce(
    () => {
      if (searchText != null && searchText != "" && searchText != undefined) {
        getProductList();
        setShowLoader(false);
      }
    },
    [searchText],
    1000
  );

  // To fetch product list
  const getProductList = async () => {
    setShowLoader(true);

    let startDate = new Date(
      selectionRange?.startDate?.getTime() -
        selectionRange?.startDate?.getTimezoneOffset() * 60000
    ).toISOString();
    let endDate = new Date(
      selectionRange?.endDate?.getTime() -
        selectionRange?.endDate?.getTimezoneOffset() * 60000
    ).toISOString();

    // Data Range library issue while selecting yesterday, Below bypass time correction for dates selected from sidemenu (eg: select yesterday from side bar of dataRange interface)
    if (moment(selectionRange?.endDate).format("ss") != 59) {
      endDate = moment(endDate).add(1, "days");
    }
    endDate = moment(endDate);
    endDate.set({ hour: 5, minute: 29, second: 59, millisecond: 59 });
    endDate = endDate.toISOString();
    const excludeCategoryValues = ["0", "-1", "-2"];

    let activefilterStatus;

    switch (Number(filterSelected)) {
      case 0:
        // All assets
        activefilterStatus = null;
        break;
      case -1:
        // Active assets
        activefilterStatus = "active";
        break;
      case -2:
        // inactive assets
        activefilterStatus = "inactive";
        break;
      default:
      // code block
    }
	let queryParam;
    if (selectedTab == "resaleAssets") {
       queryParam = {
        search: searchText,
        page: pageNo + 1,
        limit: 10,
        category: excludeCategoryValues
          .map(Number)
          .includes(Number(filterSelected))
          ? ""
          : filterSelected,
        isSold: true,
        fromDate: isDateFilterEnabled ? startDate : "",
        toDate: isDateFilterEnabled ? endDate : "",
        status: activefilterStatus,
		isOnResell:true
      };
    } else {
       queryParam = {
        search: searchText,
        page: pageNo + 1,
        limit: 10,
        category: excludeCategoryValues
          .map(Number)
          .includes(Number(filterSelected))
          ? ""
          : filterSelected,
        isSold: selectedTab == "assets" ? false : true,
        fromDate: isDateFilterEnabled ? startDate : "",
        toDate: isDateFilterEnabled ? endDate : "",
        status: activefilterStatus,
      };
    }
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const result = await getProduct({ params: queryParam, headers });
      setProductList(result?.data?.result?.assets);
      setTotalDoc(result?.data?.result?.totalItems);
      setTotalAvailableStock(result?.data?.result?.totalAvailableStock);
      setTotalSoldAsset(result?.data?.result?.totalSoldAsset);
      setTotalPage(result?.data?.result?.totalPages);
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      if (
        err?.response &&
        err?.response?.data?.errors &&
        err?.response?.data?.errors?.length !== 0 &&
        err?.response?.data?.errors?.length <= 1
      ) {
        Swal.fire({
          title: "Oops!",
          text: err?.response?.data?.errors.map((err) => err.msg),
          icon: "error",
        });
      } else {
        Swal.fire("Oops!", err?.response?.data?.message, "error");
      }
    }
  };

  return (
    <>
      <div className="products-wrapper">
        <div className="container-fluid">
          <div className="page-title">
            <div className="row">
              <div className="col-6">
                <h3>Assets</h3>
              </div>
              <div
                className="col-12 mt-4"
                style={{ justifySelf: "flexgit -end" }}
              >
                <button
                  className={"btn btn-primary"}
                  style={{ textTransform: "uppercase" }}
                  onClick={() => setOpen(true)}
                >
                  + Add Asset
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="products-content">
          <ProductTable
            data={productList}
            columns={columns}
            columnsSoldAsset={columnsSoldAsset}
            columnsResaleAsset={columnsResaleAsset}
            tableColumnWidth={tableColumnWidth}
            totalDoc={totalDoc}
            totalSoldAsset={totalSoldAsset}
            totalAvailableStock={totalAvailableStock}
            totalPage={totalPage}
            pageNo={pageNo}
            setPageNo={(pageNo) => setpageNo(pageNo)}
            showLoader={showLoader}
            setShowLoader={(e) => setShowLoader(e)}
            searchText={searchText}
            setSearchText={setSearchText}
            filterSelected={filterSelected}
            setFilterSelected={setFilterSelected}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setSelectionRange={setSelectionRange}
            selectionRange={selectionRange}
            handleSelect={handleSelect}
            isIsDateFilterEnabled={isDateFilterEnabled}
            setIsDateFilterEnabled={setIsDateFilterEnabled}
          />
        </div>
      </div>

      <UiDrawer
        saleType="fixed"
        open={open}
        onClose={() => setOpen(false)}
        showLoader={showLoader}
        setShowLoader={(e) => setShowLoader(e)}
        isEdit={false}
        maxWidth="xs"
      />
    </>
  );
}
