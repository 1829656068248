import React from "react";
import News from "./News";

export default function CommissionIndex() {
	return (
		<div>
			<div className="categoryContent">
				<News />
			</div>
		</div>
	);
}
