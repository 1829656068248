import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { axiosInstance } from '../../utility/api';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Swal from "sweetalert2";


const useStyles = makeStyles((theme) => ({

    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    paper: {
        padding: theme.spacing(8), // Increase padding to make the modal larger
        textAlign: 'center',
        width: '600px', // Set a fixed width
        height: '300px', // Set a fixed height
    },

    input : {
        marginLeft: '127px',
        paddingTop: '30px',
        paddingBottom:'20px'
    },

  }));
const UploadModal = ({ open, onClose }) => {
  const [file, setFile] = useState(null);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
    const header = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
          "Content-Type": "multipart/form-data",
        },
      };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };
  const handleUpload = async () => {
    setLoading(true);
    const formData = new FormData();
    console.log(file, "formdata");
    formData.append("csv", file);

    try {
        await axiosInstance.post(`/v1/admin/airdrop/upload-airdrop`, formData, header);
        Swal.fire('Success', 'Airdrop is processing. it will take around 5 minutes to complete', 'success').then(() => {
          setLoading(false);
          onClose();
        });
      } catch (error) {
        console.error('Error uploading file:', error.response.data);
        setLoading(false);

        Swal.fire('Error',  error?.response?.data?.message||'Failed to upload file. Please try again later.', 'error');
      }
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Paper className={classes.paper}>
       {!loading && <><Typography variant="h6" gutterBottom>
          Choose file to start airdrop
        </Typography>
        <div className={classes.input}>
        <input type="file" onChange={handleFileChange} />
        </div>
        <Button variant="contained" color="primary" onClick={handleUpload} style={{ marginTop: '20px' }}>
          Upload
        </Button></>}
        {loading && <><Typography variant="h6" gutterBottom>
          File is uploading.. &nbsp; Please Wait.
        </Typography><CircularProgress style={{ marginTop: '20px' }} /></>}
    
    
      </Paper>
    </Modal>
  );
};
export default UploadModal;








