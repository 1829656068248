import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import Swal from "sweetalert2";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";

import classes from "./Table.css";
import editIcon from "../../../assets/images/icons/edit.svg";
import deleteIcon from "../../../assets/images/icons/delete.svg";
import { axiosInstance, baseURL } from "../../../utility/api";

const Row = (props) => {
	const {
		data,
		tableColumnWidth,
		setOpenEdit,
		setSelectedRow,
		setIsEdit,
		setState,
		//  permission,
		setPermission,
	} = props;
	const [open, setOpen] = useState(false);

	const deleteCateogry = (id) => {
		console.log("deleted 2", id);
		if (id) {
			confirmAlert({
				title: "Delete SubAdmin?",
				message: "Are you sure to delete.",
				buttons: [
					{
						label: "Yes",
						onClick: () => handleDelete(id),
					},
					{
						label: "No",
						onClick: () => console.log("Clicked No"),
					},
				],
			});
		}
	};

	const handleDelete = (id) => {
		const config = {
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
			},
		};
		axiosInstance
			.delete(`${baseURL}v1/sub-admin/${id}`, config)
			.then((res) => {
				Swal.fire("Success!", res?.data?.message, "success").then((res) =>
					window.location.reload()
				);
			})

			.catch((err) => {
				Swal.fire("Error!", err?.response?.data?.message, "error");
			});
	};

	return (
		<React.Fragment>
			<TableRow
				onClick={() => {
					setOpen(!open);
				}}
				className={classes.rowDatas}
				style={{ borderBottom: "12px solid #F5F5FC", cursor: "default" }}
			>
				<TableCell style={{ width: `${tableColumnWidth[0]}%` }}>
					<div className="columnStyles">{data?.firstName ?? "-"}</div>
				</TableCell>
				<TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
					<div className="columnStyles">{data?.lastName ?? "-"}</div>
				</TableCell>
				<TableCell style={{ width: `${tableColumnWidth[3]}%` }}>
					<div className="columnStyles">{data?.email ?? "-"}</div>
				</TableCell>
				{/* <TableCell style={{ width: `${tableColumnWidth[1]}%` }}>
            <div className="columnStyles">{data?.status ?? "-"}</div>
          </TableCell>         */}
				<TableCell style={{ width: `${tableColumnWidth[2]}%` }}>
					<Tooltip title="Edit category">
						<img
							src={editIcon}
							className="cursorPointer"
							alt="Edit"
							onClick={() => {
								// setState(data)
								setSelectedRow(data);
								setOpenEdit(true);
								setIsEdit(true);
								setState(data);
								setPermission(data.permission);
							}}
						/>
					</Tooltip>
					<Tooltip title="Delete category">
						<img
							src={deleteIcon}
							onClick={() => deleteCateogry(data?.id)}
							className="marginLeft20 cursorPointer"
							alt="Delete"
						/>
					</Tooltip>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
};

export default Row;
