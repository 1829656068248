import React, { useEffect, useState } from "react";
import UiDrawer from "./UiDrawer";
import DataTable from "./Table/Table.";

const SubAdmin = () => {
	const [open, setOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [checked, setChecked] = useState([]);
	const [expanded, setExpanded] = useState([]);
	const [permission, setPermission] = useState({});
	const [reload, setReload] = useState(false);

	const [state, setState] = useState({
		firstName: "",
		lastName: "",
		email: "",
		password: "",
	});

	useEffect(() => {}, [reload]);

	const [list] = useState([
		{ slug: "assets", checked: false },
		{ slug: "cate", checked: false },
	]);
	const [selectAction, setSelectAction] = useState([{}]);

	const selectHandler = (selectAction) => {
		setSelectAction(selectAction);
	};

	const reloadTable = () => {
		console.log("okok");
		setReload(!reload);
	};

	return (
		<div>
			<div className="categoryContent">
				<>
					<div className="products-wrapper">
						<div className="container-fluide">
							<div className="page-title">
								<div className="row">
									<div className="col-6">
										<h3>Manage Sub Admin</h3>
									</div>

									<div
										className="col-12 mt-4"
										style={{ justifySelf: "flexgit -end" }}
									>
										<button
											className={"btn btn-primary"}
											onClick={() => {
												setOpen(true);
												setIsEdit(false);
												setState({
													firstname: "",
													lastname: "",
													email: "",
												});
												setSelectAction([]);
											}}
										>
											+ Add Sub Admin
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="products-content">
							<DataTable
								setEditOpen={setEditOpen}
								setIsEdit={setIsEdit}
								setState={setState}
								permission={permission}
								setPermission={setPermission}
								reload={reload}
							/>
						</div>
					</div>

					<UiDrawer
						open={open}
						onClose={() => setOpen(false)}
						showLoader={showLoader}
						setShowLoader={(e) => setShowLoader(e)}
						isEdit={isEdit}
						setState={setState}
						state={state}
						list={list}
						selectAction={selectAction}
						setSelectAction={setSelectAction}
						selectHandler={selectHandler}
						checked={checked}
						setChecked={setChecked}
						expanded={expanded}
						setExpanded={setExpanded}
						permission={permission}
						setPermission={setPermission}
					/>
					<UiDrawer
						open={editOpen}
						onClose={() => setEditOpen(false)}
						showLoader={showLoader}
						setShowLoader={(e) => setShowLoader(e)}
						isEdit={isEdit}
						setState={setState}
						state={state}
						selectAction={selectAction}
						setSelectAction={setSelectAction}
						selectHandler={selectHandler}
						list={list}
						checked={checked}
						setChecked={setChecked}
						expanded={expanded}
						setExpanded={setExpanded}
						permission={permission}
						setPermission={setPermission}
						onSuccess={reloadTable}
					/>
				</>
			</div>
		</div>
	);
};
export default SubAdmin;
