export function acceptOnlyNumbers(str) {
    if (str) {
      return str.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1')
    }
    return null
}

export function objectDeepClone(obj) {
    return JSON.parse(JSON.stringify(obj))
}

export function camelToLowerCase(str) {
  if(str) {
    const result = str.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toLowerCase() + result.slice(1);
    return finalResult
  }
  return null
}

// modify below function based on any new errors found
export function handleAPIError (err) {
  if (err?.response && err?.response?.data?.errors && Object.keys(err?.response?.data?.errors).length !== 0) {
    return err?.response?.data?.errors.map((err) => err.msg)
  } else if(err?.response?.data?.message) {
    return err?.response?.data?.message
  }
  return 'Something went wrong'
}